import React, { useEffect, useState } from "react";
import { Spinner, UncontrolledTooltip } from "reactstrap";
import { spendingByCategory } from "../../pages/Dashboard/Dashboard";
import CircleBlue from "./Blue";
import CircleRed from "./Red";
import "./style.scss";
import CircleYellow from "./Yellow";

const Circles = ({
  spendingByCategoryData,
}: {
  spendingByCategoryData: spendingByCategory[];
}) => {
  const [total, setTotal] = useState<number>(0);
  const [redInfo, setRedInfo] = useState<{
    value: number;
    percentage: number;
    label: string;
  }>({
    value: 0,
    percentage: 0,
    label: "",
  });
  const [blueInfo, setBlueInfo] = useState<{
    value: number;
    percentage: number;
    label: string;
  }>({
    value: 0,
    percentage: 0,
    label: "",
  });
  const [yellowInfo, setYellowInfo] = useState<{
    value: number;
    percentage: number;
    label: string;
  }>({
    value: 0,
    percentage: 0,
    label: "",
  });

  useEffect(() => {
    if (spendingByCategoryData.length > 0) {
      let tot = spendingByCategoryData.reduce(
        (acc, curr) => acc + (curr.amount || 0),
        0
      );
      setTotal(tot);

      if (spendingByCategoryData[0]) {
        setBlueInfo({
          value: spendingByCategoryData[0].amount || 0,
          percentage: (spendingByCategoryData[0].amount || 0) / tot || 0,
          label: spendingByCategoryData[0].transClass || "",
        });
      }
      if (spendingByCategoryData[1]) {
        setRedInfo({
          value: spendingByCategoryData[1].amount || 0,
          percentage: (spendingByCategoryData[1].amount || 0) / tot || 0,
          label: spendingByCategoryData[1].transClass || "",
        });
      }
      if (spendingByCategoryData[2]) {
        setYellowInfo({
          value: spendingByCategoryData[2].amount || 0,
          percentage: (spendingByCategoryData[2].amount || 0) / tot || 0,
          label: spendingByCategoryData[2].transClass || "",
        });
      }
    }
  }, [spendingByCategoryData]);

  return (
    <>
      <div className="wrapper circles-wrapper">
        {blueInfo.value !== 0 ? (
          <>
            <CircleBlue
              className="inner"
              value={blueInfo.value}
              percentage={blueInfo.percentage}
              label={blueInfo.label}
            />
          </>
        ) : (
          <CircleBlue
            className="inner"
            value={0}
            percentage={0}
            label={"Prelevement personnel"}
          />
        )}
        {redInfo.value !== 0 ? (
          <>
            <CircleRed
              className="inner"
              value={redInfo.value}
              percentage={redInfo.percentage}
              label={redInfo.label}
            />
          </>
        ) : (
          <CircleRed
            className="inner"
            value={0}
            percentage={0}
            label={"Cotisations"}
          />
        )}
        {yellowInfo.value !== 0 ? (
          <>
            <CircleYellow
              className="inner"
              value={yellowInfo.value}
              percentage={yellowInfo.percentage}
              label={yellowInfo.label}
            />
          </>
        ) : (
          <CircleYellow
            className="inner"
            value={0}
            percentage={0}
            label={"Impôt et taxes"}
          />
        )}
      </div>
    </>
  );
};

export default Circles;

import React, { useEffect, useState } from "react";
import "./Notes.scss";
import Select from "react-select";
import DataTable from "react-data-table-component";
import {
  Alert,
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { INote, User } from "../../../interfaces";
import config from "../../../config";
import axios from "axios";
import { ErrorLogger } from "../../../util/errorLogger";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/fr";
import { SubmitHandler, useForm } from "react-hook-form";
import DeletShadow from "../../../assets/DeletShadow";
import useAxios from "../../../util/hooks/useAxios";
import { UserTypes } from "../../../util/context";

export interface AvaliderProps {}

const { API_URL } = config[process.env.NODE_ENV];

type GeneralFormValues = Omit<Partial<INote>, "note"> & {
  note: string | null;
};

const Notes: React.FC<AvaliderProps> = ({}) => {
  const [open, setOpen] = useState(false);
  const ontoggle = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);

  const [notes, setNotes] = useState<{ day: string; notes: INote[] }[]>([]);
  const [singleNote, setSingleNote] = useState<INote | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { id } = useParams();
  let api = useAxios();

  const getNotes = async () => {
    try {
      setLoading(true);
      const { data } = await api.post(
        `/api/Note/all`,
        {
          where: {
            companyId: creds.role !== UserTypes.Client ? id : creds.company_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      let reducedData: { day: string; notes: INote[] }[] = [];
      for (let note of data.data as INote[]) {
        const included = reducedData.find(
          (elt) =>
            elt.day ===
            moment(note.createdAt)
              .set({ hour: 12, minute: 0 })
              .format("YYYY-MM-DD hh:mm")
        );
        if (!included) {
          reducedData.push({
            day: moment(note.createdAt)
              .set({ hour: 12, minute: 0 })
              .format("YYYY-MM-DD hh:mm"),
            notes: data.data.filter(
              (elt: INote) =>
                moment(elt.createdAt)
                  .set({ hour: 12, minute: 0 })
                  .format("YYYY-MM-DD hh:mm") ===
                moment(note.createdAt)
                  .set({ hour: 12, minute: 0 })
                  .format("YYYY-MM-DD hh:mm")
            ),
          });
        }
      }
      setNotes(reducedData);
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("getting notes", error);
    }
  };

  useEffect(() => {
    getNotes();
  }, []);

  const {
    control,
    setValue,
    getValues,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GeneralFormValues>({});

  const { ref: noteRef, ...note } = register("note");

  const addNote: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      if (!form.note) {
        setErrorMessage({
          type: "invalid_form",
          message: "Veuillez fournir les données de la note",
        });
        return;
      }
      setLoading(true);
      await api.post(
        `/api/Note/Create`,
        {
          note: form.note,
          company_id: creds.role !== UserTypes.Client ? id : creds.company_id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      await getNotes();
      reset({
        note: null,
      });
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("creating note", error);
    }
  };

  const deleteNote = async () => {
    try {
      setLoading(true);
      await api.post(
        `/api/Note/Delete`,
        {
          id: singleNote?.id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      await getNotes();
      setDeleteModal(false);
      setSingleNote(null);
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("updating company form", error);
    }
  };

  return (
    <>
      <div className="wrrap-notes">
        <div className="section-notes col-lg-10 mx-auto col-md-12">
          <div className="Boxe-date-notes">
            {notes?.length > 0 &&
              notes.map((elt, index) => (
                <>
                  <div className="date-note" key={index}>
                    <span className="note-time">
                      <span className="day-note">
                        {moment(elt.day).format("D")}
                      </span>{" "}
                      <span className="month-note">
                        {moment(elt.day).format("MMM").toUpperCase()}{" "}
                        {moment(elt.day).format("yyyy").toUpperCase()}
                      </span>
                    </span>
                  </div>
                  <div className="note-content">
                    {elt.notes.length > 0 &&
                      elt.notes.map((note, index) => (
                        <div className="item-note" key={index}>
                          <div className="time-top">
                            <span>{moment(note.createdAt).format("LT")}</span>
                          </div>
                          <div className="item-note-content">
                            <p>{note.note}</p>
                            <button
                              className="btn btn-default btn-small "
                              onClick={() => {
                                setSingleNote(note);
                                setDeleteModal(true);
                              }}
                            >
                              <DeletShadow /> Supprimer
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              ))}

            <div className="note-content note-form">
              <form onSubmit={handleSubmit(addNote)}>
                <div className="item-note">
                  <FormGroup className="form-icon icon-start">
                    <Input
                      className="form-secondary text-area-custom"
                      type="textarea"
                      rows="25"
                      innerRef={noteRef}
                      {...note}
                      onChange={() => setErrorMessage(null)}
                    />
                  </FormGroup>
                  {errorMessage?.type === "invalid_form" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                </div>

                <div className=" pt-2 bottom-actions">
                  <Button color="secondary" type="submit">
                    Ajouter
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="openbtn text-center">
        <Modal
          className="modal-danger modal-dialog-centered"
          isOpen={deleteModal}
          toggle={() => {
            setSingleNote(null);
            setDeleteModal(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSingleNote(null);
              setDeleteModal(false);
            }}
          >
            Supprimer une note
          </ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir supprimer cette note de{" "}
                {moment(singleNote?.createdAt).format("DD/MM/YYYY")}?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              type="button"
              onClick={() => {
                setSingleNote(null);
                setDeleteModal(false);
              }}
            >
              Non
            </Button>
            <Button
              color="danger"
              type="submit"
              onClick={async () => await deleteNote()}
            >
              Oui
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default Notes;

import React, { useState } from "react";
import { UserTypes } from "../../util/context";
import "./Switch.scss";
interface SwitchProps {
  id?: string;
  switchValue: string;
  setSwitchValue: (item: string) => void;
  leftValue?: string;
  rightValue?: string;
}

const CustomSwitch: React.FC<SwitchProps> = ({
  id,
  switchValue,
  setSwitchValue,
  leftValue = "",
  rightValue = "",
}) => {
  return (
    <>
      <input
        className="react-switch-checkbox"
        id={id}
        type="checkbox"
        onChange={() => {
          let val = switchValue === "Admin" ? UserTypes.Cabinet : "Admin";
          if (
            leftValue &&
            leftValue !== "" &&
            rightValue &&
            rightValue !== ""
          ) {
            val = switchValue === leftValue ? rightValue : leftValue;
          }
          setSwitchValue(val);
        }}
      />
      <label className="react-switch-label" htmlFor={id}>
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default CustomSwitch;

import React from "react";

interface ArchiveProps {
  className?: string;
}

const ArchiveIcon = ({ className }: ArchiveProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.822"
    height="17.547"
    viewBox="0 0 18.822 17.547"
    className={className}
  >
    <g
      id="Groupe_209"
      data-name="Groupe 209"
      transform="translate(1801.504 811.251)"
    >
      <path
        id="Tracé_311"
        data-name="Tracé 311"
        d="M-1762.478-718.451v.224q0,5.66,0,11.32c0,.469-.166.72-.525.79a1.207,1.207,0,0,1-.23.014H-1778a.653.653,0,0,1-.752-.743q0-5.723,0-11.446c0-.048.006-.1.01-.16Zm-8.136,4.282c.889,0,1.778,0,2.667,0a.637.637,0,0,0,.693-.693.633.633,0,0,0-.694-.649q-2.079,0-4.157,0c-.406,0-.812-.006-1.218,0a.629.629,0,0,0-.651.67.635.635,0,0,0,.694.671C-1772.392-714.166-1771.5-714.169-1770.614-714.169Z"
        transform="translate(-21.479 -87.602)"
        fill="#ffb254"
      />
      <path
        id="Tracé_312"
        data-name="Tracé 312"
        d="M-1782.682-807.424h-18.811c0-.077-.01-.144-.01-.212q0-1.428,0-2.856c0-.521.234-.758.758-.758q8.653,0,17.307,0c.522,0,.756.238.757.76C-1782.682-809.476-1782.682-808.461-1782.682-807.424Z"
        fill="#ffb254"
      />
    </g>
  </svg>
);

export default ArchiveIcon;

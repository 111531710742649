import React from "react";

interface AttacheProps {
  className?: string;
}

const AttacheIcon = ({ className }: AttacheProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34.855"
    height="34.855"
    viewBox="0 0 34.855 34.855"
    className={className}
  >
    <path
      id="Tracé_91"
      data-name="Tracé 91"
      d="M668.784,496.28c-.041.2-.078.4-.123.6a5.091,5.091,0,0,1-1.416,2.541c-3.919,3.919-7.826,7.85-11.762,11.751a6.9,6.9,0,0,1-7,1.856,7.017,7.017,0,0,1-5.015-5.708,6.921,6.921,0,0,1,2.033-6.114q5.834-5.87,11.7-11.709c.035-.035.072-.067.086-.08l1.418,1.422c-.026.027-.089.1-.156.165q-5.781,5.782-11.565,11.563a5,5,0,0,0-1.535,3.01,4.836,4.836,0,0,0,1.913,4.655,4.974,4.974,0,0,0,6.683-.466q5.909-5.882,11.794-11.79a2.917,2.917,0,0,0,.8-3.053,2.866,2.866,0,0,0-2.275-2.086,2.924,2.924,0,0,0-2.592.668c-.089.074-.171.156-.253.238q-5.844,5.843-11.688,11.685a1.052,1.052,0,0,0-.352,1.114,1,1,0,0,0,1.583.492,3.1,3.1,0,0,0,.254-.237q4.563-4.562,9.123-9.126a1.419,1.419,0,0,0,.159-.233l1.462,1.462c-.037.04-.086.095-.137.147q-4.632,4.633-9.266,9.265a3.025,3.025,0,0,1-4.487-4.057c.088-.1.18-.194.273-.288q5.808-5.809,11.615-11.618a5.316,5.316,0,0,1,2.615-1.482,5.049,5.049,0,0,1,5.964,3.693c.058.24.1.483.156.725Z"
      transform="translate(-801.013 126.829) rotate(-45)"
      fill="#54b9a3"
    />
  </svg>
);

export default AttacheIcon;

import React from "react";
import { Badge } from "reactstrap";
import CrownIcon from "../../assets/CrownIcon";
import styled from "styled-components";
import EmailIcon from "../../assets/EmailIcon";
import { adjustColor } from "./ApercuAdmin";

const EmailPrevIcon = ({ color }: { color: string }) => {
  const Button = styled.button`
    background: white;
    box-shadow: 0 3px 15px ${adjustColor(color, 75)};
    color: ${color};
    border-radius: 15px;
    font-size: 1.8rem;
    background: #fff;
    border: none;
    position: relative;
    height: 52px;
    min-height: 52px;
    min-width: 52px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      stroke:${color}
    }
  `;
  return (
    <Button>
      <EmailIcon />
    </Button>
  );
};

export default EmailPrevIcon;
import React, { useEffect, useState } from "react";
import "./InvoiceTransactions.scss";
import DataTable from "react-data-table-component";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { BootyPagination } from "../../../components/table/pagination";
import CalenderIconBlue from "../../../assets/images/svg/calender-icon-blue.svg";
import Select, { SingleValue } from "react-select";
import ReviewIcon from "../../../assets/ReviewIcon";
import config from "../../../config";

import { useSelector } from "react-redux";
import {
  Company,
  ICategory,
  IFacture,
  ISubCategory,
  ITransaction,
  User,
} from "../../../interfaces";
import { ErrorLogger } from "../../../util/errorLogger";
import moment, { Moment } from "moment";
import {
  BILL_OPTIONS,
  InvoiceTransactionParentTypes,
  OptionType,
  UserTypes,
} from "../../../util/context";
import Status from "../../../components/badge/Status";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useAxios from "../../../util/hooks/useAxios";
import { useFormatter } from "../../../util/hooks/useFormatter";
import { IoMdCreate } from "react-icons/io";
import SelectableBadge from "../../../components/SelectableBadge";
import { toast, ToastContainer } from "react-toastify";
import FileViewer from "../../../components/FileViewer";
import ArchiveIcon from "../../../assets/ArchiveIcon";
import { BsTrashFill } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import {
  RiArrowDropDownLine,
  RiCloseLine,
  RiEdit2Fill,
  RiSearchLine,
} from "react-icons/ri";
import SaveIconWhite from "../../../assets/SaveIconWhite";
import { useParams } from "react-router-dom";
import { SearchFormValues } from "../../MesTransactions/TransactionsAvalider/TransactionsAvalider";
import Encaissements from "../../MesTransactions/Encaissements";
import Decaissements from "../../MesTransactions/Decaissements";
registerLocale("fr", fr);
moment.updateLocale("fr", {});

export interface FactProps {}
type DynamicObj = {
  [prop: string]: boolean;
};

type SearchFacturesFormValues = {
  dateFrom?: string | null;
  dateTo?: string | null;
  company?: OptionType | null;
  status?: OptionType | null;
};

const { API_URL } = config[process.env.NODE_ENV];

const InvoiceTransactions = ({
  client,
  context,
  company,
  callback,
  reload,
  parentType,
}: {
  client?: User;
  context?: string;
  company?: Company;
  callback?: any;
  reload?: boolean;
  parentType: string | String[];
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [singleTransaction, setSingleTransaction] =
    useState<ITransaction | null>();

  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);

  const { setDecimalDigits } = useFormatter();
  const [openCategModal, setOpenCategModal] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(true);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };
  let api = useAxios();

  const { user, company: userCompany } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user
  ) as { user: User; company: Company };
  const [transactionsToValid, setTransactionsToValid] = useState<
    ITransaction[]
  >([]);

  const { id } = useParams();

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [decCategs, setDecCategs] = useState<ICategory[]>([]);
  const [encCategs, setEncCategs] = useState<ICategory[]>([]);
  const [searchCategName, setSearchCategName] = useState<string | null>();
  const [singleTrans, setSingleTrans] = useState<ITransaction | null>();
  const [closeCategSearch, setCloseCategSearch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshCategs, setRefreshCategs] = useState<boolean>(false);

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SearchFormValues>({});

  const { ref: nameRef, ...name } = register("name");

  const ontoggle = (transaction: ITransaction) => {
    setSingleTransaction(transaction);
    setDeleteModal(true);
  };

  const columns = React.useMemo(
    () => [
      {
        name: "Date",
        selector: (row: any) => row.date,
        sortable: true,
        cell: (row: ITransaction) => (
          <>{moment(row.date).format("DD/MM/YYYY")}</>
        ),
      },
      {
        name: "Facture",
        selector: (row: any) => row.label,
        sortable: true,
        cell: (row: any) => (
          <div className="tr-categ-cell-wrapper">
            <p>{row.label}</p>
            <div className="tr-categ-cell">
              {!row.sub_category && (
                <p
                  onClick={() => {
                    setSingleTrans(row);
                    setOpenCategModal(true);
                  }}
                  className="tr-categ-cell-toCateg"
                >
                  À catégoriser <RiArrowDropDownLine size={30} />
                </p>
              )}

              {row.sub_category && (
                <p>
                  {row.sub_category.name}{" "}
                  <RiEdit2Fill
                    onClick={() => {
                      setSingleTrans(row);
                      setOpenCategModal(true);
                    }}
                  />
                </p>
              )}
            </div>
          </div>
        ),
      },
      {
        name: "Document",
        selector: (row: any) => row.justif,
        sortable: true,
        cell: (row: ITransaction) => {
          return (
            <>
              <div className="file-table">
                <div className="list-Files transaction-file">
                  {row.documents &&
                    row.documents.length > 0 &&
                    row.documents.map((document, index) => (
                      <span
                        className="file-box-item justif-viewer"
                        onClick={() => {
                          setUrl(document.url);
                          setViewModal(true);
                        }}
                        key={index}
                      >
                        <FaEye size={30} />
                      </span>
                    ))}
                </div>
              </div>
            </>
          );
        },
      },
      {
        name: "Montant",
        sortable: true,
        cell: (row: any) => (
          <span
          // className={parseFloat(row.amount) > 0 ? "green-num" : "red-num"}
          >
            {setDecimalDigits(row.amount)} €
          </span>
        ),
      },
      {
        name: "Tva",
        sortable: true,
        cell: (row: ITransaction) => {
          return <>{row.vat} €</>;
        },
      },
      {
        name: "Action",
        button: true,
        cell: (row: ITransaction) => {
          return (
            <>
              <button className="btn btn-red" onClick={() => ontoggle(row)}>
                <BsTrashFill />
              </button>
            </>
          );
        },
      },
    ],
    [company]
  );

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
  };

  const getInvoiceTransactions = async () => {
    const verifyCompanyId =
      creds.role === UserTypes.Client ? creds.company_id : id;
    if (!verifyCompanyId) {
      return;
    }
    try {
      callback(false);

      const { data } = await api.post(
        `/api/Transaction/All`,
        {
          where: {
            companyId: verifyCompanyId,
            relatedToFactureType: "parent",
            parentType,
          },
          perPage: 20,
          pageIndex: currentPage,
          order: [
            ["date", "DESC"],
            ["id", "DESC"],
          ],
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const { data: transactionsData } = data;

      setTransactionsToValid(
        transactionsData.sort((a: { date: number }, b: { date: number }) => {
          return moment(b.date).diff(moment(a.date));
        })
      );

      setTotalRows(data.count);
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("getting non valid transactions", error);
    }
  };

  const searhcCategs = async () => {
    try {
      if (!searchCategName) {
        setCloseCategSearch(false);
        await getCategories();
        return;
      }
      if (!closeCategSearch) {
        setCloseCategSearch(true);
      }

      if (parentType === "dec") {
        const allSubs = decCategs.reduce(
          (acc, curr) => [...acc, ...(curr.sub_categories || [])],
          [] as ISubCategory[]
        );
        let found: ISubCategory[] = [];
        for (let elt of allSubs) {
          const exists = elt.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              searchCategName
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")!
            );

          if (exists) {
            found.push(elt);
          }
        }
        setDecCategs(
          found.length > 0
            ? [{ ...decCategs[0], sub_categories: found }]
            : decCategs
        );
      }

      if (parentType === "enc") {
        for (let elt of encCategs) {
          if (elt.sub_categories) {
            const exists: ISubCategory[] = elt.sub_categories?.filter((elt) =>
              elt.name
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  searchCategName
                    ?.toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")!
                )
            );
            if (exists.length > 0) {
              setEncCategs((prevState) => [
                {
                  ...prevState[0],
                  sub_categories:
                    exists.length > 0 ? exists : prevState[0].sub_categories,
                },
              ]);
            }
          }
        }
      }
    } catch (error: any) {
      ErrorLogger("searching categs", error);
    }
  };

  useEffect(() => {
    getInvoiceTransactions();
  }, [currentPage]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    searhcCategs();
  }, [searchCategName]);

  const resetStates = () => {
    setCloseCategSearch(false);
    reset();
    setSearchCategName(null);
    setOpenCategModal(false);
    setSingleTrans(null);
  };

  const getCategories = async () => {
    try {
      const { data } = await api.post(
        `/api/Category/All`,
        {},
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const enc = data.data.filter(
        (elt: ICategory) => elt.type === "Encaissement"
      );

      const dec = data.data.filter(
        (elt: ICategory) => elt.type === "Décaissement"
      );

      setDecCategs(dec);
      setEncCategs(enc);
    } catch (error: any) {
      ErrorLogger("getting non valid transactions", error);
    }
  };

  const categCallback = async () => {
    await getCategories();
    await getInvoiceTransactions();
    resetStates();
  };

  const deleteTransaction = async (id: string = "") => {
    try {
      setDeleteLoading(true);
      const { data } = await api.post(
        `/api/Transaction/Delete`,
        {
          id: id !== "" ? id : singleTransaction?.id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setTransactionsToValid((prevState) =>
        prevState.filter((trans) => trans.id !== singleTransaction?.id)
      );

      setSingleTransaction(null);
      setDeleteModal(false);
      setDeleteLoading(false);
    } catch (error: any) {
      ErrorLogger("deleting a transaction", error);
      setDeleteLoading(false);
    }
  };

  return (
    <div>
      <div className="toValide-transactions-table">
        <DataTable
          columns={columns}
          data={transactionsToValid}
          noDataComponent={<p>Il n'y a aucun data à afficher</p>}
          pagination
          progressPending={pending}
          progressComponent={
            <>
              <Spinner color="secondary" type="grow" className="mx-1">
                Loading...
              </Spinner>
              <Spinner color="secondary" type="grow" className="mx-1">
                Loading...
              </Spinner>
              <Spinner color="secondary" type="grow" className="mx-1">
                Loading...
              </Spinner>
            </>
          }
          paginationComponent={(props) => {
            const customProps = { ...props, color: "primary" };
            return <BootyPagination {...customProps} />;
          }}
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          paginationPerPage={20}
        />
      </div>

      <div className="openbtn text-center">
        {/*
        décaissements / encaissements modal 
        */}
        <Modal
          className="modal-primary modal-dialog-centered modal-lg"
          isOpen={openCategModal}
          toggle={async () => {
            await categCallback();
          }}
        >
          <ModalHeader
            toggle={async () => {
              await categCallback();
            }}
          >
            {parentType && parentType === InvoiceTransactionParentTypes.Dec
              ? "Décaissements"
              : "Encaissements"}
          </ModalHeader>
          <ModalBody>
            <div
              className={`content-form-block ${
                parentType && parentType === InvoiceTransactionParentTypes.Dec
                  ? "decaissements-block"
                  : ""
              }`}
            >
              <FormGroup className="form-icon icon-end">
                <Input
                  value={searchCategName || ""}
                  type="text"
                  className="form-default"
                  onChange={(event) => {
                    setSearchCategName(event.target.value);
                  }}
                />
                <button
                  className="icon icon-primary"
                  onClick={async () => {
                    if (closeCategSearch) {
                      await getCategories();
                      setCloseCategSearch(false);
                      setSearchCategName("");
                    }
                  }}
                >
                  {closeCategSearch ? <RiCloseLine /> : <RiSearchLine />}
                </button>
              </FormGroup>
              {/* </form> */}

              {(parentType === InvoiceTransactionParentTypes.Dec &&
                decCategs.length > 0) ||
              (parentType.includes(InvoiceTransactionParentTypes.Enc) &&
                encCategs.length > 0) ? (
                <>
                  {parentType === InvoiceTransactionParentTypes.Dec ? (
                    <Decaissements
                      categs={decCategs}
                      transaction={singleTrans}
                      callback={async () => await categCallback()}
                      immobCheckCallback={null}
                      carbCheckCallback={null}
                      diffPayCheckCallback={null}
                      setRefreshCategs={setRefreshCategs}
                    />
                  ) : (
                    <Encaissements
                      categs={encCategs}
                      transaction={singleTrans}
                      callback={async () => await categCallback()}
                      setRefreshCategs={setRefreshCategs}
                      diffPayCheckCallback={null}
                    />
                  )}
                </>
              ) : (
                <Spinner color="success" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              )}
            </div>
          </ModalBody>
        </Modal>
        {/*
        transaction deletion modal 
        */}
        <Modal
          isOpen={deleteModal}
          toggle={() => {
            setSingleTransaction(null);
            setDeleteModal(false);
          }}
          className="modal-danger modal-dialog-centered"
        >
          <ModalHeader
            toggle={() => {
              setSingleTransaction(null);
              setDeleteModal(false);
            }}
          >
            Supprimer une Facture
          </ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir supprimer la facture{" "}
                {singleTransaction?.label || null}?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              disabled={deleteLoading}
              onClick={() => {
                setSingleTransaction(null);
                setDeleteModal(false);
              }}
            >
              Non
            </Button>
            <Button
              color="danger"
              onClick={() => deleteTransaction()}
              disabled={deleteLoading}
            >
              {deleteLoading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Oui"
              )}
            </Button>
          </ModalFooter>
        </Modal>
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </div>
  );
};

export default InvoiceTransactions;

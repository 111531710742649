import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router";
import { Company, User } from "../../../interfaces";
import { UserTypes } from "../../../util/context";
import { ErrorLogger } from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import Information from "../../ClientInfo/InformationClient";

function ClientInfoWrapper() {

  const { client, company, callback } = useOutletContext() as any;
  return (
    <Information
      client={client}
      company={company}
      callback={callback}
    />
  );
}

export default ClientInfoWrapper;

import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {Card} from "reactstrap";
import {OutletComp} from "..";
import RoutingTabs from "../../../components/RoutingTabs";
import {Company, User} from "../../../interfaces";
import {AppPacks, UserTypes} from "../../../util/context";
import {ErrorLogger} from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import {TabsType} from "../../CompanyInfo";
import CompanyInfo from "./CompanyInfoWrapper";
import ExerciceCompanyWrapper from "./ExerciceCompanyWrapper";
import Ged from "./GedCompanyWrapper";
import Iban from "./IbanCompanyWrapper";
import SyncCompanyWrapper from "./SyncCompanyWrapper";
import GestionCompteIban from "../../CompanyInfo/GestionCompteIban/GestionCompteIban";

type CustomTabsType = {
  label: string;
  index: number;
  Component: React.FC<{}>;
  link: string;
  blackList: string[];
}[];

const tabs: CustomTabsType = [
  {
    label: "Informations société",
    index: 1,
    Component: CompanyInfo,
    link: "/profil/entreprise",
    blackList: [],
  },
  {
    label: "Gestion des Documents",
    index: 2,
    Component: Ged,
    link: "/profil/entreprise/ged",
    blackList: [],
  },
  // {
  //   label: "Iban Télépaiement",
  //   index: 3,
  //   Component: Iban,
  //   link: "/profil/entreprise/iban",
  //   blackList: [AppPacks.Entrepreneur],
  // },
  {
    label: "Exercice Précédent",
    index: 5,
    Component: ExerciceCompanyWrapper,
    link: "/profil/entreprise/exercise",
    blackList: [AppPacks.Entrepreneur, AppPacks.BilanEntrepreneur],
  },
  {
    label: "Compte Synchronisé",
    index: 6,
    Component: SyncCompanyWrapper,
    link: "/profil/entreprise/sync",
    blackList: [],
  },
  {
    label: "Gestion des Comptes",
    index: 7,
    Component: GestionCompteIban,
    link: "/profil/entreprise/gestion",
    blackList: [],
  },
];

function CompanyWrapper() {
  const creds = useSelector(
    (state: {root: object; user: object}) => state.root
  ) as {
    user_id: string;
    company_id: string;
    token: string;
    role: string;
    pack: string;
  };

  const {user, company: userCompany} = useSelector(
    (state: {root: object; user: object; company: object}) => state.user
  ) as {user: User; company: Company};

  let api = useAxios();

  const [reload, setReload] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [currentCompany, setCurrentCompany] = useState<Company | null>();

  const getUser = async () => {
    try {
      const {data} = await api.post(
        `/api/User/All`,
        {
          where: {
            id: creds.user_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      const {data: userData} = data;
      const [userFound] = userData;
      const {companies, ...user} = userFound;
      if (companies && companies.length > 0) {
        const _currComp = companies.find(
          (elt: Company) => elt.id === creds.company_id
        );
        if (_currComp) {
          setCurrentCompany(_currComp);
        } else {
          setCurrentCompany(companies[0]);
        }
      }
      setCurrentUser(user);
    } catch (error: any) {
      ErrorLogger("state rehydration in App", error);
    }
  };

  useEffect(() => {
    getUser();
  }, [creds.token, creds.company_id]);

  useEffect(() => {
    if (reload) {
      getUser();
      setReload(false);
    }
  }, [reload]);

  return (
    <div className="page">
      <div>
        <Card className="card-Table table-primary">
          <RoutingTabs
            tabs={tabs.filter((elt) => !elt.blackList.includes(creds.pack))}
            type="child"
          />
          <OutletComp
            context={{
              context: creds.company_id,
              company: currentCompany!,
              client: currentUser!,
              user: currentUser!,
              callback: getUser,
            }}
          />
        </Card>
      </div>
    </div>
  );
}

export default CompanyWrapper;

import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Company,
  ICategory,
  ISubCategory,
  ITransaction,
  User,
} from "../../interfaces";
import { rehydrateUser } from "../../store/reducers/user/userSlice";
import { UserTypes } from "../../util/context";
import { ErrorLogger } from "../../util/errorLogger";
import useAxios from "../../util/hooks/useAxios";
import "./RoutingTabs.scss";

export type ImmobStateHandler = {
  isImmobFire: boolean;
  setIsImmobFire: any;
  isImmobSubCateg: ISubCategory | null;
  setIsImmobSubCateg: any;
  isImmobTrans: ITransaction | null;
  setIsImmobTrans: any;
} | null;

type TabsProps = {
  tabs: {
    label?: string;
    image?: string;
    index: number;
    Component: any;
    link: string;
  }[];
  orientation?: "horizontal" | "vertical";
  className?: string;
  type?: string;
};

/**
 * Avalible Props
 * @param className string
 * @param tab Array of object
 * @param selectedTab number
 * @param onClick Function to set the active tab
 * @param orientation Tab orientation Vertical | Horizontal
 */



const RoutingTabs: FC<TabsProps> = ({
  className = "tabs-component",
  tabs = [],
  type = "parent",
}) => {
  const navigate = useNavigate();
  const location = useLocation()

  return (
    <div className={className}>
      <div className="tablist" role="tablist">
        {tabs.map((tab, index) => (
          <button
            className={`client-info-tab ${
              (
                type === "child"
                  ? location.pathname === tab.link
                  : location.pathname.startsWith(tab.link)
              )
                ? "btn active"
                : " btn"
            } ${type === "child" ? "child-wrapper" : "parent-wrapper"}`}
            onClick={() => {
              navigate(tab.link);
            }}
            key={index}
            type="button"
            role="tab"
            aria-controls={`tabpanel-${tab.index}`}
            tabIndex={
              (
                type === "child"
                  ? location.pathname === tab.link
                  : location.pathname.startsWith(tab.link)
              )
                ? 0
                : -1
            }
            id={`btn-${tab.index}`}
          >
            {tab.image && (
              <span className="icon-tabs">
                {" "}
                <img src={tab.image} id="in-case-img" />
              </span>
            )}
            <span className="label-span" id="in-case-text">
              {tab.label}
            </span>
          </button>
        ))}
      </div>
      <div role="tabpanel" className="tabpanel">
        {/* <OutletComp
          context={{
            context: creds.company_id,
            company: currentCompany!,
            client: currentUser!,
            user: currentUser!,
            callback: getUser,
          }}
        /> */}
      </div>
    </div>
  );
};
export default RoutingTabs;

import React, { useEffect, useState } from "react";
import "./Facturation.scss";

import { Link, useNavigate, useParams } from "react-router-dom";
import Tabs from "../../components/Tabs";
import ListesQuittances from "./ListesQuittances/ListesQuittances";
import {
  Company,
  IAvoir,
  IDevis,
  IFacture,
  IModel,
  IQuittance,
  IQuittanceModel,
  ISubCategory,
  User,
} from "../../interfaces";
import { useSelector } from "react-redux";
import useAxios from "../../util/hooks/useAxios";
import {
  Badge,
  Button,
  Card,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import BannerTop from "../../components/Banner/BannerTop";
import { SiPypy } from "react-icons/si";
import { RiUser3Line } from "react-icons/ri";
import UserInfo from "../../components/user-info/UserInfo";
import data from "../../constants/data";
import {
  AppPacks,
  ClientTypes,
  OptionType,
  UserTypes,
} from "../../util/context";
import CrownIcon from "../../assets/CrownIcon";
import { colors } from "../../constants";
import IconPlusYellow from "../../assets/images/svg/puls-icon-yellow.svg";
import Select, { components } from "react-select";
import FileViewer from "../../components/FileViewer";
import { ErrorLogger } from "../../util/errorLogger";
import moment from "moment";
import "moment/locale/fr";
import { toast, ToastContainer } from "react-toastify";
import fileSaver from "file-saver";

import ModelsTabs from "./ModelsTab";
import FactureHtml from "./QuittanceHtml/QuittanceHtml";
import LoadQuittance from "./LoadQuittance/LoadQuittance";
import UnderRepair from "../../components/UnderRepair/UnderRepair";
import CreateQuittance from "./CreateQuittance/CreateQuittance";

export interface FactureProps {}
export type TabsType = {
  label: string;
  index: number;
  Component: React.FC;
  type?: string;
  allowedPacks: String[];
}[];

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        src={IconPlusYellow}
        alt="icon"
        className="svg-warning dropdown-custom-icon"
      />
    </components.DropdownIndicator>
  );
};

const ClientTypesArr = [
  { label: "B2B", value: "b2b" },
  { label: "B2C", value: "b2c" },
];

const InvoiceTypes = [
  { label: "Facture", value: "facture" },
  { label: "Devis", value: "devis" },
  { label: "Avoir", value: "avoir" },
];

// Tabs Array
const tabs: TabsType = [
  {
    label: "Liste des quittances",
    index: 1,
    Component: ListesQuittances,
    type: "quittance",
    allowedPacks: [],
  },
  // {
  //   label: "Liste des devis",
  //   index: 2,
  //   Component: ListeDevis,
  //   type: "devis",
  //   allowedPacks: [],
  // },
  // {
  //   label: "Liste des avoirs",
  //   index: 3,
  //   Component: ListesAvoirs,
  //   type: "avoir",
  //   allowedPacks: [],
  // },
];

const Quitance = ({
  client,
  context,
  company,
  callback,
}: {
  client?: User;
  context?: string;
  company?: Company;
  callback?: () => void;
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);
  const [createQuittanceModal, setCreateQuittanceModal] =
    useState<boolean>(false);
  const [chooseClientTypeModal, setChooseClientTypeModal] =
    useState<boolean>(false);
  const [chooseClientType, setChooseClientType] = useState<OptionType | null>();
  const [fillInvoiceModal, setFillInvoiceModal] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [needEmail, setNeedEmail] = useState<boolean>(false);
  const [needModelRef, setNeedModelRef] = useState<boolean>(false);
  const [formData, setFormData] = useState<{
    month: string | null;
    companyAddress: string | null;
    companyShareCapital: string | null;
    companyPhone: string | null;
    companySiren: string | null;
    companyTVA: string | null;
    clientName: string | null;
    clientEmail: string | null;
    clientAddress: string | null;
    clientPhone: string | null;
    clientSiren: string | null;
    clientTVA: string | null;
    additionalInfo: string | null;
    companyLogo: any;
    paymentLink: string | null;
    receiverEmail: string | null;
    modelRef: string | null;
    signee: string | null;
    beneficiary: string | null;
    startDate: string | null;
    endDate: string | null;
    loyer: string | null;
    charges: string | null;
    paymentDate: string | null;
    totalTTC: number | null;
    totalTVA: number | null;
  }>({
    month: null,
    companyAddress: "",
    companyShareCapital: "",
    companyPhone: "",
    companySiren: "",
    companyTVA: "",
    clientName: "",
    clientEmail: "",
    clientAddress: "",
    clientPhone: "",
    clientSiren: "",
    clientTVA: "",
    additionalInfo: "",
    companyLogo: "",
    paymentLink: "",
    receiverEmail: "",
    modelRef: "",
    signee: "",
    beneficiary: "",
    startDate: "",
    endDate: "",
    loyer: "",
    charges: "",
    paymentDate: "",
    totalTTC: null,
    totalTVA: null,
  });
  const [invoiceType, setInvoiceType] = useState<{
    value: string;
    label: string;
  } | null>();
  const [modelType, setModelType] = useState<String | null>();

  const [saveAsModelLoading, setSaveAsModelLoading] = useState<boolean>(false);
  const [saveAndSendLoading, setSaveAndSendLoading] = useState<boolean>(false);
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [loaderModelLoading, setLoaderModelLoading] = useState<{
    index: string;
    value: boolean;
  } | null>();

  const [models, setModels] = useState<IQuittanceModel[]>([]);
  const [modelsType, setModelsType] = useState<OptionType | null>();
  const [modelsViewModal, setModelsViewModal] = useState<boolean>(false);
  const [modelDeleteModal, setModelDeleteModal] = useState<boolean>(false);
  const [createFromModelModal, setCreateFromModelModal] =
    useState<boolean>(false);
  const [singleModel, setSingleModel] = useState<IModel | null>();
  const [isLoaderModel, setIsLoaderModel] = useState<boolean>(false);

  const [singleQuittance, setSingelQuittance] = useState<IQuittance | null>();

  const deleteModelWrapper = (modal: boolean, elt: IModel) => {
    setSingleModel(elt);
    setModelDeleteModal(modal);
  };

  const { id } = useParams();

  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);

  const [invoiceSubCategs, setInvoiceSubCategs] = useState<ISubCategory[]>([]);

  const { user, company: userCompany } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user
  ) as { user: User; company: Company };

  const { newNotif } = useSelector(
    (state: { messagesNotifs: object }) => state.messagesNotifs
  ) as { newNotif: boolean };

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as {
    user_id: string;
    company_id: string;
    token: string;
    role: string;
    pack: string;
  };

  const navigate = useNavigate();
  let api = useAxios();

  const getInvoiceModels = async () => {
    try {
      const { data } = await api.post(
        `/api/QuittanceModel/all`,
        {
          where: {
            type: "quittance",
            company_id: creds.company_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setModels(data.data);
    } catch (error: any) {
      ErrorLogger("getting invoice subCategs", error);
    }
  };

  const loadFromModel = async (model: IModel) => {
    try {
      setLoaderModelLoading({
        index: model.id,
        value: true,
      });
      const { data } = await api.post(
        `/api/invoice/loadFromModel`,
        {
          modelId: model.id,
          modelType: "Quittance",
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (!data.invoice) {
        toast.dismiss();
        toast.warning(
          `Nous ne pouvons pas créer votre quittance à partir de ce modèle pour le moment, veuillez réessayer plus tard.`,
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setModelsViewModal(false);
        setModelsType(null);
        setInvoiceType(null);
        setIsLoaderModel(false);
        setLoaderModelLoading(null);
        return;
      }

      setSingelQuittance(data.invoice);
      setModelsViewModal(false);
      setIsLoaderModel(false);
      setLoaderModelLoading(null);
      setCreateFromModelModal(true);
    } catch (error: any) {
      ErrorLogger("getting invoice subCategs", error);
      setLoaderModelLoading(null);
    }
  };

  const deleteInvoiceModel = async (id: string) => {
    try {
      await api.post(
        `/api/QuittanceModel/Delete`,
        {
          id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      await getInvoiceModels();
      setModelDeleteModal(false);
    } catch (error: any) {
      ErrorLogger("getting factures data", error);
    }
  };

  const tabRedirect = (tabValue: string = "quittance") => {
    const tab = tabValue === "" ? invoiceType?.value : tabValue;
    if (tabs.findIndex((elt) => elt.type === tab) > -1) {
      setReload(true);
    }
  };

  const saveAsModel = async (used: boolean = false, relatedTo: string = "") => {
    try {
      if (!needModelRef || !formData.modelRef || formData.modelRef === "") {
        toast.dismiss();
        toast.warning(`Vous devez ajouter un nom de modèle`, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNeedModelRef(true);
        return;
      }

      if (!used) {
        setSaveAsModelLoading(true);
      }

      let companyLogoURL = "";
      if (formData.companyLogo) {
        const fileData = new FormData();

        fileData.append("file", formData.companyLogo);

        const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
          headers: {
            "x-access-token": creds.token,
          },
        });

        if (data.urls && data.urls.upload === 1 && data.urls.urls.length > 0) {
          companyLogoURL = data.urls.urls[0].url;
        }
      }
      let payload: any = {
        name: "Quittance",
        modelInfo: {
          logo: companyLogoURL !== "" ? companyLogoURL : null,
          signee:
            formData.signee && formData.signee !== "" ? formData.signee : null,
          beneficiary:
            formData.beneficiary && formData.beneficiary !== ""
              ? formData.beneficiary
              : null,
        },
        clientInfo: {
          clientTVA: formData.clientTVA,
          clientTel: formData.clientPhone,
          clientName: formData.clientName,
          clientEmail:
            formData.receiverEmail && formData.receiverEmail !== ""
              ? formData.receiverEmail.trim()
              : null,
          clientSiren: formData.clientSiren,
          clientAdress: formData.clientAddress,
        },
        date: moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        month:
          formData.month && formData.month !== ""
            ? moment(formData.month)
                .set({ hour: 12, minute: 0 })
                .startOf("month")
                .format("YYYY-MM-DD hh:mm")
            : moment()
                .set({ hour: 12, minute: 0 })
                .startOf("month")
                .format("YYYY-MM-DD hh:mm"),
        type: "quittance",
        status: "102",
        company_id: creds.company_id,
        clientType: chooseClientType?.value,
        totalTTC: formData.totalTTC ? formData.totalTTC : 0,
        totalTVA: formData.totalTVA ? formData.totalTVA : null,
        ref:
          formData.modelRef && formData.modelRef !== ""
            ? formData.modelRef
            : null,
        startDate:
          formData.startDate && formData.startDate !== ""
            ? moment(formData.startDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        endDate:
          formData.endDate && formData.endDate !== ""
            ? moment(formData.endDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        loyer: formData.loyer,
        charges: formData.charges,
        paymentDate:
          formData.paymentDate && formData.paymentDate !== ""
            ? moment(formData.paymentDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
      };
      if (relatedTo !== "") {
        payload.relatedTo = relatedTo;
      }
      const { data } = await api.post(`/api/QuittanceModel/Create`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (data.id) {
        toast.dismiss();
        toast.success("Votre modéle a été créé avec succès!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.dismiss();
        toast.warning("Votre modéle n'a pas été créé!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setSaveAsModelLoading(false);
      if (!used) {
        await getInvoiceModels();
        tabRedirect();
        setTimeout(function () {
          setFillInvoiceModal(false);
          setNeedModelRef(false);
          setInvoiceType(null);
          setChooseClientType(null);
        }, 3000);
      }
    } catch (error: any) {
      ErrorLogger("saving model", error);
      setSaveAsModelLoading(false);
      toast.dismiss();
      toast.warning("Votre modéle n'a pas été créé!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const load_saveAsModel = async (
    used: boolean = false,
    relatedTo: string = ""
  ) => {
    try {
      if (!needModelRef || !formData.modelRef || formData.modelRef === "") {
        toast.dismiss();
        toast.warning(`Vous devez ajouter un nom de modèle`, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNeedModelRef(true);
        return;
      }

      if (!used) {
        setSaveAsModelLoading(true);
      }

      let payload: any = {
        name: "Quittance",
        modelInfo: {
          signee:
            formData.signee && formData.signee !== "" ? formData.signee : null,
          beneficiary:
            formData.beneficiary && formData.beneficiary !== ""
              ? formData.beneficiary
              : null,
        },
        clientInfo: {
          clientTVA: formData.clientTVA,
          clientTel: formData.clientPhone,
          clientName: formData.clientName,
          clientEmail:
            formData.receiverEmail && formData.receiverEmail !== ""
              ? formData.receiverEmail.trim()
              : null,
          clientSiren: formData.clientSiren,
          clientAdress: formData.clientAddress,
        },
        date: moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        month:
          formData.month && formData.month !== ""
            ? moment(formData.month)
                .set({ hour: 12, minute: 0 })
                .startOf("month")
                .format("YYYY-MM-DD hh:mm")
            : moment()
                .set({ hour: 12, minute: 0 })
                .startOf("month")
                .format("YYYY-MM-DD hh:mm"),
        type: "quittance",
        status: "102",
        company_id: creds.company_id,
        clientType: chooseClientType?.value,
        ref:
          formData.modelRef && formData.modelRef !== ""
            ? formData.modelRef
            : null,
        startDate:
          formData.startDate && formData.startDate !== ""
            ? moment(formData.startDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        endDate:
          formData.endDate && formData.endDate !== ""
            ? moment(formData.endDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        loyer: formData.loyer,
        charges: formData.charges,
        paymentDate:
          formData.paymentDate && formData.paymentDate !== ""
            ? moment(formData.paymentDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        totalTTC: formData.totalTTC ? formData.totalTTC : 0,
        totalTVA: formData.totalTVA ? formData.totalTVA : null,
      };

      if (formData.companyLogo) {
        if (typeof formData.companyLogo !== "string") {
          const fileData = new FormData();

          fileData.append("file", formData.companyLogo);

          const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
            headers: {
              "x-access-token": creds.token,
            },
          });

          if (
            data.urls &&
            data.urls.upload === 1 &&
            data.urls.urls.length > 0
          ) {
            payload.modelInfo.logo = data.urls.urls[0].url;
          }
        } else {
          payload.modelInfo.logo = formData.companyLogo;
        }
      }

      if (relatedTo !== "") {
        payload.relatedTo = relatedTo;
      }
      const { data } = await api.post(`/api/QuittanceModel/Create`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (data.id) {
        toast.dismiss();
        toast.success("Votre modéle a été créé avec succès!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.dismiss();
        toast.warning("Votre modéle n'a pas été créé!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setSaveAsModelLoading(false);

      if (!used) {
        await getInvoiceModels();
        // tabRedirect(singleQuittance?.name.toLowerCase());
        tabRedirect();
        setTimeout(function () {
          setCreateFromModelModal(false);
          setInvoiceType(null);
          setChooseClientType(null);
          setSingelQuittance(null);
          setNeedModelRef(false);
        }, 3000);
      }
    } catch (error: any) {
      ErrorLogger("saving model", error);
      setSaveAsModelLoading(false);
      toast.dismiss();
      toast.warning("Votre modéle n'a pas été créé!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const saveAndSend = async (used: boolean = false) => {
    try {
      setNeedEmail(false);

      if (!formData.receiverEmail || formData.receiverEmail === "") {
        setNeedEmail(true);
        return;
      }

      if (!used) {
        if (!needModelRef || !formData.modelRef || formData.modelRef === "") {
          toast.dismiss();
          toast.warning(`Vous devez ajouter un nom de modèle`, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setNeedModelRef(true);
          return;
        }
        setSaveAndSendLoading(true);
      }

      let companyLogoURL = "";
      if (formData.companyLogo) {
        const fileData = new FormData();

        fileData.append("file", formData.companyLogo);

        const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
          headers: {
            "x-access-token": creds.token,
          },
        });

        if (data.urls && data.urls.upload === 1 && data.urls.urls.length > 0) {
          companyLogoURL = data.urls.urls[0].url;
        }
      }
      let payload: any = {
        name: "Quittance",
        quittInfo: {
          logo: companyLogoURL !== "" ? companyLogoURL : null,
          signee:
            formData.signee && formData.signee !== "" ? formData.signee : null,
          beneficiary:
            formData.beneficiary && formData.beneficiary !== ""
              ? formData.beneficiary
              : null,
        },
        clientInfo: {
          clientTVA: formData.clientTVA,
          clientTel: formData.clientPhone,
          clientName: formData.clientName,
          clientEmail:
            formData.receiverEmail && formData.receiverEmail !== ""
              ? formData.receiverEmail.trim()
              : null,
          clientSiren: formData.clientSiren,
          clientAdress: formData.clientAddress,
        },
        date: moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        month:
          formData.month && formData.month !== ""
            ? moment(formData.month)
                .set({ hour: 12, minute: 0 })
                .startOf("month")
                .format("YYYY-MM-DD hh:mm")
            : moment()
                .set({ hour: 12, minute: 0 })
                .startOf("month")
                .format("YYYY-MM-DD hh:mm"),
        type: "quittance",
        status: "102",
        company_id: creds.company_id,
        clientType: chooseClientType?.value,
        ref:
          formData.modelRef && formData.modelRef !== ""
            ? formData.modelRef
            : null,
        startDate:
          formData.startDate && formData.startDate !== ""
            ? moment(formData.startDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        endDate:
          formData.endDate && formData.endDate !== ""
            ? moment(formData.endDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        loyer: formData.loyer,
        charges: formData.charges,
        paymentDate:
          formData.paymentDate && formData.paymentDate !== ""
            ? moment(formData.paymentDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        totalTTC: formData.totalTTC ? formData.totalTTC : 0,
        totalTVA: formData.totalTVA ? formData.totalTVA : null,
      };

      const { data } = await api.post(`/api/Quittance/Create`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (!data.id) {
        toast.dismiss();
        toast.warning("Votre quittance n'a pas été créée!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSaveAndSendLoading(false);
        return;
      } else {
        const { data: createinvoicePdf } = await api.post(
          `/api/invoice/createInvoicePdf`,
          {
            invoiceModel: "Quittance",
            invoiceId: data.id,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        if (createinvoicePdf) {
          if (!used) {
            await saveAsModel(true, data.id);
          }
          const { data: sendEmailData } = await api.post(
            `/api/invoice/sendBillEmail`,
            {
              invoiceModel: "Quittance",
              invoiceId: data.id,
              paymentLink: null,
              isReciept: false,
            },
            {
              headers: {
                "x-access-token": creds.token,
              },
            }
          );

          if (sendEmailData.send) {
            toast.dismiss();
            toast.success("Votre quittance a été envoyée!", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.dismiss();
            toast.warning("Votre quittance n'a pas été envoyée!", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      }

      setSaveAndSendLoading(false);
      if (!used) {
        await getInvoiceModels();
        tabRedirect();
        setTimeout(function () {
          setFillInvoiceModal(false);
          setInvoiceType(null);
          setChooseClientType(null);
          setNeedModelRef(false);
        }, 3000);
      }
    } catch (error: any) {
      ErrorLogger("saving and sending model", error);
      setSaveAndSendLoading(false);
      toast.dismiss();
      toast.warning(`On peut pas traiter votre quittance en ce moment!`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const load_saveAndSend = async (used: boolean = false) => {
    try {
      setNeedEmail(false);

      if (!formData.receiverEmail || formData.receiverEmail === "") {
        setNeedEmail(true);
        return;
      }

      if (!used) {
        if (!needModelRef || !formData.modelRef || formData.modelRef === "") {
          toast.dismiss();
          toast.warning(`Vous devez ajouter un nom de modèle`, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setNeedModelRef(true);
          return;
        }
        setSaveAndSendLoading(true);
      }

      let payload: any = {
        name: "Quittance",
        quittInfo: {
          signee:
            formData.signee && formData.signee !== "" ? formData.signee : null,
          beneficiary:
            formData.beneficiary && formData.beneficiary !== ""
              ? formData.beneficiary
              : null,
        },
        clientInfo: {
          clientTVA: formData.clientTVA,
          clientTel: formData.clientPhone,
          clientName: formData.clientName,
          clientEmail:
            formData.receiverEmail && formData.receiverEmail !== ""
              ? formData.receiverEmail.trim()
              : null,
          clientSiren: formData.clientSiren,
          clientAdress: formData.clientAddress,
        },
        date: moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        month:
          formData.month && formData.month !== ""
            ? moment(formData.month)
                .set({ hour: 12, minute: 0 })
                .startOf("month")
                .format("YYYY-MM-DD hh:mm")
            : moment()
                .set({ hour: 12, minute: 0 })
                .startOf("month")
                .format("YYYY-MM-DD hh:mm"),
        type: "quittance",
        status: "102",
        company_id: creds.company_id,
        clientType: chooseClientType?.value,
        ref:
          formData.modelRef && formData.modelRef !== ""
            ? formData.modelRef
            : null,
        startDate:
          formData.startDate && formData.startDate !== ""
            ? moment(formData.startDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        endDate:
          formData.endDate && formData.endDate !== ""
            ? moment(formData.endDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        loyer: formData.loyer,
        charges: formData.charges,
        paymentDate:
          formData.paymentDate && formData.paymentDate !== ""
            ? moment(formData.paymentDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        totalTTC: formData.totalTTC ? formData.totalTTC : 0,
        totalTVA: formData.totalTVA ? formData.totalTVA : null,
      };

      if (formData.companyLogo) {
        if (typeof formData.companyLogo !== "string") {
          const fileData = new FormData();

          fileData.append("file", formData.companyLogo);

          const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
            headers: {
              "x-access-token": creds.token,
            },
          });

          if (
            data.urls &&
            data.urls.upload === 1 &&
            data.urls.urls.length > 0
          ) {
            payload.quittInfo.logo = data.urls.urls[0].url;
          }
        } else {
          payload.quittInfo.logo = formData.companyLogo;
        }
      }

      payload.id = singleQuittance?.id;

      const { data } = await api.post(`/api/Quittance/Create`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (!data.id) {
        toast.dismiss();
        toast.warning(`Votre quittance n'a pas été modifiée!`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSaveAndSendLoading(false);
        return;
      } else {
        if (!used) {
          await load_saveAsModel(true, singleQuittance?.id);
        }
        const { data: createInvoicePdf } = await api.post(
          `/api/invoice/createInvoicePdf`,
          {
            invoiceModel: "Quittance",
            invoiceId: singleQuittance?.id,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );

        if (createInvoicePdf) {
          const { data: sendEmailData } = await api.post(
            `/api/invoice/sendBillEmail`,
            {
              invoiceModel: "Quittance",
              invoiceId: singleQuittance?.id,
              paymentLink: null,
              isReciept: false,
            },
            {
              headers: {
                "x-access-token": creds.token,
              },
            }
          );

          if (sendEmailData.send) {
            toast.dismiss();
            toast.success("Votre quittance a été envoyée!", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.dismiss();
            toast.warning("Votre quittance n'a pas été envoyée!", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      }

      setSaveAndSendLoading(false);
      if (!used) {
        await getInvoiceModels();
        // tabRedirect(singleQuittance?.name.toLowerCase());
        tabRedirect();
        setTimeout(function () {
          setCreateFromModelModal(false);
          setInvoiceType(null);
          setChooseClientType(null);
          setSingelQuittance(null);
          setNeedModelRef(false);
        }, 3000);
      }
    } catch (error: any) {
      ErrorLogger("saving and sending model", error);
      setSaveAndSendLoading(false);
      toast.dismiss();
      toast.warning(`On peut pas traiter votre quittance en ce moment!`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const send = async () => {
    try {
      setNeedEmail(false);

      if (!formData.receiverEmail || formData.receiverEmail === "") {
        setNeedEmail(true);
        return;
      }
      setSendLoading(true);
      await saveAndSend(true);
      setSendLoading(false);
      tabRedirect();
      setTimeout(function () {
        setFillInvoiceModal(false);
        setInvoiceType(null);
        setChooseClientType(null);
      }, 3000);

      // setReload(true);
    } catch (error: any) {
      ErrorLogger("saving and sending model", error);
      setSendLoading(false);
      toast.dismiss();
      toast.warning(`On peut pas traiter votre quittance en ce moment!`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const load_send = async () => {
    try {
      setNeedEmail(false);

      if (!formData.receiverEmail || formData.receiverEmail === "") {
        setNeedEmail(true);
        return;
      }
      setSendLoading(true);
      await load_saveAndSend(true);
      setSendLoading(false);
      // tabRedirect(singleQuittance?.name.toLowerCase());
      tabRedirect();
      setTimeout(function () {
        setCreateFromModelModal(false);
        setInvoiceType(null);
        setChooseClientType(null);
        setSingelQuittance(null);
      }, 3000);

      // setReload(true);
    } catch (error: any) {
      ErrorLogger("saving and sending model", error);
      setSendLoading(false);
      toast.dismiss();
      toast.warning(`On peut pas traiter votre quittance en ce moment!`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const download = async () => {
    try {
      setDownloadLoading(true);

      let companyLogoURL = "";
      if (formData.companyLogo) {
        const fileData = new FormData();

        fileData.append("file", formData.companyLogo);

        const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
          headers: {
            "x-access-token": creds.token,
          },
        });

        if (data.urls && data.urls.upload === 1 && data.urls.urls.length > 0) {
          companyLogoURL = data.urls.urls[0].url;
        }
      }

      let payload: any = {
        name: "Quittance",
        quittInfo: {
          logo: companyLogoURL !== "" ? companyLogoURL : null,
          signee:
            formData.signee && formData.signee !== "" ? formData.signee : null,
          beneficiary:
            formData.beneficiary && formData.beneficiary !== ""
              ? formData.beneficiary
              : null,
        },
        clientInfo: {
          clientTVA: formData.clientTVA,
          clientTel: formData.clientPhone,
          clientName: formData.clientName,
          clientEmail:
            formData.receiverEmail && formData.receiverEmail !== ""
              ? formData.receiverEmail.trim()
              : null,
          clientSiren: formData.clientSiren,
          clientAdress: formData.clientAddress,
        },
        date: moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        month:
          formData.month && formData.month !== ""
            ? moment(formData.month)
                .set({ hour: 12, minute: 0 })
                .startOf("month")
                .format("YYYY-MM-DD hh:mm")
            : moment()
                .set({ hour: 12, minute: 0 })
                .startOf("month")
                .format("YYYY-MM-DD hh:mm"),
        type: "quittance",
        status: "102",
        company_id: creds.company_id,
        clientType: chooseClientType?.value,
        ref:
          formData.modelRef && formData.modelRef !== ""
            ? formData.modelRef
            : null,
        startDate:
          formData.startDate && formData.startDate !== ""
            ? moment(formData.startDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        endDate:
          formData.endDate && formData.endDate !== ""
            ? moment(formData.endDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        loyer: formData.loyer,
        charges: formData.charges,
        paymentDate:
          formData.paymentDate && formData.paymentDate !== ""
            ? moment(formData.paymentDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        totalTTC: formData.totalTTC ? formData.totalTTC : 0,
        totalTVA: formData.totalTVA ? formData.totalTVA : null,
      };

      const { data } = await api.post(`/api/Quittance/Create`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (!data.id) {
        toast.dismiss();
        toast.warning("Votre quittance n'a pas été créée!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSaveAndSendLoading(false);
        return;
      } else {
        const { data: createinvoicePdf } = await api.post(
          `/api/invoice/createInvoicePdf`,
          {
            invoiceModel: "Quittance",
            invoiceId: data.id,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        if (createinvoicePdf) {
          const { data: invoiceCreated } = await api.post(
            `/api/Quittance`,
            {
              id: data.id,
            },
            {
              headers: {
                "x-access-token": creds.token,
              },
            }
          );

          if (invoiceCreated.id) {
            if (invoiceCreated.quittS3link && invoiceCreated.quittS3link.url) {
              await fileSaver(
                invoiceCreated.quittS3link.url,
                invoiceCreated.quittS3link.key
              );
            }
          } else {
            toast.dismiss();
            toast.warning(
              "Votre quittance n'a pas pu être traitée pour le moment!",
              {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            setSaveAndSendLoading(false);
            return;
          }
        }
      }

      setDownloadLoading(false);
      tabRedirect();
      setTimeout(function () {
        setFillInvoiceModal(false);
        setInvoiceType(null);
        setChooseClientType(null);
      }, 3000);
    } catch (error: any) {
      ErrorLogger("saving and sending model", error);
      setDownloadLoading(false);
      toast.dismiss();
      toast.warning(
        `On peut pas traiter votre quittance en ce moment!"
        }`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const load_download = async () => {
    try {
      setDownloadLoading(true);

      let payload: any = {
        name: "Quittance",
        quittInfo: {
          signee:
            formData.signee && formData.signee !== "" ? formData.signee : null,
          beneficiary:
            formData.beneficiary && formData.beneficiary !== ""
              ? formData.beneficiary
              : null,
        },
        clientInfo: {
          clientTVA: formData.clientTVA,
          clientTel: formData.clientPhone,
          clientName: formData.clientName,
          clientEmail:
            formData.receiverEmail && formData.receiverEmail !== ""
              ? formData.receiverEmail.trim()
              : null,
          clientSiren: formData.clientSiren,
          clientAdress: formData.clientAddress,
        },
        date: moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        month:
          formData.month && formData.month !== ""
            ? moment(formData.month)
                .set({ hour: 12, minute: 0 })
                .startOf("month")
                .format("YYYY-MM-DD hh:mm")
            : moment()
                .set({ hour: 12, minute: 0 })
                .startOf("month")
                .format("YYYY-MM-DD hh:mm"),
        type: "quittance",
        status: "102",
        company_id: creds.company_id,
        clientType: chooseClientType?.value,
        ref:
          formData.modelRef && formData.modelRef !== ""
            ? formData.modelRef
            : null,
        startDate:
          formData.startDate && formData.startDate !== ""
            ? moment(formData.startDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        endDate:
          formData.endDate && formData.endDate !== ""
            ? moment(formData.endDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        loyer: formData.loyer,
        charges: formData.charges,
        paymentDate:
          formData.paymentDate && formData.paymentDate !== ""
            ? moment(formData.paymentDate)
                .set({ hour: 12, minute: 0 })
                .format("YYYY-MM-DD hh:mm")
            : moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        totalTTC: formData.totalTTC ? formData.totalTTC : 0,
        totalTVA: formData.totalTVA ? formData.totalTVA : null,
      };

      if (formData.companyLogo) {
        if (typeof formData.companyLogo !== "string") {
          const fileData = new FormData();

          fileData.append("file", formData.companyLogo);

          const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
            headers: {
              "x-access-token": creds.token,
            },
          });

          if (
            data.urls &&
            data.urls.upload === 1 &&
            data.urls.urls.length > 0
          ) {
            payload.quittInfo = {
              ...payload.quittInfo,
              logo: data.urls.urls[0].url,
            };
          }
        } else {
          payload.quittInfo = {
            ...payload.quittInfo,
            logo: formData.companyLogo,
          };
        }
      }

      payload.id = singleQuittance?.id;

      const { data } = await api.post(`/api/Quittance/Create`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (!data.id) {
        toast.dismiss();
        toast.warning("Votre quittance n'a pas été modifiée!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSaveAndSendLoading(false);
        return;
      } else {
        const { data: createinvoicePdf } = await api.post(
          `/api/invoice/createInvoicePdf`,
          {
            invoiceModel: "Quittance",
            invoiceId: data.id,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        if (createinvoicePdf) {
          const { data: invoiceCreated } = await api.post(
            `/api/Quittance`,
            {
              id: data.id,
            },
            {
              headers: {
                "x-access-token": creds.token,
              },
            }
          );

          if (invoiceCreated.id) {
            if (invoiceCreated.quittS3link && invoiceCreated.quittS3link.url) {
              await fileSaver(
                invoiceCreated.quittS3link.url,
                invoiceCreated.quittS3link.key
              );
            }
          } else {
            toast.dismiss();
            toast.warning(
              "Votre quittance n'a pas pu être traitée pour le moment!",
              {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            setSaveAndSendLoading(false);
            return;
          }
        }
      }

      setDownloadLoading(false);
      // tabRedirect(singleQuittance?.name.toLowerCase());
      tabRedirect();
      setTimeout(function () {
        setCreateFromModelModal(false);
        setInvoiceType(null);
        setChooseClientType(null);
        setSingelQuittance(null);
      }, 3000);

      // setReload(true);
    } catch (error: any) {
      ErrorLogger("saving and sending model", error);
      setDownloadLoading(false);
      toast.dismiss();
      toast.warning(`On peut pas traiter votre quittance en ce moment!`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    // getInvoiceSubCategs();
    getInvoiceModels();
  }, []);

  return (
    <div className="page section-facturation">
      {user.id && user.role === UserTypes.Client ? (
        <>
          <div
            className={`top-content ${
              creds.role === UserTypes.Client ? "client-resize-header" : ""
            }`}
          >
            <Row>
              <Col lg={8} md={12} className="blockBanner">
                <BannerTop banner={data.quittance} />
              </Col>
              <Col lg={4} md={12}>
                <Card className="blockUser">
                  <Button
                    className="btn-icon btn-icon-primary"
                    color="light"
                    onClick={() => navigate("/tickets")}
                  >
                    {newNotif && <Badge color="primary"> </Badge>}
                    <CrownIcon stroke={colors.primaryColor} />
                  </Button>
                  <UserInfo user={user} />
                  <Button
                    className="btn-icon btn-icon-primary"
                    color="light"
                    onClick={() =>
                      navigate({
                        pathname: "/profil/client",
                        // search: "?page=1&tab=1",
                      })
                    }
                  >
                    <RiUser3Line />
                  </Button>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="card-Table table-primary card">
        <>
          {creds.role === UserTypes.Client && (
            <div className="create-indem__button action-top-tabs client-create-invoice-wrapper">
              <FormGroup className="form-icon icon-start">
                <Button
                  classNamePrefix="select"
                  className="custom-select form-secondary"
                  onClick={() => {
                    setModelsViewModal(true);
                  }}
                >
                  Modéle
                </Button>
              </FormGroup>

              <FormGroup className="form-icon icon-start">
                <Button
                  options={InvoiceTypes}
                  closeMenuOnSelect={true}
                  // placeholder="Créer"
                  classNamePrefix="select"
                  className="custom-select form-secondary custom-facture-select"
                  onClick={(invoice) => {
                    setInvoiceType(null);
                    setCreateQuittanceModal(true);
                  }}
                  components={{ DropdownIndicator }}
                  // value={invoiceType}
                >
                  Créer
                </Button>
              </FormGroup>
            </div>
          )}
          <Tabs
            selectedTab={selectedTab}
            onClick={setSelectedTab}
            tabs={tabs.filter((elt) => {
              if (elt.allowedPacks.length > 0) {
                return elt.allowedPacks.includes(
                  creds.role === UserTypes.Client ? creds.pack : company?.pack!
                );
              } else return elt;
            })}
            context={context}
            company={company}
            client={client}
            reload={reload}
            callback={setReload}
            reloadParent={getInvoiceModels}
          />
        </>
      </div>
      <div className="openbtn text-center">
        {/*choose whaat kind of invoice*/}
        <Modal
          className="modal-secondary modal-dialog-centered"
          isOpen={createQuittanceModal}
          size={"sm"}
          toggle={() => {
            setCreateQuittanceModal(false);
            setInvoiceType(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setCreateQuittanceModal(false);
              setInvoiceType(null);
            }}
          >
            Créer une quittance
            {/* {invoiceType?.value} */}
          </ModalHeader>
          <ModalBody>
            <div className="sync-modal">
              <div className="action-button-wrapper">
                <Button
                  color="secondary"
                  outline
                  type="button"
                  onClick={() => {
                    setCreateQuittanceModal(false);
                    setModelsType(
                      InvoiceTypes.find(
                        (elt) => elt.value === invoiceType?.value
                      )
                    );
                    setIsLoaderModel(true);
                    setModelsViewModal(true);
                  }}
                >
                  Modéle déjà créé
                </Button>
              </div>
              <div className="action-button-wrapper">
                <Button
                  color="secondary"
                  outline
                  type="submit"
                  onClick={() => {
                    setCreateQuittanceModal(false);
                    setChooseClientTypeModal(true);
                  }}
                >
                  Nouveau modéle
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*choose reciever type b2b / b2c*/}
        <Modal
          className="modal-secondary modal-dialog-centered"
          isOpen={chooseClientTypeModal}
          size={"sm"}
          toggle={() => {
            setChooseClientTypeModal(false);
            setChooseClientType(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setChooseClientTypeModal(false);
              setChooseClientType(null);
            }}
          >
            Choisissez le type
          </ModalHeader>
          <ModalBody>
            <div className="sync-modal">
              <div className="action-button-wrapper">
                <Button
                  color="secondary"
                  outline
                  type="button"
                  onClick={() => {
                    setChooseClientTypeModal(false);
                    setChooseClientType(
                      ClientTypesArr.find((elt) => elt.value === "b2b")
                    );
                    setFillInvoiceModal(true);
                  }}
                >
                  B2B
                </Button>
              </div>
              <div className="action-button-wrapper">
                <Button
                  color="secondary"
                  outline
                  type="button"
                  onClick={() => {
                    setChooseClientTypeModal(false);
                    setChooseClientType(
                      ClientTypesArr.find((elt) => elt.value === "b2c")
                    );
                    setFillInvoiceModal(true);
                  }}
                >
                  B2C
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*view models*/}
        <Modal
          className="modal-primary modal-dialog-centered models-view-modal"
          isOpen={modelsViewModal}
          toggle={() => {
            setModelsViewModal(false);
            setIsLoaderModel(false);
            setInvoiceType(null);
            setModelsType(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setModelsViewModal(false);
              setIsLoaderModel(false);
              setInvoiceType(null);
              setModelsType(null);
            }}
          >
            Modéles Quittance
            {/* ({modelsType?.label}) */}
          </ModalHeader>
          <ModalBody>
            <div className="models-view-section ">
              {models.length > 0 ? (
                <ModelsTabs
                  orientation="vertical"
                  tabs={models.map((elt, index) => {
                    return {
                      index,
                      Component: () => (
                        <FactureHtml
                          quittance={elt}
                          client={client}
                          type={"model"}
                        />
                      ),
                      model: elt,
                    };
                  })}
                  context={creds.company_id}
                  callback={!isLoaderModel ? deleteModelWrapper : loadFromModel}
                  isLoaderModel={isLoaderModel}
                  loaderModelLoading={loaderModelLoading}
                />
              ) : (
                <div className="text-center">
                  <p>Il n'y a pas encore de modèle enregistré</p>
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>
        {/*delete model*/}
        <Modal
          className="modal-danger modal-dialog-centered"
          isOpen={modelDeleteModal}
          toggle={() => {
            setModelDeleteModal(false);
            setSingleModel(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setModelDeleteModal(false);
              setSingleModel(null);
            }}
          >
            Supprmier le modéle {singleModel?.id.toUpperCase()}
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir supprimer le modéle{" "}
                {singleModel?.id.toUpperCase()} ?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              onClick={() => {
                setModelDeleteModal(false);
                setSingleModel(null);
              }}
            >
              Non
            </Button>
            <Button
              color="danger"
              onClick={async () => await deleteInvoiceModel(singleModel?.id!)}
            >
              Oui
            </Button>
          </ModalFooter>
        </Modal>
        {/*create Asset*/}
        <Modal
          className="modal-primary modal-dialog-centered fill-invoice-modal"
          isOpen={fillInvoiceModal}
          size={"xl"}
          toggle={() => {
            setFillInvoiceModal(false);
            setInvoiceType(null);
            setChooseClientType(null);
            setSaveAsModelLoading(false);
            setSaveAndSendLoading(false);
            setSendLoading(false);
            setDownloadLoading(false);
            setNeedEmail(false);
            setNeedModelRef(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setFillInvoiceModal(false);
              setInvoiceType(null);
              setChooseClientType(null);
              setSaveAsModelLoading(false);
              setSaveAndSendLoading(false);
              setSendLoading(false);
              setDownloadLoading(false);
              setNeedEmail(false);
              setNeedModelRef(false);
            }}
          >
            Créer une quittance
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <CreateQuittance
                model={"Quittance"}
                invoiceSubCategs={[]}
                formData={formData}
                setFormData={setFormData}
                needEmail={needEmail}
                setNeedEmail={setNeedEmail}
                clientType={chooseClientType!}
                needModelRef={needModelRef}
                setNeedModelRef={setNeedModelRef}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              outline
              type="button"
              onClick={() => {
                setFillInvoiceModal(false);
                setInvoiceType(null);
                setChooseClientType(null);
                setSaveAsModelLoading(false);
                setSaveAndSendLoading(false);
                setSendLoading(false);
                setDownloadLoading(false);
                setNeedEmail(false);
              }}
            >
              Retour
            </Button>
            <Button
              color="success"
              outline
              type="button"
              onClick={async () => {
                // console.log("formdata : ",formData)
                await saveAsModel();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {saveAsModelLoading ? (
                <Spinner color="success" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Enregistrer comme modéle"
              )}
            </Button>
            <Button
              color="primary"
              outline
              type="button"
              onClick={async () => {
                await saveAndSend();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {saveAndSendLoading ? (
                <Spinner color="success" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Enregistrer comme modéle et envoyer"
              )}
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={async () => {
                await send();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {sendLoading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Envoyer"
              )}
            </Button>
            <Button
              color="success"
              type="button"
              onClick={async () => {
                await download();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {downloadLoading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Télécharger"
              )}
            </Button>
          </ModalFooter>
        </Modal>
        {/*create Asset from model*/}
        <Modal
          className="modal-primary modal-dialog-centered fill-invoice-modal"
          isOpen={createFromModelModal}
          size={"xl"}
          toggle={() => {
            setCreateFromModelModal(false);
            setInvoiceType(null);
            setChooseClientType(null);
            setSaveAsModelLoading(false);
            setSaveAndSendLoading(false);
            setSendLoading(false);
            setDownloadLoading(false);
            setNeedEmail(false);
            setNeedModelRef(false);
            setSingelQuittance(null);
            setLoaderModelLoading(null);
            setNeedModelRef(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setCreateFromModelModal(false);
              setInvoiceType(null);
              setChooseClientType(null);
              setSaveAsModelLoading(false);
              setSaveAndSendLoading(false);
              setSendLoading(false);
              setDownloadLoading(false);
              setNeedEmail(false);
              setNeedModelRef(false);
              setSingelQuittance(null);
              setLoaderModelLoading(null);
              setModelsType(null);
            }}
          >
            Créer quittance
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <LoadQuittance
                formData={formData}
                setFormData={setFormData}
                needEmail={needEmail}
                setNeedEmail={setNeedEmail}
                clientType={{
                  label:
                    singleQuittance?.clientType === ClientTypes.B2B
                      ? "B2B"
                      : "B2C",
                  value: singleQuittance?.clientType,
                }}
                invoice={singleQuittance!}
                needModelRef={needModelRef}
                setNeedModelRef={setNeedModelRef}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              outline
              type="button"
              onClick={() => {
                setCreateFromModelModal(false);
                setInvoiceType(null);
                setChooseClientType(null);
                setSaveAsModelLoading(false);
                setSaveAndSendLoading(false);
                setSendLoading(false);
                setDownloadLoading(false);
                setNeedEmail(false);
                setNeedModelRef(false);
                setSingelQuittance(null);
                setLoaderModelLoading(null);
                setModelsType(null);
              }}
            >
              Retour
            </Button>
            <Button
              color="success"
              outline
              type="button"
              onClick={async () => {
                setModelsType(null);
                await load_saveAsModel();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {saveAsModelLoading ? (
                <Spinner color="success" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Enregistrer comme modéle"
              )}
            </Button>
            <Button
              color="primary"
              outline
              type="button"
              onClick={async () => {
                setModelsType(null);
                await load_saveAndSend();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {saveAndSendLoading ? (
                <Spinner color="success" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Enregistrer comme modéle et envoyer"
              )}
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={async () => {
                setModelsType(null);
                await load_send();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {sendLoading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Envoyer"
              )}
            </Button>
            <Button
              color="success"
              type="button"
              onClick={async () => {
                setModelsType(null);
                await load_download();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {downloadLoading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Télécharger"
              )}
            </Button>
          </ModalFooter>
        </Modal>
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </div>
  );
};

export default Quitance;

import { useFormatter } from "./useFormatter";

const useTVA = () => {
  const { setDecimalDigits } = useFormatter();

  const setTVA = (amount: number, percent: number) => {
    return parseFloat(setDecimalDigits(amount - amount / (1 + percent)));
  };

  return {
    setTVA,
  };
};

export { useTVA };

import React from "react";
import "./style.scss";
import { Banners } from "../../../interfaces";
import { Button } from "reactstrap";
import arrowIconLogo from "../../../assets/images/icons/arrow-iconn.png";
type BannerProps = {
  banner: Banners;
  children?: string;
  className?: string;
  callback(param: any): void;
  context?: string | number;
};
const VehiculeBanner: React.FC<BannerProps> = ({
  banner,
  children,
  className,
  callback,
  context = "",
}) => {
  const banerStyle = {
    background: banner.background,
    backgroundImage: banner.backgroundImage,
  };
  return (
    <div className={`vehicule-banner primary`} style={banerStyle}>
      <div className="vehicule-banner__text">
        <h2 className="vehicule-banner__title">{banner.title}</h2>
        {context !== "" && <h2>({context})</h2>}
        {banner.button && banner.button.status && (
          <Button className="retour-btn btn btn-secondary" onClick={callback!}>
            Voir <img src={arrowIconLogo} alt="icon" />
          </Button>
        )}
      </div>
      <div className="vehicule-banner__img">
        <img src={banner.image} alt={banner.title} />
      </div>

      {children}
    </div>
  );
};

export default VehiculeBanner;

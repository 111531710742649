import React, { useEffect } from "react";
import "./LineCharts.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-style";
import { AnnualSoldeTresTypes } from "../Dashboard";
import moment, { Moment } from "moment";
import { ShadowPlugin } from "../LineChartsMu/LineChartsMu";
import { Spinner } from "reactstrap";

moment.updateLocale("fr", {});

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  {
    id: "AnnualChart",
    beforeDraw: (chart: { ctx: any }, args: any, options: any) => {
      const { ctx } = chart;
      ctx.shadowColor = "rgba(0, 0, 0, 0.25)";
      ctx.shadowBlur = 10;
      ctx.shadowOffsetX = 10;
      ctx.shadowOffsetY = 10;
    },
  }
);

export const options = {
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "",
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          let label = context.dataset.label || "";
          if (label) {
            label += ": ";
          }
          if (context.raw !== null) {
            label += parseFloat(context.raw).toFixed(2) + " €";
          }

          return label;
        },
      },
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        callback: function (value: any) {
          return `${value} €`;
        },
      },
    },
  },
};

const labels = [
  "01/2021",
  "02/2021",
  "03/2021",
  "03/2021",
  "04/2021",
  "06/2021",
  "07/2021",
  "08/2021",
  "09/2021",
];

// export const data = {
//   labels: [] as string[],
//   datasets: [
//     {
//       label: "Solde",
//       data: [3000, 2200, 2500, 2750, 2850, 2120, 2200, 2520, 2750],
//       backgroundColor: "#000DC4",
//       borderColor: "#000DC4",
//       color: "red",
//       lineTension: 0.25,
//       borderWidth: 6,
//       pointBorderWidth: 1,
//     },
//   ] as {
//     label: string;
//     data: (number | null | undefined)[];
//     backgroundColor: string;
//     borderColor: string;
//     color: string;
//     lineTension: number;
//     borderWidth: number;
//     pointBorderWidth: number;
//   }[],
// };

export const renderChart = (chartData: any, labels: any) => {
  return {
    labels: labels as string[],
    datasets: [
      {
        label: "Solde",
        data: chartData,
        backgroundColor: "#000DC4",
        borderColor: "#000DC4",
        color: "red",
        lineTension: 0.25,
        borderWidth: 6,
        pointBorderWidth: 1,
      },
    ] as {
      label: string;
      data: (number | null | undefined)[];
      backgroundColor: string;
      borderColor: string;
      color: string;
      lineTension: number;
      borderWidth: number;
      pointBorderWidth: number;
    }[],
  };
};

const LineCharts = ({
  annualSoldeTresData,
  loading,
}: {
  annualSoldeTresData: AnnualSoldeTresTypes;
  loading: boolean;
}) => {
  // useEffect(() => {
  //   if (annualSoldeTresData.annualVATSoldeTres.length > 0) {
  //     data.labels = annualSoldeTresData.annualVATSoldeTres.map(
  //       (elt) => `${elt.shortMonth}/${moment().get("year")}`
  //     );
  //     data.datasets[0].data = annualSoldeTresData.annualVATSoldeTres.map(
  //       (elt) => elt.soldeTresTotal
  //     );
  //   }
  // }, [annualSoldeTresData.annualVATSoldeTres]);

  return (
    <>
      <div className="charts">
        {loading || annualSoldeTresData.annualVATSoldeTres.length === 0 ? (
          <div className="loading-chart">
            <Spinner type="border" size={"md"} className="chart-spinner">
              Loading...
            </Spinner>
            <Line
              options={options}
              data={{
                labels: [] as string[],
                datasets: [],
              }}
            />
          </div>
        ) : (
          <Line
            options={options}
            data={renderChart(
              annualSoldeTresData.annualVATSoldeTres.map(
                (elt) => elt.soldeTresTotal
              ),
              annualSoldeTresData.annualVATSoldeTres.map(
                (elt) => `${elt.shortMonth}/${moment().get("year")}`
              )
            )}
            redraw
          />
        )}
      </div>
    </>
  );
};

export default React.memo(
  LineCharts,
  (prevProps, nextProps) =>
    prevProps.annualSoldeTresData.annualVATSoldeTres.length ===
    nextProps.annualSoldeTresData.annualVATSoldeTres.length
);

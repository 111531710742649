import React from "react";

interface FileIconProps {
  className?: string;
}

const FileIconUpload = ({ className }: FileIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39.795"
    height="39.795"
    viewBox="0 0 39.795 39.795"
    className={className}
  >
    <path
      id="Tracé_91"
      data-name="Tracé 91"
      d="M672.384,497.253c-.047.23-.089.46-.14.689a5.813,5.813,0,0,1-1.617,2.9c-4.475,4.474-8.935,8.963-13.429,13.417a7.874,7.874,0,0,1-8,2.119,8.011,8.011,0,0,1-5.726-6.517,7.9,7.9,0,0,1,2.322-6.981q6.661-6.7,13.358-13.368c.04-.04.082-.077.1-.092l1.619,1.624c-.03.031-.1.112-.178.188q-6.6,6.6-13.2,13.2a5.709,5.709,0,0,0-1.752,3.436,5.521,5.521,0,0,0,2.184,5.315,5.679,5.679,0,0,0,7.63-.532q6.747-6.716,13.465-13.461a3.331,3.331,0,0,0,.911-3.485,3.272,3.272,0,0,0-2.6-2.382,3.339,3.339,0,0,0-2.96.763c-.1.084-.2.178-.289.272q-6.672,6.671-13.344,13.342a1.2,1.2,0,0,0-.4,1.272,1.138,1.138,0,0,0,1.807.562,3.524,3.524,0,0,0,.289-.271q5.21-5.208,10.416-10.419a1.622,1.622,0,0,0,.182-.266l1.669,1.669c-.043.046-.1.108-.157.167q-5.289,5.289-10.579,10.578a3.453,3.453,0,0,1-5.123-4.632c.1-.113.205-.222.312-.329q6.631-6.632,13.261-13.265a6.069,6.069,0,0,1,2.985-1.691,5.764,5.764,0,0,1,6.809,4.216c.066.274.119.551.178.827Z"
      transform="translate(-801.013 129.375) rotate(-45)"
      fill="#45b49c"
    />
  </svg>
);

export default FileIconUpload;

import React, { useState } from "react";
import "./ErrorSynchro.scss";
import {
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import ErrorIconOrange from "../../assets/images/svg/erros-icons.svg";

export interface ErrorSynchroProps {}
const ErrorSynchro: React.FC<ErrorSynchroProps> = ({}) => {
  const [open, setOpen] = useState(false);
  const ontoggle = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="page page-prospect">
      <div className="col-md-11 mx-auto col-sm-12">
        <Card className="card-modal-box">
          <h3>Erreur de synchronisation</h3>

          <div className="block-img-center">
            <img src={ErrorIconOrange} alt="icon" />
          </div>

          <p>
            Une erreur est survenue lors de la synchronisation, merci de
            vérifier votre compte bancaire
          </p>
          <div className="openbtn text-center">
            <Modal
              className="modal-secondary modal-dialog-centered modal-lg"
              isOpen={open}
              toggle={() => setOpen(false)}
            >
              <ModalHeader toggle={() => setOpen(false)}>
                Créer un nouveau lead
              </ModalHeader>
              <ModalBody>
                <div className="content-form-block"></div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  outline
                  onClick={function noRefCheck() {}}
                >
                  Annuler
                </Button>
                <Button color="secondary" onClick={function noRefCheck() {}}>
                  Créer
                </Button>
              </ModalFooter>
            </Modal>

            {/* <Modal
              className="modal-warning modal-dialog-centered modal-lg"
              isOpen={open}
              toggle={() => setOpen(false)}
            >
              <ModalHeader toggle={() => setOpen(false)}>
                Créer un nouvel utilisateur
              </ModalHeader>
              <ModalBody>
                <div className="content-form-block">
                  <Row>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="name">Nom</Label>
                        <Input
                          id="name"
                          name="name"
                          placeholder="Nom"
                          type="text"
                          className="form-warning"
                        />
                        <span className="icon icon-warning ">
                          <img src={UserProsp} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="prenom">Prenom</Label>
                        <Input
                          id="prenom"
                          name="prenom"
                          placeholder="Prenom"
                          type="text"
                          className="form-warning"
                        />
                        <span className="icon icon-warning ">
                          <img src={UserProsp} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="email">Email</Label>
                        <Input
                          id="email"
                          name="prenom"
                          placeholder="Email"
                          type="text"
                          className="form-warning"
                        />
                        <span className="icon icon-warning ">
                          <img src={EmailIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="phone">Téléohone</Label>
                        <Input
                          id="phone"
                          name="phone"
                          placeholder="Téléohone"
                          type="text"
                          className="form-warning"
                        />
                        <span className="icon icon-warning ">
                          <img src={PhoneIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="pack">Pack</Label>
                        <Select
                          options={status}
                          closeMenuOnSelect={false}
                          isMulti
                          classNamePrefix="select"
                          className="custom-select form-warning"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="pack">Genre</Label>
                        <Select
                          options={status}
                          closeMenuOnSelect={false}
                          isMulti
                          classNamePrefix="select"
                          className="custom-select form-warning"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="pwd">Mot de passe utilisateur</Label>
                        <Input
                          id="pwd"
                          name="pwd"
                          placeholder="Mot de passe"
                          type="text"
                          className="form-warning"
                        />
                        <span className="icon icon-warning ">
                          <img src={KeyIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="cpwd">Confirme mot de passe</Label>
                        <Input
                          id="cpwd"
                          name="cpwd"
                          placeholder="Confirme mot de passe"
                          type="text"
                          className="form-warning"
                        />
                        <span className="icon icon-warning ">
                          <img src={KeyIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="pack">Application associée</Label>
                        <Select
                          options={status}
                          closeMenuOnSelect={false}
                          isMulti
                          classNamePrefix="select"
                          className="custom-select form-warning"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="warning"
                  outline
                  onClick={function noRefCheck() {}}
                >
                  Annuler
                </Button>
                <Button color="warning" onClick={function noRefCheck() {}}>
                  Enregistrer
                </Button>
              </ModalFooter>
            </Modal> */}
            {/* <Modal
              className="modal-danger"
              isOpen={open}
              toggle={() => setOpen(false)}
            >
              <ModalHeader toggle={() => setOpen(false)}>
                Supprimer un client
              </ModalHeader>
              <ModalBody>
                <div className="content-text p-5">
                  <p className="msg-text">
                    Vous êtes sur de vouloir supprimer le client Elans?
                  </p>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  outline
                  onClick={function noRefCheck() {}}
                >
                  Non
                </Button>
                <Button color="danger" onClick={function noRefCheck() {}}>
                  Oui
                </Button>
              </ModalFooter>
            </Modal> */}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ErrorSynchro;

import React from "react";
import T0 from "./blue-0.png";
import T100 from "./blue-100.png";
import T20 from "./blue-20.png";
import T40 from "./blue-40.png";
import T60 from "./blue-60.png";
import T80 from "./blue-80.png";

const CircleBlue = ({
  value,
  percentage = 20,
  label,
  className,
}: {
  value: number;
  percentage: number;
  label: string;
  className?: string;
}) => {
  return (
    <div className={className}>
      {percentage < 20 && percentage > 0 && (
        <div className="wrapper-20">
          <img src={T20} alt="blue-20" />
          <span className="text-20 blue-text generic-buble blue-bubble">
            {label} : {value} €
          </span>
        </div>
      )}
      {percentage < 40 && percentage >= 20 && (
        <div className="wrapper-40">
          <img src={T40} alt="blue-40" />
          <span className="text-40 blue-text generic-buble blue-bubble">
            {label} : {value} €
          </span>
        </div>
      )}
      {percentage < 60 && percentage >= 40 && (
        <div className="wrapper-60">
          <img src={T60} alt="blue-60" />
          <span className="text-60 blue-text generic-buble blue-bubble">
            {label} : {value} €
          </span>
        </div>
      )}
      {percentage < 100 && percentage >= 60 && (
        <div className="wrapper-80">
          <img src={T80} alt="blue-80" />
          <span className="text-80 blue-text generic-buble blue-bubble">
            {label} : {value} €
          </span>
        </div>
      )}
      {percentage === 100 && (
        <div className="wrapper-100">
          <img src={T100} alt="blue-100" />
          <span className="text-100 blue-text generic-buble blue-bubble">
            {label} : {value} €
          </span>
        </div>
      )}
      {percentage === 0 && (
        <div className="wrapper-0">
          <img src={T0} alt="blue-0" />
          <span className={`text-0 blue-text generic-buble blue-bubble${percentage === 0 ? " _grey" : ""}`}>
            {label} : {value} €
          </span>
        </div>
      )}
    </div>
  );
};

export default CircleBlue;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router";
import { Company, User } from "../../../interfaces";
import { UserTypes } from "../../../util/context";
import { ErrorLogger } from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import CompteSynchronise from "../../CompanyInfo/CompteSynchronise/CompteSynchronise";

function SyncCompanyWrapper() {
  const { context, company, callback,client } = useOutletContext() as any;
  return (
    <CompteSynchronise
      context={context}
      client={client}
      company={company}
      callback={callback}
    />
  );
}

export default SyncCompanyWrapper;

import React, { useEffect, useState } from "react";
import "./FacturationImport.scss";

import { Link, useNavigate, useParams } from "react-router-dom";
import Tabs from "../../components/Tabs";
import InvoiceTransactions from "./InvoiceTransactions/InvoiceTransactions";

import { Company, User } from "../../interfaces";
import { useSelector } from "react-redux";
import useAxios from "../../util/hooks/useAxios";
import { Badge, Button, Card, Col, Row } from "reactstrap";
import BannerTop from "../../components/Banner/BannerTop";
import { RiUser3Line } from "react-icons/ri";
import UserInfo from "../../components/user-info/UserInfo";
import data from "../../constants/data";
import { UserTypes } from "../../util/context";
import CrownIcon from "../../assets/CrownIcon";
import { colors } from "../../constants";
import IconPlusYellow from "../../assets/images/svg/puls-icon-yellow.svg";
import Select, { components } from "react-select";
import FileViewer from "../../components/FileViewer";
import { ErrorLogger } from "../../util/errorLogger";
import moment from "moment";
import "moment/locale/fr";
import { toast, ToastContainer } from "react-toastify";

import InvoiceImport from "./InvoiceImport";
import InvoiceTransactionsEnc from "./InvoiceTransactionsEnc/InvoiceTransactionsEnc";
import InvoiceTransactionsDec from "./InvoiceTransactionsDec/InvoiceTransactionsDec";

export interface FactureProps {}
export type TabsType = {
  label: string;
  index: number;
  Component: React.FC;
}[];

// Tabs Array
const tabs: TabsType = [
  {
    label: "Importer une facture",
    index: 1,
    Component: InvoiceImport,
  },
  {
    label: "Factures d'encaissement",
    index: 2,
    Component: InvoiceTransactionsEnc,
  },
  {
    label: "Factures de décaissement",
    index: 3,
    Component: InvoiceTransactionsDec,
  },
];

const FacturationImport = ({
  client,
  context,
  company,
  callback,
}: {
  client?: User;
  context?: string;
  company?: Company;
  callback?: () => void;
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);
  const [reload, setReload] = useState<boolean>(false);

  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);

  const { user, company: userCompany } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user
  ) as { user: User; company: Company };

  const { newNotif } = useSelector(
    (state: { messagesNotifs: object }) => state.messagesNotifs
  ) as { newNotif: boolean };

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as {
    user_id: string;
    company_id: string;
    token: string;
    role: string;
    pack: string;
  };

  const navigate = useNavigate();
  let api = useAxios();

  return (
    <div className="page section-facturation">
      
      {user.id && user.role === UserTypes.Client ? (
        <>
          <div
            className={`top-content ${
              creds.role === UserTypes.Client ? "client-resize-header" : ""
            }`}
          >
            <Row>
              <Col lg={8} md={12} className="blockBanner">
                <BannerTop banner={data.invoice} />
              </Col>
              <Col lg={4} md={12}>
                <Card className="blockUser">
                  <Button
                    className="btn-icon btn-icon-primary"
                    color="light"
                    onClick={() => navigate("/tickets")}
                  >
                    {newNotif && <Badge color="primary"> </Badge>}
                    <CrownIcon stroke={colors.primaryColor} />
                  </Button>
                  <UserInfo user={user} />
                  <Button
                    className="btn-icon btn-icon-primary"
                    color="light"
                    onClick={() =>
                      navigate({
                        pathname: "/profil/client",
                      })
                    }
                  >
                    <RiUser3Line />
                  </Button>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="card-Table table-primary card">
        <>
          <Tabs
            selectedTab={selectedTab}
            onClick={setSelectedTab}
            tabs={tabs}
            context={context}
            company={company}
            client={client}
            reload={reload}
            callback={setReload}
          />
        </>
      </div>
      <div className="openbtn text-center">
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </div>
  );
};

export default FacturationImport;

import React from "react";

interface PlusProps {
  className?: string;
}

const PlusIconGig = ({ className }: PlusProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="45"
    viewBox="0 0 45 45"
    className={className}
  >
    <g id="Groupe_69" data-name="Groupe 69" transform="translate(-1772 -988)">
      <rect
        id="Rectangle_42"
        data-name="Rectangle 42"
        width="3"
        height="45"
        transform="translate(1793 988)"
        fill="#fff"
      />
      <rect
        id="Rectangle_43"
        data-name="Rectangle 43"
        width="3"
        height="45"
        transform="translate(1817 1009) rotate(90)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default PlusIconGig;

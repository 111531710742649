import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router";
import { Company, User } from "../../../interfaces";
import { UserTypes } from "../../../util/context";
import { ErrorLogger } from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import GestionDocuments from "../../ClientInfo/GestionDocuments/GestionDocuments";

function GedClientWrapper() {
  const { client, company, callback } = useOutletContext() as any;

  return (
    <GestionDocuments
      client={client}
      company={company}
      callback={callback}
    />
  );
}

export default GedClientWrapper;

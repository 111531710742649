import React, { useEffect, useState } from "react";
import "./InvoiceTransactionsDec.scss";
import {
  Company,
} from "../../../interfaces";

import { InvoiceTransactionParentTypes } from "../../../util/context";
import InvoiceTransactions from "../InvoiceTransactions/InvoiceTransactions";

export interface FactProps {}

const InvoiceTransactionsDec = ({
  company,
  callback,
}: {
  company?: Company;
  callback?: any;
}) => {
  return (
    <InvoiceTransactions company={company} callback={callback} parentType={InvoiceTransactionParentTypes.Dec} />
  );
};

export default InvoiceTransactionsDec;

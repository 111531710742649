import React from "react";

interface CheckProps {
  className?: string;
}

const CheckIcon = ({ className }: CheckProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.903"
    height="13.122"
    viewBox="0 0 16.903 13.122"
    className={className}
  >
    <path
      id="Tracé_66"
      data-name="Tracé 66"
      d="M-465.138,719.372c.088-.1.136-.168.193-.225q3.979-3.981,7.96-7.961a1.282,1.282,0,0,1,1.546-.309,1.734,1.734,0,0,1,.374.271c.184.168.356.349.531.526a1.293,1.293,0,0,1,0,1.936q-1.232,1.24-2.472,2.473-3.723,3.723-7.444,7.448a.876.876,0,0,1-.966.275,1.022,1.022,0,0,1-.366-.24q-2.4-2.388-4.789-4.786a1.288,1.288,0,0,1,0-1.92c.18-.182.36-.365.543-.543a1.285,1.285,0,0,1,1.878.007q1.427,1.417,2.845,2.844C-465.255,719.219-465.213,719.281-465.138,719.372Z"
      transform="translate(471 -710.736)"
      fill="#fff"
    />
  </svg>
);

export default CheckIcon;

import React from "react";

interface SendIconProps {
  className?: string;
}

const SendIconOrange = ({ className }: SendIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37.336"
    height="37.19"
    viewBox="0 0 37.336 37.19"
    className={className}
  >
    <path
      data-name="Tracé 97"
      d="M-336.465,501.75c-.714.6-1.422,1.2-2.144,1.793q-7.549,6.153-15.1,12.3c-.073.06-.14.128-.244.222l17.545,14.342v-8.893c.95.13,1.857.2,2.742.384a19.735,19.735,0,0,1,14.16,10.608c.942,1.8,1.639,3.734,2.449,5.606a5.5,5.5,0,0,0,.417.828c.006-.086.018-.172.018-.258,0-2.444.01-4.889,0-7.333a20.869,20.869,0,0,0-17.08-20.41c-.763-.14-1.541-.2-2.312-.3-.119-.015-.238-.027-.377-.043V501.75Z"
      transform="translate(353.955 -501.75)"
      fill="#ffb254"
    />
  </svg>
);

export default SendIconOrange;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router";
import { Company, User } from "../../../interfaces";
import { UserTypes } from "../../../util/context";
import { ErrorLogger } from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import IbanTelepaiement from "../../CompanyInfo/IbanTelepaiement/IbanTelepaiement";

function IbanCompanyWrapper() {
  const { context, company, callback } = useOutletContext() as any;
  return (
    <IbanTelepaiement
      context={context}
      company={company}
      callback={callback}
    />
  );
}

export default IbanCompanyWrapper;

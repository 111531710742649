import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createAxiosInsatance } from "../../../util/helpers/createAxiosInsatance";

const initialState = {
  counter: null,
  errorMessage: false,
  reloadTickets: false,
  countNotif: {},
  loading: "idle",
  currentRequestId: undefined as any,
  error: null as any,
};

export const getCountNotifs = createAsyncThunk(
  "applications/getCountNotifs",
  async (_, { getState, requestId, dispatch }) => {
    const thunkState = getState() as any;
    let api = createAxiosInsatance(thunkState.root.token, dispatch, setCounter);
    const { data } = await api!.post(
      `/api/stats/getCountedTransactions`,
      {
        company_id: thunkState.root.company_id,
      },
      {
        headers: {
          "x-access-token": thunkState.root.token,
        },
      }
    );
    return data.data;
  }
);

export const utilSlice = createSlice({
  name: "util",
  initialState,
  reducers: {
    setCounter: (state: { counter: any }, action: { payload: any }) => {
      state.counter = action.payload;
    },

    setErrorMessage: (
      state: { errorMessage: any },
      action: { payload: any }
    ) => {
      state.errorMessage = action.payload;
    },

    setReloadTickets: (
      state: { reloadTickets: any },
      action: { payload: any }
    ) => {
      state.reloadTickets = action.payload;
    },

    seeCountNotif: (state: any) => {
      const oldState = JSON.parse(JSON.stringify(state.countNotif));
      state.countNotif = {
        ...oldState,
        seen: true,
      };
    },

    resetUtil: (state: any) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getCountNotifs.pending, (state: any, action: any) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getCountNotifs.fulfilled, (state: any, action: any) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.countNotif = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getCountNotifs.rejected, (state: any, action: any) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const { resetUtil,setCounter, setErrorMessage, setReloadTickets, seeCountNotif } =
  utilSlice.actions;
export default utilSlice.reducer;

import React from "react";

interface ArchiveProps {
  className?: string;
}

const DeArchiveIcon = (props : any) => (
  <svg
  id="Calque_1"
  xmlns="http://www.w3.org/2000/svg"
  x={0}
  y={0}
  viewBox="0 0 223.7 183"
  style={{
    enableBackground: "new 0 0 223.7 183",
  }}
  xmlSpace="preserve"
  {...props}
>
  <style>{".st1{fill:#ffb254}"}</style>
  <switch>
    <g>
      <path
        className="st1"
        d="M43.9 166.5c44.3-31 88.6-62 133.6-93.6.2 2.1.5 3.5.5 4.9v80.5c0 7.9-1.5 9.5-9.2 9.6h-122c-.8 0-1.6-.1-2.3-.1-.2-.5-.4-.9-.6-1.3zM209.8 12.1c4.7 6.6 9.1 13 13.8 19.7-70 49-139.7 97.7-209.8 146.8C9.1 172 4.7 165.8 0 159.1c70.1-49 139.8-97.9 209.8-147zM15 39.8c0-9.7-.2-19 .1-28.3.1-5.2 2.2-6.7 8.4-6.7h158.3c3.5 0 7.1.3 7.8 4.4.7 4 1.5 7.9-3.3 10.9-8.7 5.4-16.9 11.8-25.4 17.5-2.1 1.4-4.9 2.4-7.3 2.4-45.1.1-90.2.1-135.4.1-.9 0-1.7-.1-3.2-.3zM27.1 131.4v-78h111.4c-12.7 8.9-24.6 17.4-36.8 25.6-1.7 1.2-4.5 1-6.7 1.1-5.2.2-10.3 0-15.5.1-3.9 0-7.5.8-7.9 5.6-.4 5 2.7 6.7 7.1 7 .5 0 .9.2 2.7.6-18.4 12.9-35.9 25.1-54.3 38z"
      />
    </g>
  </switch>
</svg>
);

export default DeArchiveIcon;

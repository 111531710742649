import { createSlice } from "@reduxjs/toolkit";
import { rootPersistConfig } from "../..";
import { ITicketNotif } from "../../../interfaces";

export interface DynamicNotifs<T> {
  [key: string]: T;
}

export interface IMessageNotifs {
  newNotif: boolean;
  tickets: {
    [prop: string]: ITicketNotif[];
  };
}

const initialState: IMessageNotifs = {
  newNotif: false,
  tickets: {},
};

export const messagesNotifsSlice = createSlice({
  name: "messagesNotifs",
  initialState,
  reducers: {
    openNewNotifBadge: (state: { newNotif: boolean }) => {
      state.newNotif = true;
    },
    closeNewNotifBadge: (state: { newNotif: boolean }) => {
      state.newNotif = false;
    },
    setTicketNotifs: (
      state: any,
      action: { payload: { ticket: string; notifications: ITicketNotif[] } }
    ) => {
      const oldState = JSON.parse(JSON.stringify(state.tickets));
      state.tickets = {
        ...oldState,
        [action.payload.ticket]: action.payload.notifications,
      };
    },
    updateTicketNotifs: (state: any, action: { payload: ITicketNotif }) => {
      const oldState = JSON.parse(JSON.stringify(state.tickets));

      const serializedState = JSON.parse(
        localStorage.getItem("persist:" + rootPersistConfig.key)!
      );

      const arr =
        oldState[action.payload.ticket_id].findIndex((elt: { id: string }) => {
          return (
            elt.id === action.payload.id &&
            action.payload.user_id ===
              serializedState.user_id.replace(/["']/g, "")
          );
        }) === -1
          ? [...oldState[action.payload.ticket_id], action.payload]
          : oldState[action.payload.ticket_id];

      state.tickets = {
        ...oldState,
        [action.payload.ticket_id]: arr,
      };
    },
    resetTicketNotifs: (
      state: { tickets: any },
      action: { payload: { ticket_id: any } }
    ) => {
      state.tickets = {
        ...state.tickets,
        [action.payload.ticket_id]: [],
      };
    },
    resetNotifs: (state: any) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  openNewNotifBadge,
  closeNewNotifBadge,
  setTicketNotifs,
  resetTicketNotifs,
  updateTicketNotifs,
  resetNotifs
} = messagesNotifsSlice.actions;
export default messagesNotifsSlice.reducer;

import React from "react";

const useOutsideClick = (
  callback: any,
  index: string = "",
  state: boolean = false
) => {
  const ref = React.useRef() as any;

  React.useEffect(() => {
    const handleClick = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(index);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return ref;
};

export { useOutsideClick };

export const deleteWhere = async (
  api: any,
  token: string,
  model: string,
  where: any
) => {
  try {
    await api.post(
      `/api/${model}/deleteWhere`,
      {
        where,
      },
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
  } catch (error: any) {
    console.error("deleteWhere : ", error);
  }
};

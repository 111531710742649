import React from "react";

import "./Status.scss";
type BannerProps = {
  children?: JSX.Element | JSX.Element[];
  className?: string;
  title?: string;
  type?: string;
};
const Status: React.FC<BannerProps> = ({
  children,
  className,
  type,
  title,
}) => {
  return (
    <span className={`btn-status btn btn-small btn-${type} ${className}`}>
      {children} {title}
    </span>
  );
};

export default Status;

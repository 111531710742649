import React from "react";
import { colors } from "../constants";

interface ReviewProps {
  className?: string;
}

const ReviewIcon = ({ className }: ReviewProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.865"
    height="24.623"
    viewBox="0 0 26.865 24.623"
    className={className}
  >
    <g
      id="Groupe_300"
      data-name="Groupe 300"
      transform="translate(71.25 99.411)"
    >
      <path
        id="Tracé_404"
        data-name="Tracé 404"
        d="M-71.25,29.135c.281-.515.61-.711,1.027-.608.437.107.641.428.655,1.028a5.884,5.884,0,0,0,5.189,5.62,7.247,7.247,0,0,0,.758.044q3.882,0,7.763,0h.283v-.309c0-1.067.006-2.133,0-3.2a.851.851,0,0,1,.505-.893.877.877,0,0,1,1.019.254q2.953,2.832,5.917,5.652a.878.878,0,0,1,0,1.473q-2.959,2.825-5.918,5.65a.928.928,0,0,1-.743.321.821.821,0,0,1-.771-.866c-.006-1.093,0-2.186,0-3.278,0-.1,0-.19,0-.332h-.319c-2.317,0-4.634.024-6.95-.013A8.65,8.65,0,0,1-71.1,32.6c-.056-.316-.1-.634-.15-.951Z"
        transform="translate(0 -118.963)"
        fill={colors.secondaryColor}
      />
      <path
        id="Tracé_405"
        data-name="Tracé 405"
        d="M.173-84.371c-.007,0-.018.008-.021.015-.24.482-.574.686-.982.6-.437-.093-.668-.428-.679-.983a5.874,5.874,0,0,0-5.738-5.712c-2.684-.023-5.368-.006-8.051-.006-.059,0-.118.008-.2.015v.293c0,1.066-.006,2.133,0,3.2a.85.85,0,0,1-.506.892.879.879,0,0,1-1.019-.255q-2.952-2.832-5.916-5.652a.874.874,0,0,1,.006-1.471Q-19.966-96.27-17-99.1a.889.889,0,0,1,.749-.3.824.824,0,0,1,.745.889c0,1.084,0,2.168,0,3.252,0,.1,0,.191,0,.333h.345c2.308,0,4.616-.023,6.924.012A8.645,8.645,0,0,1,.023-87.842c.055.317.1.635.151.952Z"
        transform="translate(-44.558)"
        fill={colors.secondaryColor}
      />
    </g>
  </svg>
);

export default ReviewIcon;

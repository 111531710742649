import React from "react";

interface ViewProps {
  className?: string;
}

const ViewIcon = ({ className }: ViewProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.931"
    height="22.943"
    viewBox="0 0 30.931 22.943"
    className={className}
  >
    <g
      id="Groupe_62"
      data-name="Groupe 62"
      transform="translate(-1145.843 73.475)"
    >
      <path
        id="Tracé_86"
        data-name="Tracé 86"
        d="M1176.774,24.578c-.591.6-1.152,1.233-1.78,1.792a24.179,24.179,0,0,1-8.053,4.868,16.679,16.679,0,0,1-5.793,1.005,17.534,17.534,0,0,1-6.973-1.606,25.59,25.59,0,0,1-8-5.664c-.44-.454-.442-.7,0-1.154a24.959,24.959,0,0,1,9.6-6.294,16.406,16.406,0,0,1,12.043.358,25.438,25.438,0,0,1,8.535,5.827c.153.156.282.336.422.505Zm-22.1-.173a6.642,6.642,0,1,0,6.634-6.65A6.654,6.654,0,0,0,1154.672,24.4Zm12.175,5.589a24.444,24.444,0,0,0,8.477-5.579c-1.616-1.931-6.906-5.406-8.415-5.539A7.924,7.924,0,0,1,1166.848,29.994Zm-11.129-11.131c-1.865.363-7.172,3.87-8.391,5.543,1.639,1.932,6.889,5.379,8.393,5.511A7.9,7.9,0,0,1,1155.719,18.863Z"
        transform="translate(0 -82.777)"
        fill="#2e9ccb"
      />
      <path
        id="Tracé_87"
        data-name="Tracé 87"
        d="M1331.7-71.029c0,.562,0,1.124,0,1.687a.619.619,0,0,1-.59.694.618.618,0,0,1-.615-.7q0-1.717,0-3.433a.616.616,0,0,1,.59-.694c.365-.008.613.27.616.7C1331.7-72.194,1331.7-71.612,1331.7-71.029Z"
        transform="translate(-169.778)"
        fill="#2e9ccb"
      />
      <path
        id="Tracé_88"
        data-name="Tracé 88"
        d="M1401.09-48.729a1.671,1.671,0,0,1-.114.294q-.867,1.516-1.746,3.025a.6.6,0,0,1-.865.272.6.6,0,0,1-.178-.867q.874-1.529,1.761-3.051a.585.585,0,0,1,.683-.315A.634.634,0,0,1,1401.09-48.729Z"
        transform="translate(-231.926 -22.143)"
        fill="#2e9ccb"
      />
      <path
        id="Tracé_89"
        data-name="Tracé 89"
        d="M1243.514-45.742a.618.618,0,0,1-.422.617.558.558,0,0,1-.667-.215,5.351,5.351,0,0,1-.3-.49c-.512-.886-1.026-1.772-1.533-2.661a.577.577,0,0,1,.082-.77.551.551,0,0,1,.692-.1.776.776,0,0,1,.269.274q.889,1.52,1.762,3.05A1.789,1.789,0,0,1,1243.514-45.742Z"
        transform="translate(-87.026 -22.101)"
        fill="#2e9ccb"
      />
      <path
        id="Tracé_90"
        data-name="Tracé 90"
        d="M1303.526,76.511a3.018,3.018,0,1,1-3.014,3.015A3.026,3.026,0,0,1,1303.526,76.511Z"
        transform="translate(-142.216 -137.91)"
        fill="#2e9ccb"
      />
    </g>
  </svg>
);

export default ViewIcon;

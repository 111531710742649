import React from "react";
import T0 from "./red-0.png";
import T100 from "./red-100.png";
import T20 from "./red-20.png";
import T40 from "./red-40.png";
import T60 from "./red-60.png";
import T80 from "./red-80.png";

const CircleRed = ({
  value,
  percentage = 20,
  label,
  className,
}: {
  value: number;
  percentage: number;
  label: string;
  className?: string;
}) => {
  return (
    <div className={className}>
      {percentage < 20 && percentage > 0 && (
        <div className="wrapper-20">
          <img src={T20} alt="red-20" />
          <span className="text-20 red-text generic-buble red-buble">
            {label} : {value} €
          </span>
        </div>
      )}
      {percentage < 40 && percentage >= 20 && (
        <div className="wrapper-40">
          <img src={T40} alt="red-40" />
          <span className="text-40 red-text generic-buble red-buble">
            {label} : {value} €
          </span>
        </div>
      )}
      {percentage < 60 && percentage >= 40 && (
        <div className="wrapper-60">
          <img src={T60} alt="red-60" />
          <span className="text-60 red-text generic-buble red-buble">
            {label} : {value} €
          </span>
        </div>
      )}
      {percentage < 100 && percentage >= 60 && (
        <div className="wrapper-80">
          <img src={T80} alt="red-80" />
          <span className="text-80 red-text generic-buble red-buble">
            {label} : {value} €
          </span>
        </div>
      )}
      {percentage === 100 && (
        <div className="wrapper-100">
          <img src={T100} alt="red-100" />
          <span className="text-100 red-text generic-buble red-buble">
            {label} : {value} €
          </span>
        </div>
      )}
      {percentage === 0 && (
        <div className="wrapper-0">
          <img src={T0} alt="red-0" />
          <span className={`text-0 red-text generic-buble red-buble${percentage === 0 ? " _grey" : ""}`}>
            {label} : {value} €
          </span>
        </div>
      )}
    </div>
  );
};

export default CircleRed;

import React, { useState, FormEvent, useEffect } from "react";

import "./style.scss";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { RiCalendar2Line } from "react-icons/ri";
import AuthTabs from "../../components/AuthTabs";
import SignIn from "../Account/signin/signin";
import Register from "../Account/register/register";
import imgLogin from "../../assets/images/img-auth.png";
import ForgotPassword from "./forgotPassword/forgotPassword";
import GetPassword from "./getPassword/GetPassword";
import { useLocation } from "react-router-dom";
export interface TransactionsProps {}

export type TabType = {
  label: string;
  index: number;
  Component: React.FC<{}>;
  link: string;
};

type TabsType = TabType[];

// AuthTabs Array
export const tabs: TabsType = [
  {
    label: "Connexion",
    index: 1,
    Component: SignIn,
    link: "/login",
  },
  // {
  //   label: "Créez un compte",
  //   index: 2,
  //   Component: Register,
  //   link: "/register",
  // },
];

const Auth: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);

  const location = useLocation();

  useEffect(() => {
    const tab =
      tabs.findIndex((elt) => location.pathname.includes(elt.link)) > -1
        ? tabs.find((elt) => location.pathname.includes(elt.link))?.index!
        : 1;
    setSelectedTab(tab);
  }, [location.pathname]);

  return (
    <div className="main-auth">
      <div className="container-auth">
        <Row className="row-auth">
          <Col lg={4} md={12} className="leftLogin">
            <div className="innerLeftLogin">
              <div className="img-login">
                <img src={imgLogin} alt="img" />
              </div>
            </div>
          </Col>

          <Col lg={8} md={12} className="righttLogin">
            <div className="innerrightLogin">
              {/* <GetPassword /> */}
              {/* <ForgotPassword /> */}

              <AuthTabs
                orientation="vertical"
                selectedTab={selectedTab}
                onClick={setSelectedTab}
                tabs={tabs}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Auth;

import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Button, Card, Col, FormGroup, Label, Row, Spinner } from "reactstrap";
import config from "../../config";
import { ErrorLogger } from "../../util/errorLogger";
import ErrorIconOrange from "../../assets/images/svg/erros-icons.svg";
import ClanderIconGreen from "../../assets/images/svg/calender-green.svg";
import CardDevis from "../../components/CardDevis/CardDevis";

import "./style.scss";
import useAxios from "../../util/hooks/useAxios";

const { API_URL } = config[process.env.NODE_ENV];

const DevisHandler = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<number>();
  const [devis, setDevis] = useState<string>();

  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  let api = useAxios();
  const queriesArray = [...(searchParams as any)] as string[][];

  const handleDevis = async (id: string, decision: number) => {
    try {
      setLoading(true);
      const { data } = await api.post(`/api/invoice/handleInvoiceStatus`, {
        invoiceId: id,
        decision: decision,
      });
      if (!data.update && data.status) {
        setLoading(false);
        setMessage(data.status);
        return;
      }
      setMessage(decision);
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("handling invoice status", error);
      setMessage(999);
      setLoading(false);
      // navigate("/");
    }
  };

  useEffect(() => {
    setDevis(queriesArray[0][1]);
    handleDevis(queriesArray[0][1], parseInt(queriesArray[1][1]));
  }, [queriesArray[0][1]])

  return (
    <div className="devis-handler-wrapper">
      <>
        {loading ? (
          <>
            <Spinner color="secondary" type="border" size={"md"}>
              Loading...
            </Spinner>
          </>
        ) : (
          <div className="section-information-societe">
            {message === 1 && (
              <CardDevis
                title={`Merci pour votre réponse, Votre devis ${devis?.toUpperCase()} a été accepté`}
                icon={ClanderIconGreen}
                className="devis-accepted"
              >
                {/* <Button color="primary" outline onClick={() => navigate("/")}>
                  Accueil
                </Button> */}
              </CardDevis>
              )}
              
            {message === 2 && (
              <CardDevis
                title={`Merci pour votre réponse, Votre devis ${devis?.toUpperCase()} a été refusé`}
                icon={ErrorIconOrange}
                className="devis-refused"
              >
                {/* <Button color="warning" outline onClick={() => navigate("/")}>
                  Accueil
                </Button> */}
              </CardDevis>
              )}
              
            {message === 103 && (
              <CardDevis
                title={`Votre devis ${devis?.toUpperCase()} a déjà été accepté`}
                // title={`Merci pour votre réponse, Votre devis ${devis?.toUpperCase()} a été accepté`}
                icon={ClanderIconGreen}
                className="devis-accepted"
              >
                {/* <Button color="primary" outline onClick={() => navigate("/")}>
                  Accueil
                </Button> */}
              </CardDevis>
              )}
              
            {message === 104 && (
              <CardDevis
                title={`Merci pour votre réponse, Votre devis ${devis?.toUpperCase()} a déjà été refusé`}
                // title={`Merci pour votre réponse, Votre devis ${devis?.toUpperCase()} a été refusé`}
                icon={ErrorIconOrange}
                className="devis-refused"
              >
                {/* <Button color="warning" outline onClick={() => navigate("/")}>
                  Accueil
                </Button> */}
              </CardDevis>
              )}
              
              {message === 999 && (
              <CardDevis
                title={`Une erreur s'est produite lors du traitement de votre devis, veuillez réessayer plus tard.`}
                icon={ErrorIconOrange}
                className="devis-refused"
              >
                {/* <Button color="warning" outline onClick={() => navigate("/")}>
                  Accueil
                </Button> */}
              </CardDevis>
              )}
          </div>
        )}
      </>
    </div>
  );
};

export default React.memo(DevisHandler);

import React from "react";
import { colors } from "../constants";

interface EmailProps {
  className?: string;
}

const EmailIcon = ({ className }: EmailProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width="21.512"
    height="16.434"
    viewBox="0 0 21.512 16.434"
  >
    <path
      id="Tracé_51"
      data-name="Tracé 51"
      d="M470.519-94.061c.021-.095.044-.189.063-.284a3.344,3.344,0,0,1,3.368-2.716q7.32-.017,14.639,0a3.339,3.339,0,0,1,3.372,2.707,3.306,3.306,0,0,1,.066.646q.008,2.73,0,5.461a.834.834,0,0,1-.819.882.827.827,0,0,1-.857-.822c-.011-.42,0-.84,0-1.26,0-1.344,0-2.688,0-4.064-.089.05-.159.085-.227.127q-3.485,2.165-6.968,4.332a3.319,3.319,0,0,1-3.126.324,4.264,4.264,0,0,1-.669-.347q-3.469-2.151-6.934-4.309c-.064-.04-.13-.076-.219-.128,0,.075-.008.127-.008.18q0,4.621,0,9.242a1.666,1.666,0,0,0,1.29,1.716,2.164,2.164,0,0,0,.54.054q7.246,0,14.492,0a1.681,1.681,0,0,0,1.764-1.257,2.551,2.551,0,0,0,.064-.456.835.835,0,0,1,.879-.8.834.834,0,0,1,.8.859,3.361,3.361,0,0,1-3.146,3.321q-.294.014-.588.014-7.12,0-14.24,0a3.468,3.468,0,0,1-2.2-.687,3.311,3.311,0,0,1-1.3-2.18,1.12,1.12,0,0,0-.04-.139Zm2.4-1c.084.055.141.094.2.13q3.564,2.217,7.126,4.437a1.786,1.786,0,0,0,2.063,0q3.557-2.228,7.126-4.437c.062-.039.122-.082.2-.134a1.754,1.754,0,0,0-1.128-.316H474.029A1.736,1.736,0,0,0,472.917-95.065Z"
      transform="translate(-470.519 97.069)"
      fill={colors.secondaryColor}
    />
  </svg>
);

export default EmailIcon;

import React from "react";

interface SaveIconProps {
  className?: string;
}

const SaveIconWhite = ({ className }: SaveIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.146"
    height="14.155"
    viewBox="0 0 14.146 14.155"
    className={className}
  >
    <g
      id="Groupe_210"
      data-name="Groupe 210"
      transform="translate(235.627 894.128)"
    >
      <path
        id="Tracé_314"
        data-name="Tracé 314"
        d="M-233.1-894.123v.184q0,1.136,0,2.272a1.517,1.517,0,0,0,1.59,1.584q1.949,0,3.9,0a1.514,1.514,0,0,0,1.582-1.576c0-.757,0-1.515,0-2.272v-.176a1.817,1.817,0,0,1,1.774.615c.73.911,1.479,1.807,2.23,2.7a1.869,1.869,0,0,1,.486.8,2.152,2.152,0,0,1,.053.436q0,4,0,8a1.514,1.514,0,0,1-1.582,1.576h-.438v-.2q0-2.414,0-4.829a1.5,1.5,0,0,0-1.136-1.486,1.71,1.71,0,0,0-.421-.049q-3.5,0-6.99,0a1.51,1.51,0,0,0-1.551,1.558q0,2.4,0,4.8v.186a1.6,1.6,0,0,1-2.025-1.59c.013-3.64.005-7.28.005-10.919a1.519,1.519,0,0,1,1.614-1.607Z"
        transform="translate(0 0)"
        fill="#fff"
      />
      <path
        id="Tracé_315"
        data-name="Tracé 315"
        d="M-146.458-893.739c0,.858.006,1.7,0,2.55a.5.5,0,0,1-.549.469h-3.344c-.216,0-.431,0-.647,0a.491.491,0,0,1-.5-.469c-.008-.846,0-1.692,0-2.549Z"
        transform="translate(-80.581 -0.373)"
        fill="#fff"
      />
      <path
        id="Tracé_316"
        data-name="Tracé 316"
        d="M-155.241-597.262H-163.3v-1.717h8.061Z"
        transform="translate(-69.282 -282.728)"
        fill="#fff"
      />
      <path
        id="Tracé_317"
        data-name="Tracé 317"
        d="M-163.33-646.086v-.99h8.065v.99Z"
        transform="translate(-69.255 -236.655)"
        fill="#fff"
      />
      <path
        id="Tracé_318"
        data-name="Tracé 318"
        d="M-155.353-689.222h-8.071a2.37,2.37,0,0,1,0-.376.491.491,0,0,1,.509-.405q3.518,0,7.035,0a.5.5,0,0,1,.523.514C-155.352-689.405-155.353-689.322-155.353-689.222Z"
        transform="translate(-69.159 -195.534)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SaveIconWhite;

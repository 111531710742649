import React, { useEffect, useState } from "react";
import "./style.scss";

import { Badge, Button, Card, Col, Row } from "reactstrap";

import Tabs from "../../components/Tabs";
import ImmobilisationCurrent from "./ImmobilisationCurrent/ImmobilisationCurrent";
import ImmoblisationArchive from "./ImmoblisationArchive/ImmoblisationArchive";
import Export from "./Export";
import { Company, IApplication, IPermission, User } from "../../interfaces";
import BannerTop from "../../components/Banner/BannerTop";
import { SiPypy } from "react-icons/si";
import UserInfo from "../../components/user-info/UserInfo";
import { RiUser3Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import data from "../../constants/data";
import { UserTypes } from "../../util/context";
import CrownIcon from "../../assets/CrownIcon";
import { colors } from "../../constants";

type TabsType = {
  label: string;
  index: number;
  Component: React.FC<{}>;
}[];

// Tabs Array
const tabs: TabsType = [
  {
    label: "Année en cours",
    index: 1,
    Component: ImmobilisationCurrent,
  },
  {
    label: "Archive",
    index: 2,
    Component: ImmoblisationArchive,
  },
  {
    label: "Export",
    index: 3,
    Component: Export,
  },
];

const Immobilisation = ({
  context,
  company,
}: {
  context?: string;
  company?: Company;
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);

  const { newNotif } = useSelector(
    (state: { messagesNotifs: object }) => state.messagesNotifs
  ) as { newNotif: boolean };

  let navigate = useNavigate();

  const { user, company: userCompany } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user
  ) as { user: User; company: Company };

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  return (
    <>
      <div className="page">
        {user.id && user.role === UserTypes.Client ? (
          <>
            <div
              className={`top-content immoblisation-selector-wrapper ${
                creds.role === UserTypes.Client ? "client-resize-header" : ""
              }`}
            >
              <Row>
                <Col lg={8} md={12} className="blockBanner">
                  <BannerTop banner={data.immoblisation} />
                </Col>
                <Col lg={4} md={12}>
                  <Card className="blockUser">
                    <Button
                      className="btn-icon btn-icon-primary"
                      color="light"
                      onClick={() => navigate("/tickets")}
                    >
                      {newNotif && <Badge color="primary"> </Badge>}
                      <CrownIcon stroke={colors.primaryColor} />
                    </Button>
                    <UserInfo user={user} />
                    <Button
                      className="btn-icon btn-icon-primary"
                      color="light"
                      onClick={() =>
                        navigate({
                          pathname: "/profil/client",
                          // search: "?page=1&tab=1",
                        })
                      }
                    >
                      <RiUser3Line />
                    </Button>
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}
        <div>
          <Card className="card-Table table-primary">
            <Tabs
              selectedTab={selectedTab}
              onClick={setSelectedTab}
              tabs={
                user.id && user.role === UserTypes.Client
                  ? [
                      {
                        label: "Année en cours",
                        index: 1,
                        Component: ImmobilisationCurrent,
                      },
                      {
                        label: "Archive",
                        index: 2,
                        Component: ImmoblisationArchive,
                      },
                    ]
                  : tabs
              }
              context={company && company.id ? context : userCompany.id}
              company={company && company.id ? company : userCompany}
            />
          </Card>
        </div>
        <div className="openbtn text-center"></div>
      </div>
    </>
  );
};

export default Immobilisation;

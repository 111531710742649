import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router";
import { Company, User } from "../../../interfaces";
import { UserTypes } from "../../../util/context";
import { ErrorLogger } from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import ExercicePrecedent from "../../CompanyInfo/ExercicePrecedent/ExercicePrecedent";

function ExerciceCompanyWrapper() {
  const { context, company, callback } = useOutletContext() as any;
  return (
    <ExercicePrecedent
      context={context}
      company={company}
      callback={callback}
    />
  );
}

export default ExerciceCompanyWrapper;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../../config";
import useAxios from "../../../util/hooks/useAxios";
import { setCounter } from "../utils/utilSlice";
import { createAxiosInsatance } from "../../../util/helpers/createAxiosInsatance";

const { API_URL } = config[process.env.NODE_ENV];


export const fetchAllApplications = createAsyncThunk(
  "applications/fetchAllApplications",
  async (_, { getState, requestId, dispatch }) => {
    const thunkState = getState() as any;
    let api = createAxiosInsatance(thunkState.root.token, dispatch, setCounter);
    const { data } = await api!.post(
      `/api/Application/All`,
      {},
      {
        headers: {
          "x-access-token": thunkState.root.token,
        },
      }
    );
    return data.data;
  }
);

const initialState = {
  applications: [],
  loading: "idle",
  currentRequestId: undefined as any,
  error: null as any,
};

export const applicationsSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    resetApplications: (state: any) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllApplications.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchAllApplications.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.applications = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchAllApplications.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const {resetApplications} = applicationsSlice.actions;
export default applicationsSlice.reducer;

export const getStatusDescription = (status: string) => {
  switch (status) {
    case "0":
      return <span >Tout va bien</span>;
    case "-2":
      return (
        <span >
          L'item est en attente de synchronisation
        </span>
      );
    case "-3":
      return (
        <span >
          L'item est en attente de synchronisation
        </span>
      );
    case "402":
      return (
        <span >
          Les identifiants ne permettent pas de synchroniser la banque
        </span>
      );
    case "429":
      return (
        <span >
          Une action est nécessaire sur l'interface de la banque
        </span>
      );
    case "1003":
      return (
        <div>
          <span >
            La synchronisation a rencontré une erreur.
            <span style={{color: "red", fontSize: "15px", marginLeft: "5px"}}>
              Si une tentative resynhronisation de l'item échoue, nous vous
              invitons à contacter notre support.
            </span>
          </span>
        </div>
      );
    case "1005":
      return (
        <div>
          <span >
            Le connecteur sélectionné ne permet pas de synchroniser les compte.
            <span style={{color: "red", fontSize: "15px", marginLeft: "5px"}}>
              L'utilisateur doit vérifier ses droits et sélectionner le
              connecteur adapté à sa banque
            </span>
          </span>
        </div>
      );
    case "1009":
      return (
        <div>
          <span >
            Le solde du compte a changé, mais aucune nouvelle transaction n'a
            été trouvée.
            <span
              style={{color: "red", fontSize: "15px", marginLeft: "5px"}}
            >
              Cela peut arriver avec des comptes d'assurance-actions/vie.
            </span>
          </span>
        </div>
      );
    case "1010":
      return (
        <span >
          Vous devez réaliser votre authentification forte en vous assurant que
          celle-ci est correctement configurée sur l'interface de votre banque.
        </span>
      );
    case "1100":
      return (
        <span >
          Un validation des comptes professionnels est nécessaire afin d'obtenir
          les transactions des comptes
        </span>
      );
    default:
      return <span>Il y a un problème</span>;
  }
};

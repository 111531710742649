import React, { PropsWithChildren, ReactElement } from "react";
import "./CardDevis.scss";
import { ICardDevis } from "../../interfaces";
import { Card } from "reactstrap";

function CardDevis(props: PropsWithChildren<ICardDevis>): ReactElement {
  return (
    <Card className={` card-devis ${props.className} `}>
      <h3>{props.title}</h3>
      <div className="card-devis-img">
        <img src={props.icon} alt="icon" />
      </div>
      <div className="cardBottom"> {props.children}</div>
    </Card>
  );
}

export default CardDevis;

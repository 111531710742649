import {AxiosInstance} from "axios";

export const getClient = async ({
  api,
  companyId,
}: {
  api: AxiosInstance;
  companyId: string;
}) => {
  try {
    const resp = await api.post("/api/v2/client/one", {
      where: {id: companyId},
    });

    return resp;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

import React from "react";
import { colors } from "../constants";

interface ZoomProps {
  className?: string;
  stroke?: string;
}

const ZoomIcon = ({ className, stroke = colors.secondaryColor }: ZoomProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="35.482"
    viewBox="0 0 32 35.482"
    className={className}
  >
    <defs>
      <filter
        id="Tracé_104"
        x="0"
        y="0"
        width="32"
        height="35.482"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="1.5" result="blur" />
        <feFlood floodColor={colors.secondaryColor} strokeWidth="0.522" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Groupe_86" data-name="Groupe 86" transform="translate(4.5)">
      <g transform="matrix(1, 0, 0, 1, -4.5, 0)" filter="url(#Tracé_104)">
        <path
          id="Tracé_104-2"
          data-name="Tracé 104"
          d="M3001.889,1027.044a9.76,9.76,0,0,1-8.091,2.158,9.547,9.547,0,0,1-6.112-3.725,9.856,9.856,0,1,1,15.311.471,1.07,1.07,0,0,1,.262.146q2.555,2.542,5.1,5.09a.836.836,0,0,1,.266.9.817.817,0,0,1-1.311.378c-.072-.06-.136-.13-.2-.2l-5.021-5.028C3002.03,1027.174,3001.961,1027.113,3001.889,1027.044Zm-6.324-15.743a8.211,8.211,0,1,0,8.177,8.285A8.209,8.209,0,0,0,2995.565,1011.3Z"
          transform="translate(-2981.17 -1009.68)"
          fill={stroke}
        />
      </g>
    </g>
  </svg>
);

export default ZoomIcon;

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IPermission, User } from "../../interfaces";
import { usePermissions } from "./usePermissions";

const useAuthorization = () => {
  const navigate = useNavigate();

  const { hasAccessPermission } = usePermissions();

  const authenticatedRoute = (
    permissions: IPermission[],
    whiteList: string
  ) => {
    const hasAccess = hasAccessPermission(permissions, whiteList);
    if (!hasAccess) {
      return navigate("/");
    }
    return;
  };

  return {
    authenticatedRoute,
  };
};

export { useAuthorization };

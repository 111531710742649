import React, { useState } from "react";
import { BsTrashFill } from "react-icons/bs";
import { RiDownload2Fill } from "react-icons/ri";
import { FormGroup, Input, Spinner } from "reactstrap";
import AttacheIcon from "../../assets/AttacheIcon";

const FilePicker = ({
  className,
  onChange,
  state,
  fileReadyDelete = null,
  fileStagedDelete = null,
  setUrl = null,
  setViewModal = null,
  isMultiple = false,
  renderType = "single",
  loader = false,
  disabled = false,
  disableDelete,
}: {
  className: string;
  onChange: any;
  state: any;
  fileReadyDelete?: any;
  fileStagedDelete?: any;
  setUrl?: any;
  setViewModal?: any;
  isMultiple?: boolean;
  renderType?: string;
  loader?: boolean;
  disabled?: boolean;
  disableDelete?: boolean;
}) => {
  return (
    <>
      <FormGroup className={className}>
        <span className="label-file">
          {loader ? <></> : "Déposez les fichiers ici"}
        </span>
        <label className="file-box ">
          {loader ? (
            <React.Fragment>
              <Spinner color="info" type="border" size={"sm"}>
                Loading...
              </Spinner>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <RiDownload2Fill />
              <Input
                type="file"
                multiple={isMultiple}
                onClick={(event) => {
                  (event.target as HTMLInputElement).value =
                    null as unknown as string;
                }}
                placeholder="Nom white label"
                className="form-default"
                onChange={onChange}
                disabled={disabled}
              />
              <span> Télécharger d'ici</span>
            </React.Fragment>
          )}
        </label>
      </FormGroup>
      <div className="list-Files">
        {renderType === "array" ? (
          <>
            {state &&
              state.length > 0 &&
              state.map((elt: any, key: React.Key | null | undefined) => (
                <React.Fragment key={key}>
                  {!elt.key ? (
                    <React.Fragment key={key}>
                      <span className={`file-box-item-with-clickers`}>
                        <span
                          className="file-viewer-click"
                          onClick={() => {
                            setUrl(URL.createObjectURL(elt as any));
                            setViewModal(true);
                          }}
                        >
                          <AttacheIcon />
                          <span>{(elt as any).name}</span>
                        </span>
                        <span
                          className="file-delete-click"
                          onClick={() => {
                            if (disableDelete) {
                              return;
                            }
                            fileStagedDelete(elt);
                          }}
                        >
                          <BsTrashFill />
                        </span>
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={key}>
                      <span className={`file-box-item-with-clickers`}>
                        <span
                          className="file-viewer-click"
                          onClick={() => {
                            setUrl(elt.url);
                            setViewModal(true);
                          }}
                        >
                          <AttacheIcon />
                          <span>{elt.key}</span>
                        </span>
                        <span
                          className="file-delete-click"
                          onClick={() => {
                            if (disableDelete) {
                              return;
                            }
                            fileReadyDelete(elt);
                          }}
                        >
                          <BsTrashFill />
                        </span>
                      </span>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
          </>
        ) : (
          <>
            {state && (
              <>
                {!state.key ? (
                  <span className="file-box-item-with-clickers">
                    <span
                      className="file-viewer-click"
                      onClick={() => {
                        setUrl(URL.createObjectURL(state as any));
                        setViewModal(true);
                      }}
                    >
                      <AttacheIcon />
                      <span>{state.name}</span>
                    </span>
                    <span
                      className="file-delete-click"
                      onClick={!disableDelete && fileStagedDelete}
                    >
                      <BsTrashFill />
                    </span>
                  </span>
                ) : (
                  <span className="file-box-item-with-clickers">
                    <span
                      className="file-viewer-click"
                      onClick={() => {
                        setUrl(state.url);
                        setViewModal(true);
                      }}
                    >
                      <AttacheIcon />
                      <span>{state.key}</span>
                    </span>
                    <span
                      className="file-delete-click"
                      onClick={!disableDelete && fileReadyDelete}
                    >
                      <BsTrashFill />
                    </span>
                  </span>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FilePicker;

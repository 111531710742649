import { Button, Card, Col, FormGroup, Label, Row } from "reactstrap";
import "./style.scss";
import ClanderIconGreen from "../../assets/images/svg/calender-green.svg";
import CardDevis from "../../components/CardDevis/CardDevis";
const SyncSuccess = () => {
  return (
    <div className="single-sync-page">
      <div className="col-md-11 mx-auto col-sm-12">
        <CardDevis
          title="Votre synchronisation s'est déroulée avec succès"
          icon={ClanderIconGreen}
          className="devis-accepted"
        ></CardDevis>
      </div>
    </div>
  );
};

export default SyncSuccess;

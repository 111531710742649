import React from "react";
import "./BannerTop.scss";
import { Banners } from "../../interfaces";
import { Button } from "reactstrap";
import arrowIconLogo from "../../assets/images/icons/arrow-iconn.png";
import { useSelector } from "react-redux";
import { UserTypes } from "../../util/context";
type BannerProps = {
  banner: Banners;
  children?: string;
  className?: string;
  callback?: void;
  context?: string | number;
};
const BannerTop: React.FC<BannerProps> = ({
  banner,
  children,
  className,
  callback = null,
  context = "",
}) => {
  const banerStyle = {
    background: banner.background,
    backgroundImage: banner.backgroundImage,
  };

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  return (
    <div
      className={`Banner ${className}`}
      style={banerStyle}
    >
      <div className="Banner-text">
        <h2>{banner.title}</h2>
        <p className="Text">{banner.description}</p>
        {context !== "" && <h2>({context})</h2>}
        {banner.button && banner.button.status && (
          <Button className="retour-btn btn btn-secondary" onClick={callback!}>
            Voir <img src={arrowIconLogo} alt="icon" />
          </Button>
        )}
      </div>
      <div className="Banner-img">
        <img src={banner.image} alt={banner.title} />
      </div>

      {children}
    </div>
  );
};

export default BannerTop;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import RoutingTabs from "../../../components/RoutingTabs";
import Ged from "./GedClientWrapper";
import Info from "./ClientInfoWrapper";
import { Card } from "reactstrap";
import { OutletComp } from "..";
import { ErrorLogger } from "../../../util/errorLogger";
import { UserTypes } from "../../../util/context";
import useAxios from "../../../util/hooks/useAxios";
import { useSelector } from "react-redux";
import { Company, User } from "../../../interfaces";

const tabs = [
  {
    label: "Information client",
    index: 1,
    Component: Info,
    link: "/profil/client",
  },
  {
    label: "Gestion des documents",
    index: 2,
    Component: Ged,
    link: "/profil/client/ged",
  },
];

const ClientWrapper = () => {
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user, company: userCompany } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user
  ) as { user: User; company: Company };

  let api = useAxios();

  const [reload, setReload] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [currentCompany, setCurrentCompany] = useState<Company | null>();

  const getUser = async () => {
    try {
      const { data } = await api.post(
        `/api/User/All`,
        {
          where: {
            id: creds.user_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      const { data: userData } = data;
      const [userFound] = userData;
      const { company, ...user } = userFound;
      setCurrentCompany(company);
      setCurrentUser(user);
    } catch (error: any) {
      ErrorLogger("state rehydration in App", error);
    }
  };

  useEffect(() => {
    setCurrentUser(user);
    if (user.role === UserTypes.Client) {
      setCurrentCompany(userCompany);
    }
  }, [creds.token]);

  useEffect(() => {
    if (reload) {
      getUser();
      setReload(false);
    }
  }, [reload]);
  return (
    <div className="page">
      <div>
        <Card className="card-Table table-primary">
          <RoutingTabs tabs={tabs} type="child" />
          <OutletComp
            context={{
              context: creds.company_id,
              company: currentCompany!,
              client: currentUser!,
              user: currentUser!,
              callback: getUser,
            }}
          />
        </Card>
      </div>
    </div>
  );
};

export default ClientWrapper;

import { IPermission } from "../../interfaces";

const usePermissions = () => {
  const hasCrudPermission = (
    permissions: IPermission[],
    whiteList: string
  ) => {
    if (permissions.length) {
      const _permission = permissions.findIndex(
        (elt) => elt.module === whiteList
      );
      if (_permission < 0) {
        return false;
      } else return true;
    } else {
      return false;
    }
  };

  const hasAccessPermission = (
    permissions: IPermission[],
    whiteList: string
  ) => {
    if (permissions.length) {
      const _permission = permissions.findIndex((elt) => {
        return elt.module === whiteList;
      });

      if (_permission < 0) {
        return false;
      } else return true;
    } else return false;
  };

  return {
    hasCrudPermission,
    hasAccessPermission,
  };
};

export { usePermissions };

import React from "react";
import T0 from "./yellow-0.png";
import T100 from "./yellow-100.png";
import T20 from "./yellow-20.png";
import T40 from "./yellow-40.png";
import T60 from "./yellow-60.png";
import T80 from "./yellow-80.png";

const CircleYellow = ({
  value,
  percentage,
  label,
  className,
}: {
  value: number;
  percentage: number;
  label: string;
  className?: string;
}) => {

  return (
    <div className={className}>
      {percentage < 20 && percentage > 0 && (
        <div className="wrapper-20">
          <img src={T20} alt="yellow-20" />
          <p className="text-20 yellow-text generic-buble yellow-buble">
            {label} : <br /> {value} €
          </p>
        </div>
      )}
      {percentage < 40 && percentage >= 20 && (
        <div className="wrapper-40">
          <img src={T40} alt="yellow-40" />
          <p className="text-40 yellow-text generic-buble yellow-buble">
             {label} :
            <br />
            {value} €
          </p>
        </div>
      )}
      {percentage < 60 && percentage >= 40 && (
        <div className="wrapper-60">
          <img src={T60} alt="yellow-60" />
          <p className="text-60 yellow-text generic-buble yellow-buble">
             {label} :
            <br />
            {value} €
          </p>
        </div>
      )}
      {percentage < 100 && percentage >= 60 && (
        <div className="wrapper-80">
          <img src={T80} alt="yellow-80" />
          <p className="text-80 yellow-text generic-buble yellow-buble">
             {label} :
            <br />
            {value} €
          </p>
        </div>
      )}
      {percentage === 100 && (
        <div className="wrapper-100">
          <img src={T100} alt="yellow-100" />
          <p className="text-100 yellow-text generic-buble yellow-buble">
             {label} :
            <br />
            {value} €
          </p>
        </div>
      )}
      {percentage === 0 && (
        <div className="wrapper-0">
          <img src={T0} alt="yellow-0" />
          <p
            className={`text-0 yellow-text generic-buble ${percentage === 0 ? " _grey" : ""}`}
          >
            {label} :
            <br />
            {value} €
          </p>
        </div>
      )}
    </div>
  );
};

export default CircleYellow;

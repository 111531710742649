import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Company, User } from "../../../interfaces";
import { UserTypes } from "../../../util/context";
import { ErrorLogger } from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import AdminComp from "./AdminComp";

const AdminWrapper = () => {
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const [reload, setReload] = useState<boolean>(false);

  const [currentUser, setCurrentUser] = useState<User | null>();
  const [currentCompany, setCurrentCompany] = useState<Company | null>();

  const { user, company: userCompany } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user
  ) as { user: User; company: Company };

  let api = useAxios();

  const getUser = async () => {
    try {
      const { data } = await api.post(
        `/api/User/All`,
        {
          where: {
            id: creds.user_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      const { data: userData } = data;
      const [userFound] = userData;
      const { company, ...user } = userFound;
      setCurrentCompany(company);
      setCurrentUser(user);
    } catch (error: any) {
      ErrorLogger("state rehydration in App", error);
    }
  };

  useEffect(() => {
    setCurrentUser(user);
    if (creds.role === UserTypes.Client) {
      setCurrentCompany(userCompany);
    }
  }, []);

  useEffect(() => {
    if (reload) {
      getUser();
      setReload(false);
    }
  }, [reload]);

  return <AdminComp user={user!} setReload={setReload} />;
};

export default AdminWrapper;

import React from "react";
import { colors } from "../constants";

interface ShadowProps {
  className?: string;
}

const PlusShadow = ({ className }: ShadowProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42.457"
    height="47.957"
    viewBox="0 0 42.457 47.957"
    className={className}
  >
    <defs>
      <filter
        id="Tracé_76"
        x="0"
        y="0"
        width="42.457"
        height="47.957"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="13" />
        <feGaussianBlur stdDeviation="2.5" result="blur" />
        <feFlood flood-color={colors.secondaryColor} flood-opacity="0.678" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Tracé_76)">
      <path
        id="Tracé_76-2"
        data-name="Tracé 76"
        d="M152.72,261.122c-.056-.025-.112-.051-.168-.074a1,1,0,0,1-.391-1.562c.06-.073.129-.139.2-.206q3.847-3.847,7.694-7.693c.053-.053.117-.094.242-.194a1.679,1.679,0,0,1-.25-.163q-3.877-3.871-7.75-7.745a1.02,1.02,0,0,1-.343-.97,1,1,0,0,1,1.641-.555c.057.05.109.105.163.159q3.854,3.854,7.706,7.708a1.463,1.463,0,0,1,.134.183l.091.021a.94.94,0,0,1,.118-.194q3.871-3.877,7.745-7.75a1.037,1.037,0,0,1,1.04-.346.993.993,0,0,1,.561,1.553,1.983,1.983,0,0,1-.2.225q-3.866,3.868-7.735,7.734a1.454,1.454,0,0,1-.166.125l-.03.089a.891.891,0,0,1,.2.115q3.883,3.877,7.764,7.758a1.073,1.073,0,0,1,.365.8.994.994,0,0,1-.628.911c-.057.023-.113.048-.169.072h-.379a1.686,1.686,0,0,1-.712-.477q-3.821-3.829-7.647-7.653a1.4,1.4,0,0,1-.145-.234c-.1.126-.145.189-.2.242q-3.817,3.819-7.634,7.639a1.637,1.637,0,0,1-.73.484Z"
        transform="translate(84.71 -278.34) rotate(45)"
        fill={colors.secondaryColor}
      />
    </g>
  </svg>
);

export default PlusShadow;

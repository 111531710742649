import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { OptionType } from "../../util/context";
import { useOutsideClick } from "../../util/hooks/useOutsideClick";

import "./SelectableBadge.scss";
type SelectableBadgeProps = {
  children?: JSX.Element | JSX.Element[];
  className?: string;
  title?: string;
  type?: string;
  buttonTitle?: string;
  onChange?: any;
  options: OptionType[];
  defaultValue: OptionType;
  index: string;
  showDropdown: boolean;
  setShowDropdown: any;
  loading: { index: string; value: boolean } | null;
};
const SelectableBadge: React.FC<SelectableBadgeProps> = ({
  className,
  type,
  defaultValue,
  onChange = null,
  options = [],
  index,
  showDropdown,
  setShowDropdown,
  loading,
}) => {
  const [selectedOption, setSelectedOption] = useState<OptionType | null>();

  const toggleDropdown = () => {
    if (loading && loading.index !== index) {
      return;
    }
    setShowDropdown((prevState: { [x: string]: any }) => {
      const oldState = { ...prevState };

      for (let elt of Object.keys(oldState)) {
        oldState[elt] = false;
      }

      return {
        ...oldState,
        [index]: !prevState[index],
      };
    });
  };

  const closeToggle = (val: string) => {
    setShowDropdown((prevState: { [x: string]: any }) => {
      return {
        ...prevState,
        [val]: false,
      };
    });
  };

  const ref = useOutsideClick(closeToggle, index, showDropdown) as any;

  useEffect(() => {
    if (defaultValue && defaultValue.value) {
      const val = options.find((elt) => elt.value === defaultValue.value)!;
      setSelectedOption(val);
    }
  }, [defaultValue]);

  return (
    <div
      className={`btn-status btn btn-small btn-${type} ${className}`}
      ref={ref}
    >
      <div className="selectable-badge-select" onClick={toggleDropdown}>
        <span className="selectable-badge-value">
          {loading && loading.index === index ? (
            <Spinner color="light" type="border" size={"sm"}>
              Loading...
            </Spinner>
          ) : (
            <>{selectedOption?.label}</>
          )}
        </span>
      </div>
      {showDropdown && (
        <ul
          className={`selectable-badge-dropdown ${showDropdown ? "show" : ""}`}
        >
          {options
            .filter((elt) => elt.value !== selectedOption?.value)!
            .map((option, key) => (
              <li
                key={key}
                className={`key-${option.value}`}
                onClick={() => {
                  setSelectedOption(option);
                  onChange(option);
                  closeToggle(index);
                }}
              >
                {option.label}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default SelectableBadge;

import React from "react";
import { Tooltip } from "reactstrap";

export default function CustomTooltip({
  children,
  message,
  id,
  placement,
}: {
  placement?: string;
  children: React.ReactElement;
  message: string;
  id: string;
}) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  return (
    <>
      {children}
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target={id}
        toggle={() => {
          setTooltipOpen(!tooltipOpen);
        }}
        style={{ background: "#4BB8C5", color: "fff" }}
      >
        {message}
      </Tooltip>
    </>
  );
}

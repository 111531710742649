import React, {useEffect, useState} from "react";
import "./style.scss";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Badge, Button, Card, Col, Row} from "reactstrap";
import {SiPypy} from "react-icons/si";
import {IoIosCash} from "react-icons/io";
import UserInfo from "../../components/user-info/UserInfo";
import {RiUser3Line} from "react-icons/ri";
import BannerTop from "../../components/Banner/BannerTop";
import Tabs from "../../components/Tabs";

import TopCard from "../../components/TopCard/TopCard";
import Notes from "./Notes/Notes";
import InformationSociete from "./InformationSociete/InformationSociete";
import {data} from "../../constants";
import GestionDocuments from "./GestionDocuments/GestionDocuments";
import IbanTelepaiement from "./IbanTelepaiement/IbanTelepaiement";
import ExercicePrecedent from "./ExercicePrecedent/ExercicePrecedent";
import CompteSynchronise from "./CompteSynchronise/CompteSynchronise";
import {Company, User} from "../../interfaces";
import {AppModules, AppPacks, UserTypes} from "../../util/context";
import {useSelector} from "react-redux";
import {LocationState} from "../ClientBackOffice";
import GestionCompteIban from "./GestionCompteIban/GestionCompteIban";

export interface InfosSocietesProps {}

export type TabsType = {
  label: string;
  index: number;
  Component: React.FC<{}>;
  blackList: string[];
}[];

// Tabs Array
const tabs: TabsType = [
  {
    label: "Informations société",
    index: 1,
    Component: InformationSociete,
    blackList: [],
  },
  {
    label: "Gestion des Documents",
    index: 2,
    Component: GestionDocuments,
    blackList: [],
  },
  // {
  //   label: "Iban Télépaiement",
  //   index: 3,
  //   Component: IbanTelepaiement,
  //   blackList: [AppPacks.Entrepreneur],
  // },
  {
    label: "Exercice Précédent",
    index: 3,
    Component: ExercicePrecedent,
    blackList: [AppPacks.Entrepreneur, AppPacks.BilanEntrepreneur],
  },
  {
    label: "Compte Synchronisé",
    index: 4,
    Component: CompteSynchronise,
    blackList: [],
  },
  {
    label: "Gestion des Comptes",
    index: 5,
    Component: GestionCompteIban,
    blackList: [],
  },
  {
    label: "Notes",
    index: 6,
    Component: Notes,
    blackList: [],
  },
];

const InformationsSocietes = ({
  context,
  company,
  client,
  callback,
  tempState,
  setTempState,
}: {
  context?: string;
  company?: Company;
  client?: User;
  callback?: () => void;
  tempState?: any;
  setTempState?: any;
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);

  const creds = useSelector(
    (state: {root: object; user: object}) => state.root
  ) as {
    user_id: string;
    company_id: string;
    token: string;
    role: string;
    pack: string;
  };

  //finish navigation for admin using tab states
  useEffect(() => {
    const state = tempState as LocationState;
    if (
      creds.role !== UserTypes.Client &&
      state &&
      state.child !== selectedTab
    ) {
      setSelectedTab(state.child);
      setTempState(null);
    }
  }, []);

  return (
    <div className="page-infos-societes">
      <div>
        <Card className="card-Table table-primary">
          <Tabs
            selectedTab={selectedTab}
            onClick={setSelectedTab}
            tabs={tabs.filter(
              (elt) =>
                !elt.blackList.includes(
                  creds.role === UserTypes.Client ? creds.pack : company?.pack!
                )
            )}
            context={context}
            company={company}
            client={client}
            callback={callback}
          />
        </Card>
      </div>
    </div>
  );
};

export default InformationsSocietes;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router";
import { Company, IS3, User } from "../../../interfaces";
import { UserTypes } from "../../../util/context";
import { ErrorLogger } from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import GestionDocuments from "../../CompanyInfo/GestionDocuments/GestionDocuments";

type CustomCompany = Omit<
  Partial<Company>,
  "listOfBeneficialOwners" | "kbis" | "status" | "listOfBeneficialOwners" | "m0"
> & {
  listOfBeneficialOwners: IS3[] | null;
  kbis: IS3 | null;
  status: IS3 | null;
  m0: IS3 | null;
};

function GedCompanyWrapper() {
  const { context, company, callback ,client} = useOutletContext() as any;
  return (
    <GestionDocuments
      context={context}
      client={client}
      company={company}
      callback={callback}
    />
  );
}

export default GedCompanyWrapper;

import React, { useEffect, useState } from "react";
import "./InvoiceTransactionsEnc.scss";
import { Company } from "../../../interfaces";

import { InvoiceTransactionParentTypes } from "../../../util/context";
import InvoiceTransactions from "../InvoiceTransactions/InvoiceTransactions";

export interface FactProps {}

const InvoiceTransactionsEnc = ({
  company,
  callback,
}: {
  company?: Company;
  callback?: any;
}) => {
  return (
    <InvoiceTransactions
      company={company}
      callback={callback}
      parentType={[
        InvoiceTransactionParentTypes.Enc,
        InvoiceTransactionParentTypes.ManualEnc,
      ]}
    />
  );
};

export default InvoiceTransactionsEnc;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router";
import { Company, User } from "../../../interfaces";
import { UserTypes } from "../../../util/context";
import { ErrorLogger } from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import InformationSociete from "../../CompanyInfo/InformationSociete/InformationSociete";

function CompanyInfoWrapper() {
  const { company, callback } = useOutletContext() as any;
  return <InformationSociete company={company} callback={callback} />;
}

export default CompanyInfoWrapper;

import React, { useEffect, useState } from "react";
import "./style.scss";
import ClientWrapper from "./ClientWrapper";
import CompanyWrapper from "./CompanyWrapper";
import ClientInfoIcon from "../../assets/images/icons/client/client-info-icon.png";
import CompanyInfoIcon from "../../assets/images/icons/client/company-info-icon.png";
import RoutingTabs from "../../components/RoutingTabs";
import { useSelector } from "react-redux";
import useAxios from "../../util/hooks/useAxios";
import { Company, User } from "../../interfaces";
import { Outlet } from "react-router";
import { ErrorLogger } from "../../util/errorLogger";
import { UserTypes } from "../../util/context";

const parentTabs = [
  {
    label: "Informations client",
    image: ClientInfoIcon,
    index: 1,
    Component: ClientWrapper,
    link: "/profil/client",
  },
  {
    label: "Informations société",
    image: CompanyInfoIcon,
    index: 2,
    Component: CompanyWrapper,
    link: "/profil/entreprise",
  },
];

export const OutletComp = ({ context }: { context: any }) => {
  return <Outlet context={context}></Outlet>;
};

const UserProfileInfo = () => {
  return (
    <>
      <div className="main-User-client client-profile-tabs">
        <div className="container-full">
          <div className="client-wrrap">
            <RoutingTabs tabs={parentTabs} type="parent" />
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfileInfo;

import React, { useEffect, useState } from "react";
import "./GestionAdmin.scss";
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import UserInfo from "../../components/user-info/UserInfo";
import { data } from "../../constants";
import BannerTop from "../../components/Banner/BannerTop";

import Tabs from "../../components/Tabs";
import NolyCompta, { GeneralFormValues } from "./NolyCompta/NolyCompta";
import UserOrangeIcon from "../../assets/UserOrangeIcon";
import Select, { GroupBase, MultiValue } from "react-select";
import UserIcon from "../../assets/images/svg/user-orange.svg";
import EmailIcon from "../../assets/images/svg/mail-orange.svg";
import KeyIcon from "../../assets/images/svg/key-orange.svg";
import PhoneIcon from "../../assets/images/svg/phone-orange.svg";
import LabelIcon from "../../assets/images/svg/banq-icon.svg";
import MeubleIconBlue from "../../assets/images/svg/meuble-blue-icon.svg";
import IconPepColors from "../../assets/images/svg/colors-icons.svg";
import ApercuIcons from "../../assets/images/svg/apercu-icons.svg";
import ApercuImage from "../../assets/images/web-apercu.png";
import { useDispatch, useSelector } from "react-redux";
import { Company, IApplication, IPermission, User } from "../../interfaces";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthorization } from "../../util/hooks/useAuthorization";
import config from "../../config";
import axios from "axios";
import {
  setCounter,
  setErrorMessage,
} from "../../store/reducers/utils/utilSlice";
import { ErrorLogger } from "../../util/errorLogger";
import { setClientsList } from "../../store/reducers/clients-list/clientsSlice";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Switch from "../../components/Switch/Switch";
import CustomSwitch from "../../components/CustomSwitch/Switch";
import WhiteLabel from "./WhiteLabel/WhiteLabel";
import { fetchAllApplications } from "../../store/reducers/applications/applicationsSlice";
import { RiDownload2Fill } from "react-icons/ri";
import AttacheIcon from "../../assets/AttacheIcon";
import useAxios from "../../util/hooks/useAxios";
import ApercuAdmin from "../ApercuAdmin/ApercuAdmin";
import FilePicker from "../../components/FilePicker";
import FileViewer from "../../components/FileViewer";
import { toast, ToastContainer } from "react-toastify";

import WhiteLabelAdmins from "./WhiteLabelAdmins/WhiteLabelAdmins";
import { UserTypes } from "../../util/context";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const optionLabel = [
  { value: "nouveauwhitelabel", label: "Nouveau white label" },
  { value: "digiwork", label: "Digiwork" },
  { value: "digiwork1", label: "Digiwork1" },
];

type TabsType = {
  label: string;
  index: number;
  Component: React.FC<{}>;
  image?: string;
}[];

// Tabs Array
const tabs: TabsType = [
  {
    label: "Noly Compta",
    image: "",
    index: 1,
    Component: NolyCompta,
  },
  {
    label: "White Label",
    image: " ",
    index: 2,
    Component: WhiteLabel,
  },
];

export type CustomGeneralFormValues = GeneralFormValues & {
  applicationName?: string | null;
  applicationChoice: string | null;
  applicationBaseUrl?: string | null;
  applicactionCreationChoice?: string | null;
  primColor?: string | null;
  secColor?: string | null;
  thirdColor?: string | null;
  cabinetCompanies?: { value: string; label: string }[] | null;
  logo?: any;
};

const { APPLICATION_NAME, APPLICATION_ID } = config[process.env.NODE_ENV];

export interface AdminProps {}
const GestionAdmin: React.FC<AdminProps> = ({}) => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);
  const [open, setOpen] = useState(false);
  const [switchValue, setSwitchValue] = useState("Admin");

  const [color, setColor] = useState("#000000");
  const [color2, setColor2] = useState("#000000");
  const [color3, setColor3] = useState("#000000");

  const ontoggle = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onCabinetAdminSwitch = (e: any) => {
    console.log("e : ", e);
  };

  const [stateErrorMessage, setStateErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);

  const filterCompanies = () => {
    const options = companiesList.reduce(
      (acc: { value: string; label: string }[], curr: Company) => [
        ...acc,
        { value: curr.id, label: curr.name || curr.email },
      ],
      []
    );

    return options;
  };

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };

  const { user, application, permissions } = useSelector(
    (state: { user: object; application: object; permissions: object }) =>
      state.user
  ) as { user: User; application: IApplication; permissions: IPermission[] };

  const { companiesList } = useSelector(
    (state: { companiesList: Company[] }) => state.companiesList
  ) as unknown as { companiesList: Company[] };

  const { applications } = useSelector(
    (state: { applications: Object }) => state.applications
  ) as { applications: IApplication[] };

  const { permissionsList } = useSelector(
    (state: { permissionsList: IPermission[] }) => state.permissionsList
  ) as unknown as { permissionsList: IPermission[] };
  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [crudLoading, setCrudLoading] = useState<boolean>(false);
  const [appLogo, setAppLogo] = useState<any>();
  const { authenticatedRoute } = useAuthorization();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const resteForm = () =>
    reset({
      lastName: null,
      firstName: null,
      adminApp: null,
      adminPerms: null,
      email: null,
      phone: null,
      password: null,
      password_repeat: null,
      adminCompanies: null,
      applicationName: null,
      applicationChoice: null,
      applicactionCreationChoice: null,
      primColor: null,
      secColor: null,
      thirdColor: null,
      applicationBaseUrl: null,
      cabinetCompanies: null,
      logo: null,
    });

  useEffect(() => {
    resteForm();
  }, []);

  useEffect(() => {
    resteForm();
    setSwitchValue("Admin");
  }, [open]);

  const {
    watch,
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CustomGeneralFormValues>({});

  const {
    adminApp: _adminApp,
    applicationChoice: _applicationChoice,
    applicactionCreationChoice: _applicactionCreationChoice,
    logo: _logo,
  } = watch();
  let api = useAxios();

  const createAdmin: SubmitHandler<CustomGeneralFormValues> = async (
    form: CustomGeneralFormValues
  ) => {
    try {
      setStateErrorMessage(null);
      setLoading(true);
      if (
        form.password?.toLowerCase() !== form.password_repeat?.toLowerCase()
      ) {
        setStateErrorMessage({
          type: "password_repeat",
          message: "Les mots de passe ne correspondent pas",
        });
        setLoading(false);
        return;
      }
      if (!form.email) {
        setStateErrorMessage({
          type: "no_email",
          message: "Veuillez fournir une adresse électronique valide",
        });
        setLoading(false);
        return;
      }

      const { data } = await api.post(
        `/api/User/All`,
        {
          where: {
            email: form.email,
            application_id: APPLICATION_ID,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (data.exists && !data.deleted && data.id) {
        setLoading(false);
        return setStateErrorMessage({
          type: "email",
          message: "Cet email existe déjà",
        });
      }

      let payload: any = {
        firstName: form.firstName,
        lastName: form.lastName,
        password: form.password,
        email: form.email,
        phone: form.phone,
        role: switchValue === "Admin" ? UserTypes.Admin : UserTypes.Cabinet,
        activated: true,
        application_id: APPLICATION_ID,
        username: APPLICATION_NAME,
      };

      //setting permissions for cabinet
      if (switchValue === UserTypes.Cabinet && form.adminPerms?.length) {
        payload.permissionsid = form.adminPerms;
      }

      //setting permissions for admin
      if (switchValue.toLowerCase() === "admin") {
        payload.permissionsid = permissions.reduce(
          (acc, curr) => [...acc, curr.id as string],
          [] as string[]
        );
      }

      //setting companies for cabinet
      if (switchValue === UserTypes.Cabinet && form.cabinetCompanies?.length) {
        payload.companiesid = form.cabinetCompanies?.reduce(
          (acc, curr) => [...acc, curr.value],
          [] as string[]
        );
      }

      // new admin
      if (!data.exists && !data.deleted && !data.id) {
        const { data: userData } = await api.post(`/api/User/Create`, payload, {
          headers: {
            "x-access-token": creds.token,
          },
        });

        if (!userData) {
          setLoading(false);
          setStateErrorMessage({
            type: "application",
            message: "Impossible de créer un admin",
          });
          setTimeout(() => {
            setOpen(false);
          }, 3000);
        }
      }
      // deleted admin
      if (data.exists && data.deleted && data.id) {
        const { data: userData } = await api.post(
          `/api/User/createCustomUser`,
          { ...payload, inherit_type: switchValue },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );

        if (!userData) {
          setLoading(false);
          setStateErrorMessage({
            type: "application",
            message: "Impossible de créer un admin",
          });
          setTimeout(() => {
            setOpen(false);
          }, 3000);
        }
      }

      if (!data) {
        setLoading(false);
        setStateErrorMessage({
          type: "application",
          message: "Impossible de créer un admin",
        });
        setTimeout(() => {
          setOpen(false);
        }, 3000);
      }

      setLoading(false);
      toast.dismiss()
      toast.success("Votre ajout a été effectué avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setOpen(false);
      dispatch(fetchAllApplications());
      navigate(`/gestion-admin`);
    } catch (error: any) {
      setLoading(false);
      ErrorLogger("creating admin", error);
      setLoading(false);
      ErrorLogger("creating an admin", error);
      setStateErrorMessage({
        type: "application",
        message: "Impossible de créer un admin",
      });
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  };

  const { ref: lastNameRef, ...lastName } = register("lastName");
  const { ref: firstNameRef, ...firstName } = register("firstName");
  const { ref: adminPermsRef, ...adminPerms } = register("adminPerms");
  const { ref: emailRef, ...email } = register("email");
  const { ref: phoneRef, ...phone } = register("phone");
  const { ref: passwordRef, ...password } = register("password");
  const { ref: passwordRepeatRef, ...password_repeat } =
    register("password_repeat");

  return (
    <>
      
      <div className="page page-admin">
        <Button
          color="warning"
          className="btn-creat-admin"
          onClick={() => ontoggle()}
        >
          <UserOrangeIcon />
          <span>Créer un admin</span>
        </Button>
        <div className="top-content">
          <Row>
            <Col lg={8} md={12}>
              <BannerTop banner={data.gestionadmin} />
            </Col>
            <Col lg={4} md={12}>
              <UserInfo user={user} />
            </Col>
          </Row>
        </div>

        <div className="full-tabs">
          <Tabs
            selectedTab={1}
            onClick={setSelectedTab}
            tabs={[
              {
                label: user.application.name || user.application.baseUrl,
                image: "",
                index: 1,
                Component: WhiteLabelAdmins,
              },
            ]}
          />
        </div>

        <div className="openbtn text-center">
          <Modal
            className="modal-warning modal-dialog-centered modal-lg modal-apercu"
            isOpen={open}
            toggle={() => {
              setOpen(false);
              setLoading(false);
              setCrudLoading(false);
              setStateErrorMessage(null);
            }}
          >
            <ModalHeader
              toggle={() => {
                setOpen(false);
                setLoading(false);
                setCrudLoading(false);
                setStateErrorMessage(null);
              }}
            >
              Créer un nouvel administrateur
            </ModalHeader>
            <form onSubmit={handleSubmit(createAdmin)}>
              <ModalBody>
                <div className="content-form-block content-admin">
                  <h4>Informations générales</h4>
                  <Row>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="name">Nom</Label>
                        <Input
                          id="name"
                          innerRef={lastNameRef}
                          {...lastName}
                          placeholder="Nom"
                          type="text"
                          className="form-warning"
                        />
                        <span className="icon icon-warning ">
                          <img src={UserIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="prenom">Prénom</Label>
                        <Input
                          id="prenom"
                          innerRef={firstNameRef}
                          {...firstName}
                          placeholder="Prénom"
                          type="text"
                          className="form-warning"
                        />
                        <span className="icon icon-warning ">
                          <img src={UserIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="email">Email</Label>
                        <Input
                          id="email"
                          innerRef={emailRef}
                          {...email}
                          placeholder="Email"
                          type="text"
                          className="form-warning"
                          onChange={() => {
                            setStateErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-warning ">
                          <img src={EmailIcon} alt="icon" />
                        </span>
                      </FormGroup>
                      {stateErrorMessage?.type === "email" && (
                        <Alert color="danger">
                          {stateErrorMessage?.message}
                        </Alert>
                      )}
                      {stateErrorMessage?.type === "no_email" && (
                        <Alert color="danger">
                          {stateErrorMessage?.message}
                        </Alert>
                      )}
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="phone">Téléphone</Label>
                        <Input
                          id="phone"
                          innerRef={phoneRef}
                          {...phone}
                          placeholder="Téléphone"
                          type="text"
                          className="form-warning"
                        />
                        <span className="icon icon-warning ">
                          <img src={PhoneIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="pwd">Mot de passe</Label>
                        <Input
                          id="pwd"
                          innerRef={passwordRef}
                          {...password}
                          placeholder="Mot de passe"
                          type="password"
                          className="form-warning"
                          onChange={() => {
                            setStateErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-warning ">
                          <img src={KeyIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="cpwd">Confirmer le mot de passe</Label>
                        <Input
                          id="cpwd"
                          innerRef={passwordRepeatRef}
                          {...password_repeat}
                          placeholder="Confirmer le mot de passe"
                          type="password"
                          className="form-warning"
                          onChange={() => {
                            setStateErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-warning ">
                          <img src={KeyIcon} alt="icon" />
                        </span>
                      </FormGroup>
                      {stateErrorMessage?.type === "password_repeat" && (
                        <Alert color="danger">
                          {stateErrorMessage?.message}
                        </Alert>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} sm={12}>
                      <div className="box-list">
                        <label className="form-label">
                          Type d'administrateur :
                        </label>
                        <div className="custom-switch admin-cabinet-custom-switch">
                          <span className="label orangeTxt">Admin</span>
                          <CustomSwitch
                            id="admin-cabinet-switch"
                            switchValue={switchValue}
                            setSwitchValue={setSwitchValue}
                          />
                          <span className="label">Sous-compte</span>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} sm={12}>
                      {switchValue === UserTypes.Cabinet && (
                        <>
                          <div className="lists-clients-block">
                            <FormGroup>
                              <Label for="exampleEmail">
                                Liste des clients
                              </Label>
                              <Controller
                                name="cabinetCompanies"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    options={filterCompanies()}
                                    {...field}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    classNamePrefix="select"
                                    className="custom-select form-warning"
                                    name="cabinetComps"
                                  />
                                )}
                              />
                            </FormGroup>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  {switchValue === UserTypes.Cabinet && (
                    <>
                      <div className="checkbox-form">
                        <div className="inner-checkbox-form">
                          <h4>Droits d'accès</h4>
                          <Row>
                            {permissionsList.length &&
                              permissionsList.map((perm, index) => (
                                <Col md={6} key={index}>
                                  <div className="check-box check-warning">
                                    <input
                                      className="styled-checkbox"
                                      id={`checkbox-${index}`}
                                      type="checkbox"
                                      value={perm.id}
                                      ref={adminPermsRef}
                                      {...adminPerms}
                                    />
                                    <label htmlFor={`checkbox-${index}`}>
                                      {perm.label}
                                    </label>
                                  </div>
                                </Col>
                              ))}
                          </Row>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </ModalBody>

              <ModalFooter>
                <Button
                  color="warning"
                  outline
                  onClick={() => {
                    setOpen(false);
                    setLoading(false);
                    setCrudLoading(false);
                    setStateErrorMessage(null);
                  }}
                  type="button"
                  disabled={loading || crudLoading}
                >
                  {loading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Annuler"
                  )}
                </Button>
                <Button
                  color="warning"
                  type="submit"
                  disabled={loading || crudLoading}
                >
                  {loading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Ajouter"
                  )}
                </Button>
              </ModalFooter>
            </form>
          </Modal>
          <FileViewer
            url={url!}
            setUrl={setUrl}
            viewModal={viewModal}
            setViewModal={setViewModal}
            fileType="image"
          />
        </div>
      </div>
    </>
  );
};

export default GestionAdmin;

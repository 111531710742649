import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import "./style.scss";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useParams } from "react-router-dom";
import config from "../../../config";
import { useSelector } from "react-redux";
import axios from "axios";
import { ErrorLogger } from "../../../util/errorLogger";
import {
  Company,
  ICategory,
  IS3,
  ISubCategory,
  ITransaction,
  User,
} from "../../../interfaces";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  RiCheckboxCircleLine,
  RiDownload2Fill,
  RiIndeterminateCircleFill,
} from "react-icons/ri";
import AttacheIcon from "../../../assets/AttacheIcon";
import moment from "moment";
import "moment/locale/fr";
import {
  GENDER_OPTIONS,
  OptionType,
  TransactionMediums,
  UserTypes,
} from "../../../util/context";
import EmailIcon from "../../assets/images/svg/mail-blue.svg";
import PhoneIcon from "../../assets/images/svg/phone-blue.svg";
import { BsMailbox, BsTrashFill } from "react-icons/bs";
import { BootyPagination } from "../../../components/table/pagination";
import DataTable from "react-data-table-component";
import useAxios from "../../../util/hooks/useAxios";
import FilePicker from "../../../components/FilePicker";
import FileViewer, { DocView } from "../../../components/FileViewer";
import { toast, ToastContainer } from "react-toastify";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import Select from "react-select";
registerLocale("fr", fr);

type GeneralFormValues = {
  file: any;
};

type ModalData = {
  DueDate: string | null;
  Subtotal: string | number | null;
  CustomerName: string | null;
  IssueDate: string | null;
  ID: string | number | null;
  Total: string | number | null;
  Tax: string | number | null;
  VendorName: string | null;
  TVANumber: string | null;
  file: any;
};

const { API_URL } = config[process.env.NODE_ENV];

const MemoDocView = React.memo(DocView);

const InvoiceImport = ({
  context,
  company,
  client,
  callback,
}: {
  context?: string;
  company?: Company;
  client?: User;
  callback?: () => void;
}) => {
  const [url, setUrl] = useState<string | null>(null);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const [enc, setEnc] = useState<string>("");
  const [encCategs, setEncCategs] = useState<ICategory[]>([]);
  const [decCategs, setDecCategs] = useState<ICategory[]>([]);
  const [subCateg, setSubCateg] = useState<OptionType | null>(null);

  const [viewModal, setViewModal] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { id } = useParams();

  let api = useAxios();

  const {
    watch,
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ModalData>({});

  const emptyOutForm = () => {
    reset({
      DueDate: null,
      Subtotal: null,
      CustomerName: null,
      IssueDate: null,
      ID: null,
      Total: null,
      Tax: null,
      VendorName: null,
      TVANumber: null,
    });
    setEnc("");
    setSubCateg(null);
  };

  const uploadFacture = async () => {
    try {
      setFileLoading(true);

      if (!file) {
        setFileLoading(false);
        toast.dismiss();
        toast.warning("Veuillez ajouter une facture", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      const formData = new FormData();
      formData.append(
        "companyId",
        creds.role === UserTypes.Client ? creds.company_id : (id as string)
      );
      formData.append("facture", file);

      const { data } = await api.post(
        `/api/documents/import-invoice`,
        formData,
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (data.err && data.err.msg) {
        setFileLoading(false);
        setFile(null);
        toast.dismiss();
        toast.warning(data.err.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      if (data.response) {
        reset({
          DueDate: data.response.DueDate || null,
          Subtotal: data.response.Subtotal || null,
          CustomerName: data.response.CustomerName || null,
          IssueDate:
            data.response.IssueDate &&
            !moment(data.response.IssueDate)
              .format("DD/MM/yyyy")
              .includes("Invalid date")
              ? moment(data.response.IssueDate).format("DD/MM/yyyy")
              : null,
          ID: data.response.ID || null,
          Total: data.response.Total || null,
          Tax: data.response.Tax || null,
          VendorName: data.response.VendorName || null,
          TVANumber: data.response.TVANumber || null,
          file,
        });
        setUrl(URL.createObjectURL(file as any));
        setConfirmModal(true);
      }

      setFileLoading(false);
    } catch (error: any) {
      ErrorLogger("updating client form", error);
      setFileLoading(false);
      toast.dismiss();
      toast.warning(
        "Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const { ref: SubtotalRef, ...Subtotal } = register("Subtotal");
  const { ref: CustomerNameRef, ...CustomerName } = register("CustomerName");
  const { ref: IssueDateRef, ...IssueDate } = register("IssueDate");
  const { ref: TotalRef, ...Total } = register("Total");
  const { ref: TaxRef, ...Tax } = register("Tax");

  const approveFacture: SubmitHandler<ModalData> = async (form: ModalData) => {
    try {
      setApproveLoading(true);
      if (!form.file) {
        setApproveLoading(false);
        toast.dismiss();
        toast.warning(
          "Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard",
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }

      if (
        !form.IssueDate ||
        !form.Total ||
        !form.Tax ||
        !form.Subtotal ||
        !form.CustomerName ||
        enc === "" ||
        !subCateg
      ) {
        setApproveLoading(false);
        toast.dismiss();
        toast.warning(
          "Les données fournies sont dépourvues de certains attributs clés",
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }

      const formData = new FormData();
      formData.append(
        "companyId",
        creds.role === UserTypes.Client ? creds.company_id : (id as string)
      );

      formData.append("facture", form.file);

      formData.append("CustomerName", form.CustomerName);
      formData.append("IssueDate", moment(form.IssueDate).format("YYYY-MM-DD"));
      formData.append("Total", form.Total as string);
      formData.append("Subtotal", form.Subtotal as string);
      formData.append("Tax", form.Tax as string);
      formData.append("subCategoryId", subCateg?.value! as string);

      if (enc === "decaissement") {
        formData.append("parentType", "dec");
      }
      if (enc === "encaissement") {
        formData.append("parentType", "enc");
      }

      const { data } = await api.post(
        `/api/documents/approve-invoice`,
        formData,
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (data.err && data.err.msg) {
        setApproveLoading(false);
        setFile(null);
        toast.dismiss();
        toast.warning(data.err.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      if (data.msg) {
        toast.dismiss();
        toast.success("Votre facture a été téléchargée avec succès", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setApproveLoading(false);
      setConfirmModal(false);
      setFile(null);
      emptyOutForm();
    } catch (error: any) {
      ErrorLogger("updating client form", error);
      setApproveLoading(false);
      toast.dismiss();
      toast.warning(
        "Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const handleFactureChange = (event: { target: { files: any } }) => {
    setFile(event.target.files![0]);
  };

  const getCategories = async () => {
    try {
      const { data } = await api.post(
        `/api/Category/All`,
        {},
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const encaissementCategs = data.data.filter(
        (elt: ICategory) => elt.type === "Encaissement"
      );
      const decaissementCategs = data.data.filter(
        (elt: ICategory) => elt.type === "Décaissement"
      );

      setDecCategs(decaissementCategs);
      setEncCategs(encaissementCategs);
    } catch (error: any) {
      ErrorLogger("getting categories", error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <div className="page">
        <div className="card-Table table-primary card-body">
          <div className="file-uploader col-12 col-lg-7 mx-auto">
            <h3>Importation d'une facture</h3>
            <FilePicker
              className={"form-icon form-file file-secondary"}
              onChange={handleFactureChange}
              state={file}
              fileStagedDelete={() => {
                setFile(null);
              }}
              loader={fileLoading}
              setUrl={setUrl}
              setViewModal={setViewModal}
            />
            <p>
              NB : Formats de documents pris en charge sont{" "}
              <strong>PNG, JPEG, PDF, et TIFF</strong>.
            </p>
          </div>
          <div className="action-buttons">
            <Button
              color="secondary"
              outline
              type="button"
              disabled={fileLoading}
              onClick={() => {
                setFile(null);
                setFileLoading(false);
                setApproveLoading(false);
                setConfirmModal(false);
              }}
            >
              Réinitialiser
            </Button>
            <Button
              color="secondary"
              type="submit"
              disabled={fileLoading}
              onClick={async () => await uploadFacture()}
            >
              Importation
            </Button>
          </div>
        </div>
        <div className="openbtn text-center">
          <FileViewer
            url={url!}
            setUrl={setUrl}
            viewModal={viewModal}
            setViewModal={setViewModal}
          />
          {/*verify invoice data*/}
          <Modal
            className="modal-secondary modal-dialog-centered"
            isOpen={confirmModal}
            size={"xl"}
            toggle={() => {
              setConfirmModal(false);
              emptyOutForm();
              setFile(null);
            }}
          >
            <ModalHeader
              toggle={() => {
                setConfirmModal(false);
                emptyOutForm();
                setFile(null);
              }}
            >
              Vérification des données
            </ModalHeader>
            <form onSubmit={handleSubmit(approveFacture)}>
              <ModalBody>
                <Row>
                  <Col md={file && url ? 6 : 12}>
                    <Row>
                      <Col md={file && url ? 6 : 4}>
                        <FormGroup className="form-icon">
                          <Label for="CustomerName">Nom</Label>
                          <Input
                            id="CustomerName"
                            innerRef={CustomerNameRef}
                            {...CustomerName}
                            type="text"
                            className="form-secondary"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={file && url ? 6 : 4}>
                        <FormGroup className="form-icon">
                          <Label for="Subtotal">Total HT</Label>
                          <Input
                            id="Subtotal"
                            innerRef={SubtotalRef}
                            {...Subtotal}
                            type="number"
                            min={0}
                            step={0.01}
                            className="form-secondary"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={file && url ? 6 : 4}>
                        <FormGroup className="form-icon">
                          <Label for="Tax">Total TVA</Label>
                          <Input
                            id="Tax"
                            innerRef={TaxRef}
                            {...Tax}
                            type="number"
                            min={0}
                            step={0.01}
                            className="form-secondary"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={file && url ? 6 : 4}>
                        <FormGroup className="form-icon">
                          <Label for="Total">Total TTC</Label>
                          <Input
                            id="Total"
                            innerRef={TotalRef}
                            {...Total}
                            type="number"
                            min={0}
                            step={0.01}
                            className="form-secondary"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={file && url ? 6 : 4}>
                        <FormGroup className="form-icon icon-end">
                          <Label for="IssueDate">Date</Label>
                          <Controller
                            control={control}
                            name="IssueDate"
                            render={({ field }) => (
                              <DatePicker
                                placeholderText="Date"
                                onChange={(date: any) => field.onChange(date)}
                                selected={
                                  field.value ? new Date(field.value) : null
                                }
                                className="form-control form-secondary"
                                yearDropdownItemNumber={100}
                                scrollableYearDropdown={true}
                                showYearDropdown
                                showMonthDropdown
                                locale="fr"
                                dateFormat="dd/MM/yyyy"
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={file && url ? 6 : 4}>
                        <Label>Type</Label>
                        <div className="check-box check-secondary mt-1">
                          <input
                            type="radio"
                            id="test2"
                            name="radio-group"
                            onClick={() => {
                              if (subCateg) {
                                setSubCateg(null);
                              }
                              setEnc("encaissement");
                            }}
                          />
                          <label htmlFor="test2">Encaissement</label>
                        </div>
                        <div className="check-box check-secondary mt-1">
                          <input
                            type="radio"
                            id="test3"
                            name="radio-group"
                            onClick={() => {
                              if (subCateg) {
                                setSubCateg(null);
                              }
                              setEnc("decaissement");
                            }}
                          />
                          <label htmlFor="test3">Décaissement</label>
                        </div>
                      </Col>
                      <Col md={file && url ? 6 : 4}>
                        <FormGroup className="form-icon icon-start">
                          <Label>Catégorie</Label>
                          <Select
                            options={(enc === "encaissement"
                              ? encCategs
                              : decCategs
                            )
                              .reduce(
                                (acc, curr) => [
                                  ...acc,
                                  ...(curr.sub_categories || []),
                                ],
                                [] as ISubCategory[]
                              )

                              .reduce(
                                (acc, curr) => [
                                  ...acc,
                                  { value: curr.id, label: curr.name },
                                ],
                                [] as { value: string; label: string }[]
                              )}
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                            onChange={(subCayegory) =>
                              setSubCateg(subCayegory!)
                            }
                            placeholder="Catégorie"
                            value={subCateg}
                            noOptionsMessage={() => <p>Aucune option</p>}
                            isDisabled={enc === ""}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  {file && url && (
                    <Col className="invoice-viewer" md={6}>
                      <MemoDocView url={url} />
                    </Col>
                  )}
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  outline
                  type="button"
                  onClick={() => {
                    setConfirmModal(false);
                    emptyOutForm();
                    setFile(null);
                  }}
                  disabled={approveLoading}
                >
                  Ne pas valider
                </Button>
                <Button
                  color="secondary"
                  type="submit"
                  disabled={approveLoading}
                >
                  {approveLoading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Valider"
                  )}
                </Button>
              </ModalFooter>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default InvoiceImport;

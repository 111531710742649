import {AxiosInstance} from "axios";

export const getBridgeAccount = async ({
  api,
  clientId,
}: {
  api: AxiosInstance;
  clientId: string;
}) => {
  try {
    const body = {
      clientId,
    };

    const res = await api.post("/api/v2/bridgeAccount/all", body);
    return res;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
  search: [],
};

export const categoriesSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    setCategories: (state: any, action: any) => {
      state.categories = action.payload;
    },
    searchCategories: (state: any, action: any) => {
      state.search = action.payload;
    },
    resetCategories: (state: any) => {
      Object.assign(state, initialState);
    },
  },
});

export const { setCategories, searchCategories, resetCategories } =
  categoriesSlice.actions;
export default categoriesSlice.reducer;

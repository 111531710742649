import React, { FC, useEffect, useState } from "react";
import EditIcon from "../../../assets/EditIcon";
import "./ModelsTab.scss";
import {
  Company,
  ICategory,
  IModel,
  IQuittanceModel,
  ISubCategory,
  ITransaction,
  User,
} from "../../../interfaces";

import tvaEditIcon from "../../assets/images/svg/edit-small-blue.svg";
import { IoMdAddCircle, IoMdCreate } from "react-icons/io";
import { BsCheck2Circle, BsTrashFill } from "react-icons/bs";
import { FaFileInvoice } from "react-icons/fa";
import { Col, Input, Row, Spinner } from "reactstrap";
import { ErrorLogger } from "../../../util/errorLogger";
import { SubmitHandler, useForm } from "react-hook-form";
import ZoomIcon from "../../../assets/ZoomIcon";
import { colors } from "../../../constants";
import { RiCloseLine } from "react-icons/ri";

export type ImmobStateHandler = {
  isImmobFire: boolean;
  setIsImmobFire: any;
  isImmobSubCateg: ISubCategory | null;
  setIsImmobSubCateg: any;
  isImmobTrans: ITransaction | null;
  setIsImmobTrans: any;
} | null;

export type TabType = {
  index: number;
  model: IModel | IQuittanceModel;
  Component: any;
};

type TabsProps = {
  tabs: TabType[];
  orientation?: "horizontal" | "vertical";
  className?: string;
  context?: string;
  company?: Company;
  client?: User;
  callback?: any;
  reload?: boolean;
  isLoaderModel?: boolean;
  loaderModelLoading?: { index: string; value: boolean } | null;
};

type SearchFormValues = {
  text?: string | null;
};

/**
 * Avalible Props
 * @param className string
 * @param tab Array of object
 * @param selectedTab number
 * @param onClick Function to set the active tab
 * @param orientation Tab orientation Vertical | Horizontal
 */
const ModelsTabs: FC<TabsProps> = ({
  className = "models-view-component",
  tabs = [],
  orientation = "horizontal",
  context = "",
  company = {},
  client = {},
  callback = null,
  reload = false,
  isLoaderModel = false,
  loaderModelLoading,
}) => {
  // const Panel = tabs && tabs.find((tab) => tab.index === selectedTab);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedPanel, setSelectedPanel] = useState<TabType>();
  const [models, setModels] = useState<TabType[]>([]);
  const [tmpModels, setTmpModels] = useState<TabType[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [searching, setSearching] = useState<boolean>(false);

  useEffect(() => {
    setSelectedPanel(tabs.find((tab) => tab.index === selectedTab));
  }, [selectedTab]);

  useEffect(() => {
    setModels(tabs);
    setTmpModels(tabs);
    return () => {
      setSearching(false);
      setSearchText("");
      setModels([]);
      setTmpModels([]);
    };
  }, [tabs]);

  useEffect(() => {
    if (searchText && searchText !== "") {
      searchModel();
    }
  }, [searchText]);

  const searchModel = () => {
    try {
      setModels(
        tmpModels.filter(
          (elt) =>
            elt.model.ref &&
            elt.model.ref
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                searchText
                  ?.toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "") || ""
              )
        )
      );
    } catch (error: any) {
      ErrorLogger("searching model", error);
    }
  };

  return (
    <>
      <div className="search-top mb-3">
        <Row>
          <Col md={12} className="form-icon  icon-end">
            <Input
              id="mc"
              placeholder="Chercher un modèle"
              type="text"
              className="form-primary"
              onChange={(e) => {
                if (!searching) {
                  setSearching(true);
                }
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
            <span className="icon icon-primary ">
              {searching ? (
                <RiCloseLine
                  onClick={() => {
                    setSearching(false);
                    setSearchText("");
                    setModels(tmpModels);
                  }}
                  style={{
                    cursor:"pointer"
                  }}
                />
              ) : (
                <ZoomIcon stroke={colors.primaryColor} />
              )}
            </span>
          </Col>
        </Row>
      </div>
      <div
        className={
          orientation === "vertical" ? className + " vertical" : className
        }
      >
        <div
          className="models-list"
          role="models-list"
          aria-orientation={orientation}
        >
          {models.map((tab) => (
            <button
              className={`${
                selectedTab === tab.index ? "model-btn active" : " model-btn"
              }`}
              onClick={() => setSelectedTab(tab.index)}
              key={tab.index}
              type="button"
              role="tab"
              aria-selected={selectedTab === tab.index}
              aria-controls={`modelpanel-${tab.index}`}
              tabIndex={selectedTab === tab.index ? 0 : -1}
              id={`model-btn-${tab.index}`}
            >
              <div className={`model-tab`}>
                <div className="model-tab-label">
                  <FaFileInvoice size={25} />
                  <span className="model-tab-label-text">
                    Modéle {tab.model.type}{" "}
                    {tab.model.ref ||
                      tab.model.clientInfo?.clientName ||
                      tab.model.id}
                  </span>
                </div>

                <button
                  className={`model-tab-btn btn ${
                    isLoaderModel ? "btn-green" : "btn-red"
                  }`}
                  onClick={() => {
                    if (isLoaderModel) {
                      callback(tab.model)!;
                    } else {
                      callback(true, tab.model)!;
                    }
                  }}
                >
                  {loaderModelLoading &&
                  loaderModelLoading.index === tab.model.id ? (
                    <Spinner color="success" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    <>{isLoaderModel ? <IoMdAddCircle /> : <BsTrashFill />}</>
                  )}
                </button>
              </div>
            </button>
          ))}
        </div>
        <div
          role="modelPanel"
          aria-labelledby={`btn-${selectedTab}`}
          id={`modelPanel-${selectedTab}`}
          className="modelPanel"
        >
          {selectedPanel && <selectedPanel.Component index={selectedTab} />}
        </div>
      </div>
    </>
  );
};
export default ModelsTabs;

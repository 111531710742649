import React, { PropsWithChildren, ReactElement } from "react";
import "./CardDashDepense.scss";
import { ICardDepenseProperties } from "../../interfaces";
import { Card, Progress } from "reactstrap";

function CardDashDepense(
  props: PropsWithChildren<ICardDepenseProperties>
): ReactElement {
  return (
    <>
      <Card className={`card-dash-depense ${props.className} `}>
        <div className=" box-inner-card">
          <div className="card-icon">
            <img src={props.icon} alt="icon" />
          </div>
          <div className="card-texte">
            <h4>{props.title}</h4>
            <div className="card-price">{props.text}</div>
            <div className="etat-value-item">
              <Progress value={props.percent}>
                <span className="progress-percent">{props.percent} %</span>
              </Progress>
            </div>
            <p>Du dépenses du mois</p>
          </div>
        </div>
      </Card>
    </>
  );
}

export default CardDashDepense;

import React from "react";
import {Col, Row} from "reactstrap";
import img_1 from "./images/img-1.png";
import img_2 from "./images/img-2.png";
import img_3 from "./images/img-3.png";
import "./style.scss";
import moment from "moment";

export default function index({
  companyName = "",
  companyAddress = "",
  companyForm = "",
  companyTVA = "",
  userName = "",
}) {
  return (
    <div>
      <div className="letter-page-wrapper">
        <Row>
          <Col md={6} className="letter-header-wrapper">
            <div className="letter-header-images">
              <div className="letter-header-img">
                <img
                  src={img_1}
                  alt="img-1"
                  className="letter-header-images-img-1"
                />
              </div>

              <div className="letter-header-img">
                <img
                  src={img_2}
                  alt="img-2"
                  className="letter-header-images-img-2"
                />
              </div>
            </div>
            <div className="letter-header-info">
              <div>
                <p className="letter-header-info-adress">
                  Adresse : 10 rue de Penthièvre, 75008 Paris
                </p>
                <p className="letter-header-info-siret">SIRET : 883169302</p>
                <p className="letter-header-info-capital">
                  Capital Social : 500 €
                </p>
                <p className="letter-header-info-capital">Code Ape: 6920Z</p>
              </div>
            </div>
          </Col>
          <Col md={6} className="letter-header-subject">
            <div className="letter-header-subject-wrapper">
              <p className="letter-header-subject-companyName">{companyName}</p>
              <br />
              <p className="letter-header-subject-companyAddress">
                {companyAddress}
              </p>
              <p className="letter-header-subject-companyForm">{companyForm}</p>
            </div>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-pragraphs">
            <p>Noly Compta est une société d’expertise-comptable en ligne.</p>
            <p>
              Cette présente lettre de mission permet d’encadrer la
              collaboration et l’accompagnement entre notre société d’expertise
              comptable et {companyName}.
            </p>
            <p>
              Notre objectif est de prendre en charge les démarches que vous
              pourriez être amenés à effectuer tout en vous prodiguant nos
              conseils.
            </p>
          </Col>
          <Col md={12} className="letter-pragraphs">
            <p>
              Noly Compta est un cabinet d’expertise comptable reconnu et
              référencé par&nbsp;:
            </p>
            <p style={{marginLeft: "15px"}}>
              {" "}
              - L’ordre des experts-comptables auprès duquel il est inscrit et
              respecte les règles de déontologie.
            </p>
            <p style={{marginLeft: "15px"}}>
              {" "}
              - L’administration fiscale et l’URSSAF comme tiers de confiance,
              pour télédéclarer et transmettre les informations requises sur
              votre entreprise.
            </p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Noly Compta permet aussi au travers de nombreuses fonctionnalités
              de vous tenir informé en temps réel de votre situation au travers
              du développement d’une application qui permet de collecter des
              données sur votre société et d’avoir à disposition vos pièces
              comptables et le suivi de vos opérations en cours.
            </p>
          </Col>
          <Col md={12} className="letter-pragraphs">
            <p>
              Conformément à notre déontologie, nous vous invitons à prendre
              connaissance de notre lettre de mission obligatoire dans le cadre
              d’une collaboration.
            </p>
            <p>
              Du surcroît, cette lettre de mission constitue un tout
              indissociable avec les conditions générales d’utilisation du
              service (consultables sur{" "}
              <a href="http://www.noly-compta.fr/">www.noly-compta.fr</a>).
            </p>
          </Col>
        </Row>

        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-title">
            <p>A. Votre société</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Cette lettre de mission s’applique au client dont les
              caractéristiques principales sont énoncées ci-dessous.
            </p>
            <p>
              Les informations que vous retrouverez dans cette présente lettre
              de mission seront le reflet des informations qui ont été
              renseignées dans notre espace en ligne Noly Compta.
            </p>
            <p>
              Nous vous proposons dès lors d’accorder une importance
              particulière à ces différentes informations.
            </p>
          </Col>
          <Col md={12} className="letter-pragraphs">
            <p>
              {companyName} est une {companyForm} soumise à l’impôt des sociétés
              et {companyTVA} assujettie au régime réel mensuel de TVA.
            </p>
          </Col>
        </Row>
        <Row className="letter-footer">
          <Col md={12} className="footer-number-wrapper">
            1
          </Col>
        </Row>
      </div>
      <div className="letter-page-wrapper">
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-title">
            <p>B. Offre et affectation des missions</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Le tableau ci-dessous permettra de constater les missions que Noly
              Compta réalisera pour vous, dans le but de vous fournir un
              accompagnement approprié et des conseils personnalisés, et la
              partie restante qui est réalisée au sein de {companyName} .
            </p>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-table table-responsive">
            <table className="table table-bordered align-middle">
              <thead>
                <tr>
                  <th></th>
                  <th colSpan={2}>RÉPARTITION</th>
                </tr>
                <tr>
                  <th></th>
                  <th>
                    <img
                      src={img_3}
                      alt="img-3"
                      className="letter-header-images-img-3"
                    />
                  </th>
                  <th style={{verticalAlign: "middle"}}>Votre société</th>
                </tr>
              </thead>
              <tbody>
                <tr className="letter-custom-tr">
                  <td colSpan={3}>Tenue de la comptabilité</td>
                </tr>
                <tr>
                  <td className="letter-td">
                    Pré-catégorisation de la ligne bancaire{" "}
                  </td>
                  <td>&nbsp;</td>
                  <td>X</td>
                </tr>
                <tr>
                  <td className="letter-td">
                    Catégorisation manuelle le cas échéant{" "}
                  </td>
                  <td>&nbsp;</td>
                  <td>X</td>
                </tr>
                <tr>
                  <td className="letter-td">
                    Validation de la ligne bancaire dans tous les cas
                  </td>
                  <td>&nbsp;</td>
                  <td>X</td>
                </tr>
                <tr>
                  <td className="letter-td">
                    Transmission numérique des pièces comptables demandées
                  </td>
                  <td>&nbsp;</td>
                  <td>X</td>
                </tr>
                <tr className="letter-custom-tr">
                  <td colSpan={3}>Déclarations fiscales et sociales</td>
                </tr>
                <tr>
                  <td className="letter-td">
                    Déclarations fscales 2065, 2031 ou 2035{" "}
                  </td>
                  <td>X</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td className="letter-td">
                    Calcul des montants des déclarations de TVA
                  </td>
                  <td>X</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td className="letter-td">
                    Calcul des montants des déclarations sociales des
                    indépendants (DSI)
                  </td>
                  <td>X</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td className="letter-td">
                    Déclarations efective et paiement éventuel de TVA et DSI
                  </td>
                  <td>X</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td className="letter-td">Déclaration CET et DAS 2 </td>
                  <td>&nbsp;</td>
                  <td>X</td>
                </tr>
                <tr>
                  <td className="letter-td">Déclaration DAS 2</td>
                  <td>X</td>
                  <td>X</td>
                </tr>
                <tr>
                  <td className="letter-td">
                    Déclarations sociales Travailleurs Non Salariés (TNS){" "}
                  </td>
                  <td>&nbsp;</td>
                  <td>X</td>
                </tr>
                <tr className="letter-custom-tr">
                  <td colSpan={3}>Établissement des comptes annuels</td>
                </tr>
                <tr>
                  <td className="letter-td">
                    Contrôle et révision des comptes
                  </td>
                  <td>X</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td className="letter-td">Écritures d’inventaire </td>
                  <td>&nbsp;</td>
                  <td>X</td>
                </tr>
                <tr>
                  <td className="letter-td">Édition et clôture annuelle </td>
                  <td>X</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td className="letter-td">
                    Comptes annuels et annexes (le cas échéant)
                  </td>
                  <td>X</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td className="letter-td">
                    Envoi auprès de l’administration fscale{" "}
                  </td>
                  <td>X</td>
                  <td>&nbsp;</td>
                </tr>
                <tr className="letter-custom-tr">
                  <td>Conseil, accompagnement et audit</td>
                  <td className="letter-custom-td" colSpan={2}>
                    Nous sommes à votre disposition, n’hésitez pas à nous
                    contacter pour prendre rendez-vous.
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row className="letter-footer">
          <Col md={12} className="footer-number-wrapper">
            2
          </Col>
        </Row>
      </div>
      <div className="letter-page-wrapper">
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-title">
            <p>C. Honoraires</p>
          </Col>
          <Col md={12} className="letter-sub-title">
            <p>1. Le Package</p>
          </Col>
          <Col md={12} className="letter-pragraphs">
            <p>
              Le Cabinet d’expertise comptable Noly Compta a été créé dans le
              but de permettre un suivi de vos opérations comptables du
              quotidien.
            </p>
            <p>
              Au travers de cet accompagnement, nos experts s’occupent de vos
              tâches ainsi que des obligations déclaratives et fiscales
              qu’incombent votre activité.
            </p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Nous vous proposons différentes tarifications en fonction de votre
              utilisation au quotidien et selon le service recherché. Nous vous
              prélèverons par mensualités successives. En cas d’inscription en
              cours d’exercice, vous devrez vous acquitter d’un rattrapage
              d’honoraires qui correspond aux mensualités depuis le début de
              votre exercice jusqu’à la date d’activation de l’abonnement. En ce
              sens, si votre exercice comptable compte moins de 12 mois, un
              reliquat d’honoraires vous sera demandé afin que le minimum
              d’honoraires perçus pour un exercice comptable soit l’équivalent
              de 12 mois de facturation mensuel pleine.
            </p>
          </Col>
        </Row>
        <Row className="letter-footer">
          <Col md={12} className="footer-number-wrapper">
            3
          </Col>
        </Row>
      </div>
      <div className="letter-page-wrapper">
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-sub-title">
            <p>2. Cas particuliers</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Pour tout ce qui concerne les cas nécessitant des rattrapages de
              comptabilité, de déclaration, de retard de paiement (taxes, impôts
              etc...) ou tout autre service sortant du cadre du package choisi,
              une tarification horaire sera appliquée.
            </p>
            <p>Le tarif horaire s’élève à 45 euros de l’heure hors taxes.</p>
            <p>
              L’estimation du délai se fera en amont en accord avec le client et
              Noly Compta.
            </p>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-title">
            <p>
              D. Lettre de mission, conditions générales d’expertise-comptable
            </p>
          </Col>
          <Col md={12} className="letter-sub-title">
            <p>1. Préambule</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              La présente lettre de mission est un contrat établi afin de se
              conformer aux dispositions de l’article 151 du Code de déontologie
              intégré au décret du 30 mars 2012 relatif à l’exercice de
              l‘activité d’expertise-comptable. Elle a pour objet de vous
              confirmer par écrit les termes et les objectifs de notre mission
              ainsi que la nature et les limites de celle-ci. Cette lettre de
              mission constitue un tout indissociable avec les conditions
              générales d’utilisation de la société Noly Compta.
            </p>
            <p>
              Par ailleurs, Noly Compta met à votre disposition une application
              internet accessible depuis l’adresse suivante :
              www.noly-compta.fr, il s’agit d’un site indispensable à la bonne
              mise en œuvre de notre mission.
            </p>
          </Col>
          <Col md={12} className="letter-sub-title">
            <p>2. Domaine d’application</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Lesdites conditions sont applicables aux conventions relatives aux
              missions conclues entre Noly Compta dénommé « le cabinet » ou « le
              professionnel comptable » et la société dénommée « le client ».
            </p>
          </Col>
          <Col md={12} className="letter-sub-title">
            <p>3. Définition de la mission et clientèle éligible</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Noly Compta s’occupe exclusivement des activités dont la
              comptabilité permet d’être tenue selon la méthode dite «
              comptabilité de trésorerie ».
            </p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              La mission que vous nous confierez dépend du régime fiscal auquel
              est soumis votre entreprise :
            </p>
            <ul>
              <li>
                <p>
                  Si vous êtes en BIC ou en Société : il s’agira d’une mission
                  de présentation des comptes annuels régie par les normes de
                  l’Ordre des experts-comptables et d’établissement des
                  déclarations fiscales afférentes. Cette mission ne constitue
                  ni un audit ni un examen limité des comptes de votre
                  entreprise.
                </p>
              </li>
              <li>
                <p>
                  Si vous êtes en BNC : il s’agira d’une mission d’assistance
                  dans l’accomplissement de vos obligations déclaratives au
                  travers de l’établissement de la déclaration fiscale n° 2035
                  conformément à l’article 96 du Code Général des Impôts.
                  L’établissement de votre déclaration professionnelle ainsi que
                  les états complémentaires dans le respect de la loi et de la
                  réglementation en vigueur, relèvera de notre mission. Son
                  exécution induit, en ce qui nous concerne, le respect des
                  textes légaux et réglementaires applicables aux
                  experts-comptables.
                </p>
              </li>
            </ul>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Quelle que soit votre catégorie, nous assurerons dans le cadre de
              la mission :
            </p>
            <ul>
              <li>
                <p>
                  L’établissement des déclarations récapitulatives annuelles et
                  des déclarations fiscales associées ;
                </p>
              </li>
              <li>
                <p>
                  La cohérence des éléments comptables catégorisés et validés
                  par vos soins ;
                </p>
              </li>
              <li>
                <p>
                  L’établissement des états financiers, fiscaux et des comptes
                  annuels à la clôture de chaque exercice,étant bien précisé que
                  vous devrez établir un inventaire détaillé et chiffré de vos
                  stocks, travaux en cours et prestations en cours ;
                </p>
              </li>
              <li>
                <p>
                  La gestion des immobilisations et des amortissements de la
                  société ;
                </p>
              </li>
              <li>
                <p>
                  Le dossier destiné à votre association agréée, le cas échéant.
                </p>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="letter-footer">
          <Col md={12} className="footer-number-wrapper">
            4
          </Col>
        </Row>
      </div>
      <div className="letter-page-wrapper">
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Les travaux que nous mettrons en œuvre ont pour objectif de nous
              permettre d’exprimer une opinion sur la cohérence et la
              vraisemblance des comptes pris dans leur ensemble et d’attester de
              leur régularité en la forme au regard du référentiel comptable
              applicable à votre secteur d’activité. Ils sont détaillés dans une
              lettre de mission et sont strictement limités à son contenu. Ils
              ne comportent ni le contrôle de la matérialité des opérations, ni
              le contrôle des inventaires physiques des actifs de votre
              entreprise à la clôture de l’exercice comptable (stocks,
              immobilisations, espèces en caisse notamment). Nos travaux ne
              comprennent pas le recours à la procédure de confirmation de
              soldes auprès de tiers par voie de confirmation directe et
              l’appréciation des procédures de contrôle interne. Ils n’ont pas
              pour objectif de déceler les erreurs, les fraudes ou les actes
              illégaux pouvant ou ayant existé dans votre entreprise. Toutefois,
              nous vous en informerions si nous étions conduits à en avoir
              connaissance.
            </p>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Nous vous précisons que nous sommes juridiquement redevables d’une
              simple obligation de moyens. Par conséquent, la vérification des
              écritures et leur rapprochement avec les pièces justificatives
              sont effectués par notre cabinet uniquement par épreuve et ne
              portent donc pas sur l’appréciation sur la légalité et sur la
              fiabilité des documents présentés.
            </p>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Nous attirons toutefois votre attention sur le fait que
              conformément à l’article 123-14 du Code de commerce, « les comptes
              annuels doivent être réguliers, sincères et donner une image
              fidèle du patrimoine, de la situation financière et du résultat de
              l’entreprise ». Vous restez ainsi responsables à l’égard des tiers
              de l’exhaustivité, de la fiabilité et de l’exactitude des
              informations comptables et financières concourant à la
              présentation des comptes. Cela implique notamment le respect des
              règles applicables à la tenue d’une comptabilité en France ainsi
              que du référentiel comptable applicable à votre secteur
              d’activité. Nous comptons sur votre entière coopération afin qu’il
              soit mis à notre disposition tous les documents et autres
              informations nécessaires qui nous permettront de mener à bien
              notre mission. Il est bien entendu que la mission pourra, sur
              votre demande, être complétée par d’autres interventions en
              matière fiscale, juridique, économique, financière ou de gestion.
            </p>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-sub-title">
            <p>4. Durée de la mission</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              La mission est conclue pour une durée d’une année correspondant à
              l’exercice comptable. Pour la première année, la durée de la
              mission couvre la période comprise entre la date d’effet de la
              lettre de mission et la date de clôture de l’exercice comptable,
              date d’échéance du contrat. La mission est renouvelable chaque
              année par tacite reconduction. Le Client peut résilier
              unilatéralement le contrat à tout moment. Dans cette hypothèse, il
              devra régler la somme de 49,90€ HT correspondant aux frais de
              résiliation. Les honoraires correspondant à chaque mois commencé
              sont dus et aucun remboursement ne sera effectué.
            </p>
            <p>
              Cette lettre reste en vigueur pour les exercices futurs, sauf en
              cas de résiliation, de modification ou d’annulation de notre
              mission selon les modalités décrites aux présentes conditions
              générales. En cas de résiliation au cours d’un exercice comptable,
              et sauf faute grave imputable au professionnel comptable, le
              client devra verser au professionnel comptable les honoraires dus
              pour le travail déjà effectué. Il est précisé que tous les
              acomptes bilans facturés et inclus dans le forfait mensuel seront
              considérés comme acquis et le client ne pourra se prévaloir d’un
              arrêt de la mission pour en demander le remboursement, et ce pour
              quelque motif que ce soit. Les honoraires facturés sont donc
              considérés comme acquis dans leur intégralité. En cas de
              manquement important duclient à ses obligations, le professionnel
              comptable aura la faculté de suspendre sa mission en informant le
              client par tout moyen écrit ou de mettre fin à sa mission après
              envoi d’une lettre recommandée demeurée sans effet. Dans le cas où
              la mission est amenée à être arrêtée en cours d’année civile, le
              client reconnaît expressément qu’il s’engage à effectuer lui-même
              la déclaration de taxe d’apprentissage et de formation continue de
              l’année concernée. Le cabinet ne pourrait être mis en cause pour
              ne pas l’avoir faite.
            </p>
          </Col>
        </Row>
        <Row className="letter-footer">
          <Col md={12} className="footer-number-wrapper">
            5
          </Col>
        </Row>
      </div>
      <div className="letter-page-wrapper">
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Lorsque la mission est suspendue, les délais de remise des travaux
              seront prolongés pour une durée égale à celle de la suspension
              pour autant que le professionnel comptable dispose de toutes les
              informations nécessaires à l’exécution des travaux à réaliser.
              Pendant la période de suspension, les obligations du client
              demeurent applicables.
            </p>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-sub-title">
            <p>5. Votre participation</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Dans le cadre de notre collaboration, vous vous engagez sur les
              points suivants :
            </p>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-sub-sub-title">
            <p>a. Prestations comptables et fiscales</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <ul>
              <li>
                <p>
                  Faire en sorte d’avoir un accès régulier à un ordinateur munis
                  d’une connexion internet vous permettant d’utiliser
                  l’application internet de Noly Compta ainsi que de respecter
                  l’ensemble des conditions d’utilisation du site internet ;
                </p>
              </li>
              <li>
                <p>
                  Autoriser Noly Compta à récupérer vos flux bancaires (ou de
                  manière plus générale tout flux s’inscrivant dans un journal
                  de banque, de vente ou d’achat) à l’aide de vos identifiants
                  de connexion via notre prestataire d’agrégation bancaire. Ces
                  données sont indispensables à la réalisation de nos
                  prestations, notamment la préparation de votre bilan. Il est
                  entendu que ces données sont strictement confidentielles et ne
                  feront l’objet d’aucun autre usage que la mise en œuvre de nos
                  missions comptables, fiscales, juridique ou de gestion sociale
                  ;
                </p>
              </li>
              <li>
                <p>
                  Utiliser des organismes bancaires et de paiement dont les flux
                  sont récupérables via notre prestataire d’agrégation bancaire.
                  L’immense majorité des établissements bancaires et de paiement
                  français sont utilisables ;
                </p>
              </li>
              <li>
                <p>
                  Fournir les informations et documents d’identification requis
                  en application des dispositions visées aux articles L561-1 et
                  suivants du Code monétaire et financier dans le cadre de
                  l’identification du client et des bénéficiaires effectifs.
                  Notamment, Kbis récents, documents d’identité en cours de
                  validité comportant une photographie pour le client et les
                  membres, personnes morales ou physiques, des organes de
                  direction et d’administration. Ces informations et documents
                  d’identification devront être tenus à jour régulièrement,
                  toute modification de leur contenu devant nous être
                  communiquée spontanément ;
                </p>
              </li>
              <li>
                <p>
                  Prendre au moins un rendez-vous avec l’un de nos comptables
                  pendant votre période d’essai, afin de faire un point complet
                  sur votre activité et de valider l’éligibilité de l’entreprise
                  à une mission comptable Noly Compta. Le cabinet Noly Compta ne
                  pourra être tenu responsable en cas de défaut de conseil si ce
                  rendez-vous n’a pas été pris ;
                </p>
              </li>
              <li>
                <p>
                  Compléter de manière exhaustive les paramètres de votre
                  entreprise dans l’application Noly Compta. En particulier,
                  renseigner et compléter les données de tous les associés et
                  salariés de l’entreprise ;
                </p>
              </li>
              <li>
                <p>
                  Tenir de manière hebdomadaire et rigoureuse la saisie de votre
                  comptabilité depuis l’application Noly Compta ;
                </p>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="letter-footer">
          <Col md={12} className="footer-number-wrapper">
            6
          </Col>
        </Row>
      </div>
      <div className="letter-page-wrapper">
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-pragraphs-custom">
            <ul>
              <li>
                <p>
                  Catégoriser les recettes et dépenses dans les catégories
                  concernées et corriger le cas échéant la pré-affectation faite
                  par l’application Noly Compta ;
                </p>
              </li>
              <li>
                <p>
                  Joindre un scan de toutes les pièces justificatives qui vous
                  seront demandées ;
                </p>
              </li>
              <li>
                <p>
                  Enregistrer vos notes de frais personnels, vos indemnités
                  kilométriques régulièrement sur l’application Noly Compta ;
                </p>
              </li>
              <li>
                <p>
                  Répondre aux questions qui vous seront adressées régulièrement
                  afin de faciliter la clôture de l’exercice et la connaissance
                  de votre dossier ;
                </p>
              </li>
              <li>
                <p>
                  Établir un état des stocks détaillé par quantité, prix
                  unitaire et pourcentage de dépréciation à la date du jour de
                  votre clôture ;
                </p>
              </li>
              <li>
                <p>
                  Le cas échéant, établir vos déclarations de TVA aux échéances
                  convenues avec l’administration fiscale et selon la
                  périodicité demandée. Nous mettons à votre disposition dans
                  l’application Noly Compta, un outil d’aide au calcul de votre
                  TVA ;
                </p>
              </li>
              <li>
                <p>
                  Noly Compta ne pourra être mis en responsabilité pour les
                  montants proposés par notre simulateur de TVA. Vous restez
                  donc seul responsable des montants déclarés, du respect des
                  échéances et de l’approvisionnement suffisant sur votre compte
                  bancaire. Cet outil sera le strict reflet des éléments que
                  vous aurez préalablement saisis sur la plateforme en
                  respectant toutes les dispositions fiscales applicables. En
                  revanche, une déclaration permettant de régulariser vos
                  montants de TVA sera réalisée par nos soins, le cas échéant,
                  une fois par année civile ;
                </p>
              </li>
              <li>
                <p>
                  Porter à notre connaissance sous 10 jours, tout événement
                  significatif dans la vie de l’entreprise et de ses dirigeants.
                  Notamment et sans limitation, création d’un établissement
                  supplémentaire, déménagement, cessation de paiement, prise de
                  participation, changement de date de clôture, assemblée
                  générale. Nous signaler également les engagements susceptibles
                  d’affecter les résultats ou votre situation patrimoniale ;
                </p>
              </li>
              <li>
                <p>
                  Nous autoriser à télétransmettre à l’administration fiscale
                  votre liasse fiscale et le cas échéant votre déclaration
                  sociale des indépendants (DSI). Cette lettre de mission tient
                  lieu de mandat de télétransmission TDFC et DSI.
                </p>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-sub-sub-title">
            <p>b. Avertissements et limitations</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <ul>
              <li>
                <p>
                  Si vous ne validez pas les écritures de votre relevé bancaire,
                  votre déclaration de TVA ne pourra pas se calculer via notre
                  portail. De fait, vous encourez des pénalités et majorations
                  de retard. Cette information est mise clairement à votre
                  connaissance et vous reconnaissez en prendre bonne note en
                  acceptant les termes de notre collaboration ;
                </p>
              </li>
              <li>
                <p>
                  Un barème de remises commerciales, facturations
                  complémentaires et rupture anticipée de notre mission est
                  appliqué dans les conditions définies à l’article « D.5.
                  Remises commerciales, facturations complémentaires et rupture
                  anticipée de la mission » ;
                </p>
              </li>
              <li>
                <p>
                  La catégorisation et la validation des écritures se font sous
                  votre entière responsabilité. Nous rappelons que les
                  justificatifs comptables ne nous sont pas transmis sauf si
                  nous vous en faisons la demande notamment par le biais de
                  notre logiciel. De ce fait, il est clairement exclu dans notre
                  mission de vérifier l’exactitude des catégorisations ;
                </p>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="letter-footer">
          <Col md={12} className="footer-number-wrapper">
            7
          </Col>
        </Row>
      </div>
      <div className="letter-page-wrapper">
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-pragraphs-custom">
            <ul>
              <li>
                <p>
                  Le logiciel informatique mis à disposition permet la
                  catégorisation comptable d’après le mode trésorerie.
                  Toutefois, à la date du jour de votre clôture, nous
                  reconstituerons l’engagement de vos créances et dettes. Vous
                  acceptez et prenez en note qu’il n’est pas possible de
                  fonctionner mensuellement selon une comptabilité d’engagement
                  ;
                </p>
              </li>
              <li>
                <p>
                  Lorsque vous nous remettez une note de frais, vous devez
                  indiquer uniquement les dépenses payées personnellement pour
                  votre entreprise. Nous ne vérifierons pas la présence
                  effective des frais (notes de restaurant, etc.), ni le
                  caractère de déductibilité. Vous séparerez la TVA afin de nous
                  indiquer le montant de cette dernière. Dans le cas contraire,
                  les dépenses seront enregistrées sans récupération de TVA.
                  Enfin, vous assurerez la conservation des frais justificatifs
                  lors d’éventuels contrôles fiscaux pendant une durée de 10 ans
                  ;
                </p>
              </li>
              <li>
                <p>
                  Les outils de simulation mis à disposition n’ont pas vocation
                  à donner une information exacte mais une tendance. Vous
                  acceptez et prenez en note que des écarts, parfois
                  significatifs peuvent exister ;
                </p>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-sub-title">
            <p>6. Prestations sociales (le cas échéant)</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Concernant tout ce qui est prestation sociale, il conviendra de
              nous contacter afin de définir les contours de vos besoins. Des
              lors, nous serons en mesure de vous soumettre une offre concrète à
              ce sujet. Les prestations sociales ne sont pas comprises dans la
              mission comptable, il conviendra donc de faire les démarches
              nécessaires en annexes.
            </p>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-sub-title">
            <p>7. Prestations juridiques (le cas échéant)</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Nous sommes en mesure de vous apporter toutes les prestations
              juridiques nécessaires à la bonne tenue de votre société.
            </p>
            <p>
              Veuillez trouver nos offres relatives à ce sujet sur notre site.
              Les prestations juridiques ne sont pas comprises dans la mission
              comptable, il conviendra donc de faire les démarches nécessaires
              en annexes (excepté les offres comprises dans le pack START).
            </p>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-title">
            <p>E. Mode d’intervention</p>
          </Col>
          <Col md={12} className="letter-sub-title">
            <p>1. Intervenants</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Compte tenu de la variété des travaux à exécuter, et parfois du
              volume à traiter, plusieurs collaborateurs du cabinet seront
              amenés à intervenir régulièrement ou ponctuellement. Les équipes
              de Noly Compta sont formées d’experts pluridisciplinaires qui sont
              à votre disposition en cas de problématique que vous rencontrerez.
            </p>
            <p>
              En cas de questions comptables ou techniques, Noly Compta vous
              prodigue un support client en expertise comptable qui est
              accessible par téléphone, mail, et par chat.
            </p>
            <p>
              Notre structure et notre souplesse nous permettent de satisfaire
              rapidement et efficacement les besoins de nos clients.
            </p>
          </Col>
          <Col md={12} className="letter-sub-title">
            <p>2. Conditions d’intervention</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>Nos relations seront réglées sur le plan juridique :</p>
            <ul>
              <li>
                <p>par les termes de cette lettre de mission ;</p>
              </li>
              <li>
                <p>par les conditions générales d’utilisation du service.</p>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="letter-footer">
          <Col md={12} className="footer-number-wrapper">
            8
          </Col>
        </Row>
      </div>
      <div className="letter-page-wrapper">
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              La nature même du présent contrat et les modalités de son
              exécution aboutissent à la délivrance d’une attestation telle que
              définie dans la mission normée de présentation, qui vous sera
              remise en même temps que les comptes annuels. Ce document permet
              aux tiers en relation avec votre entreprise de pouvoir s’assurer
              de la qualité de vos comptes. Lors de la préparation du bilan, le
              client reçoit un questionnaire par mail à retourner.
            </p>
            <p>
              Lors du remplissage du questionnaire, le client peut faire
              parvenir au cabinet toute remarque ou demande spécifique qu’il
              jugera pertinente. Le cabinet procède ensuite notamment à la
              révision des comptes et à l’élaboration des comptes annuels et de
              l’attestation. Ces documents sont ensuite remis au client par
              courriel. Une fois ces documents produits, toute modification ou
              correction desdits documents fera l’objet d’une facturation
              complémentaire comme précisé à l’article « Remises commerciales,
              facturations complémentaires et rupture anticipée de la mission ».
            </p>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-sub-title">
            <p>3. Obligations du professionnel comptable</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              L’expert-comptable effectue la mission qui lui est confiée
              conformément aux dispositions du Code de déontologie intégré au
              décret du 30 mars 2012 relatif à l’exercice de l’activité
              d’expertise comptable, de la norme professionnelle de « Maîtrise
              de la qualité », de la norme professionnelle relative aux
              obligations de la profession d’expertise comptable en matière de
              lutte contre le blanchiment des capitaux et le financement du
              terrorisme (NPLAB) élaborée en application des dispositions du
              Code monétaire et financier et le cas échéant de la norme
              professionnelle de travail spécifique à la mission considérée. Il
              contracte, en raison de cette mission, une obligation de moyens.
            </p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              L’expert-comptable peut se faire assister par les collaborateurs
              de son choix. Le nom du collaborateur principal chargé du dossier
              est indiqué au client.
            </p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              A l’achèvement de sa mission, l’expert-comptable restitue les
              documents appartenant au client que ce dernier lui a confiés pour
              l’exécution de la mission.
            </p>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-pragraphs-custom">
            <p>L’expert-comptable est tenu :</p>
            <ul>
              <li>
                <p>
                  à une obligation au secret professionnel dans les conditions
                  prévues à l’article 226-13 du Code pénal ;
                </p>
              </li>
              <li>
                <p>
                  à une obligation de discrétion, distincte de l’obligation
                  précédente, quant aux informations recueillies et à la
                  diffusion des documents qu’il a établis. Ces derniers sont
                  adressés au client, à l’exclusion de tout envoi à un tiers,
                  sauf demande du client. Les documents établis par l’expert-
                  comptable seront en conséquence adressés au client, à
                  l’exclusion de tout envoi direct à un tiers.
                </p>
              </li>
            </ul>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              La responsabilité civile professionnelle de l’expert-comptable ne
              peut être mise en jeu que sur une période contractuellement
              définie à compter du jour où le client a connu ou aurait dû
              connaître les faits lui permettant de la mettre en cause.
            </p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Tout événement susceptible d’avoir des conséquences notamment en
              matière de responsabilité doit être porté sans délai par le client
              à la connaissance de l’expert-comptable.
            </p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              La responsabilité contractuelle de l’expert-comptable à l’égard du
              client, pour toutes les conséquences dommageables d’une même
              mission, est limitée à un plafond de 5 000 euros.
            </p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              La responsabilité civile professionnelle de l’expert-comptable est
              couverte par un contrat d’assurance.
            </p>
          </Col>
        </Row>
        <Row className="letter-footer">
          <Col md={12} className="footer-number-wrapper">
            9
          </Col>
        </Row>
      </div>
      <div className="letter-page-wrapper">
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              La responsabilité de l’expert-comptable ne peut notamment être
              engagée dans l’hypothèse où le préjudice subi par le client est
              une conséquence :
            </p>
            <ul>
              <li>
                <p>
                  d’une information erronée ou d’une faute ou négligence commise
                  par le client ou par ses salariés,
                </p>
              </li>
              <li>
                <p>
                  du retard ou de la carence du client à fournir une information
                  nécessaire à l’expert-comptable,
                </p>
              </li>
              <li>
                <p>
                  des fautes commises par des tiers intervenant chez le client.
                </p>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-title">
            <p>
              F. Maniement d’espèces et tenue de caisse de ventes au comptant
            </p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              La force probante d’un chiffre d’affaires réalisé soit en partie,
              soit en totalité, par des ventes au comptant, repose sur le
              respect de la part du client d’un formalisme très strict. Le
              cabinet informe le client, dans le présent article, qu’il est
              nécessaire de respecter le formalisme, à travers les dispositions
              du code de commerce et du code général des impôts. Cela implique
              la production de « rouleaux de caisse enregistreuse » où la
              conservation informatique des transactions dont la caractéristique
              doit légalement être de pouvoir fournir le détail de chaque acte
              de vente. Du respect de ce formalisme dépend la comptabilisation
              des recettes et de la TVA collectée correspondante. Par
              conséquent, il incombe au client de veiller à respecter la stricte
              rigueur nécessaire en matière de justificatifs de caisse. Cela
              concerne les « rouleaux de caisse enregistreuse », lesquels font
              partie « des pièces justificatives du chiffre d’affaires sur
              lesquelles le droit de communication de l’administration s’exerce
              » (Rép. Guilhaumon. JO du 9 février 1929), mais aussi tous les
              justificatifs de dépenses effectuées à partir de la caisse
              (règlements effectués à des tiers en espèces). Dans la mesure où
              la quote-part du chiffre d’affaires de l’entreprise provenant de
              la caisse est significatif le cabinet se réserve la faculté de
              refuser de mener sa mission à terme, appréciant souverainement la
              qualité des justificatifs de caisse produits par son client.
            </p>
          </Col>
          <Col md={12} className="letter-title">
            <p>
              G. Déclaration d’état de cessation de paiement et difficultés
              financières
            </p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              La loi sur les faillites implique que l’état de cessation de
              paiement soit signifié aux instances légales compétentes, dès sa
              survenance. Le cabinet, dans le cadre de la mission de
              présentation des comptes, n’est pas tenu de suivre la gestion au
              quotidien de l’entreprise, mais de satisfaire aux obligations en
              matière de comptabilité. Le client doit décider seul si sa
              situation financière implique une déclaration d’état de cessation
              de paiement. S’il souhaite avoir l’avis du cabinet sur ce point,
              ce dernier lui fera part de sa position, à condition de disposer
              d’informations fiables et récentes sur la solvabilité à court
              terme de l’entreprise. Mais en aucune façon, le client ne pourra
              se prévaloir de l’existence de la mission de présentation des
              comptes pour invoquer un défaut de conseil ou d’information de la
              part du cabinet en matière d’opportunité de déclaration de
              cessation des paiements. Si à l’occasion de l’exercice de sa
              mission, le cabinet estime que l’entreprise est vraisemblablement
              en état de cessation de paiement, il pourra en informer le client
              et produire une analyse économique de l’entreprise, mais il n’est
              jamais tenu d’estimer cet état de cessation de paiement pour une
              très simple raison technique : un état de cessation des paiements
              peut survenir très brutalement, et les informations comptables et
              financières traitées par le cabinet relèvent du passé. Il n’est
              donc pas possible au cabinet d’être sûr de l’évolution récente des
              données économiques de l’entreprise.
            </p>
          </Col>
          <Col md={12} className="letter-title">
            <p>H. Résiliation de la mission</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              En cas de résiliation par le client au cours d’un exercice
              comptable (ou pendant la mission en cas de mission ponctuelle), et
              sauf faute grave imputable à l’expert-comptable, le client ne
              devra pas verser à ce dernier les honoraires dus pour le travail
              déjà effectué sauf si le Client n’a pas réglé des éventuelles
              factures en souffrance. Cependant, il règlera la somme forfaitaire
              de 49,90 € HT
            </p>
          </Col>
        </Row>
        <Row className="letter-footer">
          <Col md={12} className="footer-number-wrapper">
            10
          </Col>
        </Row>
      </div>
      <div className="letter-page-wrapper">
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Correspondant aux frais de résiliation comme convenu dans les
              termes et conditions. En cas de manquement du client à l’une de
              ses obligations, l’expert-comptable aura la faculté de mettre fin
              à sa mission après l’envoi d’une mise en demeure sous forme de
              lettre recommandée restée sans effet.
            </p>
            <p>
              Dès la survenance d’un évènement susceptible de le placer dans une
              situation de conflit d’intérêts ou de porter atteinte à son
              indépendance, l’expert-comptable a l’obligation de dénoncer le
              contrat.
            </p>
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="letter-title">
            <p>I. Suspension de la mission</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Lorsque la mission est suspendue pour cause de force majeure
              (empêchement temporaire), les délais de remise des travaux sont
              prolongés pour une durée égale à celle de la suspension susvisée à
              moins que le retard en résultant ne justifie la résolution.
            </p>
            <p>
              Pendant la période de suspension, toutes les dispositions du
              contrat demeurent applicables.
            </p>
            <p>
              En cas de manquement du client à l’une de ses obligations (exemple
              : défaut de paiement des honoraires à l’échéance prévue),
              l’expert-comptable aura la faculté de suspendre sa mission après
              l’envoi d’une mise en demeure sous forme de lettre recommandée
              restée sans effet, et ne pourra être tenu responsable des
              conséquences préjudiciables pouvant découler de cette suspension.
            </p>
          </Col>
          <Col md={12} className="letter-title">
            <p>J. Différends</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              En cas de contestation par le client des conditions d’exercice de
              la mission ou de différend sur les honoraires, l’expert-comptable
              s’efforce de faire accepter la conciliation ou l’arbitrage du
              président du conseil régional de l’ordre avant toute action en
              justice.
            </p>
          </Col>
          <Col md={12} className="letter-title">
            <p>K. Droit applicable et attribution de compétence</p>
          </Col>
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Ce contrat de mission sera régi et interprété selon le droit
              français. Tous les litiges auxquels le contrat pourra donner lieu,
              notamment au sujet de sa validité, de son interprétation, de son
              exécution et de sa réalisation, seront soumis aux tribunaux
              compétents.
            </p>
          </Col>
        </Row>
        <Row className="letter-sign-wrapper">
          <Col md={12} className="letter-sign-address">
            <p>Fait à : {companyAddress}</p>
            <p>Fait le : {moment().format("DD/MM/YYYY")}</p>
          </Col>
          <Col md={6} className="letter-sign-field">
            <p className="letter-sign-title">
              Signature de la structure d’exercice professionnel
            </p>
            <p className="letter-sign-sub-title">
              (Représentant légal / signature sociale)
            </p>
            {/* <p className="letter-sign-signee">{companyName}</p> */}
            <img
              src={img_1}
              alt="img-1"
              className="letter-header-images-img-4 image-noly"
            />
          </Col>
          <Col md={6} className="letter-sign-field">
            <p className="letter-sign-title">
              Signature de la structure d’exercice professionnel
            </p>
            <p className="letter-sign-sub-title">
              (Représentant légal / signature sociale)
            </p>
            <p className="letter-sign-sub-title">&nbsp;</p>
            {/* <p className="letter-sign-signee">{userName}</p> */}
            <p className="letter-sign-signee text-center">{companyName}</p>
          </Col>
        </Row>
        {/* <Row className="letter-sign-wrapper">
          <Col md={12} className="letter-sign-logo">
            <img
              src={img_1}
              alt="img-1"
              className="letter-header-images-img-4"
            />
          </Col>
        </Row> */}
        <Row className="letter-footer">
          <Col md={12} className="footer-number-wrapper">
            11
          </Col>
        </Row>
      </div>
      {/* <div className="letter-page-wrapper">
        <Row className="letter-pragraphs-wrapper">
          <Col md={12} className="text-center">
            <p className="appendix-title-1">
              ANNEXE 1 – REPARTITION DES TACHES
            </p>
            <p className="appendix-title-2">EN FONCTION DES PACKS PROPOSES</p>
            <hr style={{ borderTop: "2px solid", borderColor: "#28a897" }} />
          </Col>
        </Row>
        <Row className="letter-pragraphs-wrapper table-responsive">
          <Col md={12} className="letter-pragraphs-custom">
            <p>
              Le tableau ci-dessous récapitule la répartition des tâches en
              fonction du pack choisi :
            </p>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="text-start"
                  >
                    Tâches effectuées
                  </th>
                  <th className="text-center">Pack BNC/ASSOC</th>
                  <th className="text-center">Pack BNC/ASSOC +</th>
                  <th className="text-center">Pack BIC</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-start">Accès application</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                </tr>
                <tr>
                  <td className="text-start">
                    Récupération des données bancaires
                  </td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                </tr>
                <tr>
                  <td className="text-start">
                    Catégorisation des dépenses automatiques
                  </td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                </tr>
                <tr>
                  <td className="text-start">Système de calcul de TVA</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                </tr>
                <tr>
                  <td className="text-start">Accès tableau de bord</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                </tr>
                <tr>
                  <td className="text-start">
                    Note de frais / Encaissement espèces
                  </td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                </tr>
                <tr>
                  <td className="text-start">Gestion immobilisation </td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                </tr>
                <tr>
                  <td className="text-start">Support technique</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                </tr>
                <tr>
                  <td className="text-start">Support & conseil*</td>
                  <td className="text-center">&nbsp;</td>
                  <td className="text-center">&nbsp;</td>
                  <td className="text-center">&nbsp;</td>
                </tr>
                <tr>
                  <td className="text-start">Réalisation de votre bilan**</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                </tr>
                <tr>
                  <td className="text-start">
                    Etablissement de la liasse fiscale
                  </td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                </tr>
                <tr>
                  <td className="text-start">
                    Réalisation de la déclaration sociale des indépendants (DSI)
                  </td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                </tr>
                <tr>
                  <td className="text-start">
                    Gestion et transmission des données comptables à l'AGA
                  </td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                </tr>
                <tr>
                  <td className="text-start">Déclaration de TVA</td>
                  <td>&nbsp;</td>
                  <td className="text-center">X</td>
                  <td className="text-center">X</td>
                </tr>
                <tr>
                  <td className="text-start">
                    Réconciliation des créances et dettes{" "}
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td className="text-center">X</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row className="letter-footer">
          <Col md={12} className="footer-number-wrapper">
            12
          </Col>
        </Row>
      </div> */}
    </div>
  );
}

import { Button, Card, Col, FormGroup, Label, Row } from "reactstrap";
import "./style.scss";
import ErrorIconOrange from "../../assets/images/svg/erros-icons.svg";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="not-found-wrapper">
      <div className="col-md-11 mx-auto col-sm-12">
        <Card className="card-modal-box">
          <h3>Oops !</h3>

          <div className="block-img-center">
            <img src={ErrorIconOrange} alt="icon" />
          </div>

          <p>
            Votre page n'a pas été trouvée.
          </p>
          <Button color="warning" outline onClick={() => navigate("/")}>
            Accueil
          </Button>
        </Card>
      </div>
    </div>
  );
};

export default NotFound;

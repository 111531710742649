import React from "react";

interface DeletShadProps {
  className?: string;
}

const DeletShadow = ({ className }: DeletShadProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="32.989"
    viewBox="0 0 27 32.989"
    className={className}
  >
    <defs>
      <filter
        id="Tracé_70"
        x="0"
        y="0"
        width="27"
        height="17.304"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="1.5" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="Tracé_71"
        x="1.249"
        y="6.012"
        width="24.503"
        height="26.977"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="1.5" result="blur-2" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="Groupe_31"
      data-name="Groupe 31"
      transform="translate(-308.344 -713.6)"
    >
      <g transform="matrix(1, 0, 0, 1, 308.34, 713.6)" filter="url(#Tracé_70)">
        <path
          id="Tracé_70-2"
          data-name="Tracé 70"
          d="M324.446,713.6a4.762,4.762,0,0,1,.852.36,1.712,1.712,0,0,1,.747,1.471c0,.18,0,.359,0,.57h.217c1.113,0,2.227-.006,3.34,0a1.19,1.19,0,0,1,1.166,1.6,1.124,1.124,0,0,1-.839.753,1.764,1.764,0,0,1-.416.04q-7.67,0-15.341,0a1.242,1.242,0,0,1-1.143-.565,1.19,1.19,0,0,1,.777-1.807,1.955,1.955,0,0,1,.378-.029q1.61,0,3.22,0h.259a7.029,7.029,0,0,1,0-.808,1.754,1.754,0,0,1,1.5-1.564.374.374,0,0,0,.073-.029Zm.4,2.394c0-.19,0-.363,0-.535a.606.606,0,0,0-.661-.658c-.52,0-1.039,0-1.559,0q-1.559,0-3.118,0a.592.592,0,0,0-.65.5,5.822,5.822,0,0,0-.005.7Z"
          transform="translate(-308.34 -713.6)"
          fill="red"
        />
      </g>
      <g transform="matrix(1, 0, 0, 1, 308.34, 713.6)" filter="url(#Tracé_71)">
        <path
          id="Tracé_71-2"
          data-name="Tracé 71"
          d="M337.817,833.795h15.5c-.042.52-.082,1.03-.124,1.539q-.151,1.813-.3,3.626-.174,2.092-.347,4.184c-.1,1.149-.182,2.3-.29,3.446a1.79,1.79,0,0,1-1.848,1.679q-4.84.006-9.68,0a1.789,1.789,0,0,1-1.846-1.681c-.13-1.413-.239-2.829-.357-4.243q-.192-2.3-.384-4.6C338.032,836.435,337.926,835.127,337.817,833.795Zm3.548,1.91h.009c.016.273.032.546.049.818q.152,2.444.3,4.888.121,1.945.243,3.89a.573.573,0,0,0,.375.522.591.591,0,0,0,.818-.584c-.017-.519-.059-1.038-.091-1.556q-.151-2.434-.3-4.868c-.068-1.084-.133-2.168-.2-3.252a.588.588,0,0,0-.558-.575.6.6,0,0,0-.625.479A1.768,1.768,0,0,0,341.365,835.7Zm8.4-.059a.6.6,0,0,0-.565-.658.612.612,0,0,0-.634.588q-.123,1.956-.242,3.912-.111,1.777-.223,3.553c-.046.732-.1,1.464-.131,2.2a.589.589,0,0,0,.564.63.6.6,0,0,0,.632-.586c.016-.219.028-.439.041-.659q.127-2.066.255-4.132C349.565,838.894,349.666,837.3,349.769,835.646Zm-3.6,4.782q0-2.341,0-4.681c0-.48-.221-.759-.6-.759s-.6.279-.6.759q0,4.681,0,9.363a1.825,1.825,0,0,0,0,.2.582.582,0,0,0,.4.523.6.6,0,0,0,.794-.623Q346.172,842.818,346.169,840.427Z"
          transform="translate(-332.07 -827.78)"
          fill="red"
        />
      </g>
    </g>
  </svg>
);

export default DeletShadow;

import React, { PropsWithChildren, ReactElement } from "react";
import "./CardDashboard.scss";
import { ICardDashProperties } from "../../interfaces";
import { Card } from "reactstrap";

function CardDashboard(
  props: PropsWithChildren<ICardDashProperties>
): ReactElement {
  return (
    <>
      <Card className={`card-dash-box ${props.className} `}>
        <div className=" box-inner-card">
          <div className="card-icon">
            <img src={props.icon} alt="icon" />
          </div>
          <div className="card-texte">
            <h4>{props.title}</h4>
            <div className="card-price">{props.text}</div>
          </div>
          <div className="etat-value-item">
            {props.isEntrep && props.entrepMessage ? (
              props.entrepMessage
            ) : (
              <>
                {props.iconEtat && <img src={props.iconEtat} alt="icon" />}
                {props.valuePrice && (
                  <span className="etat-value"> {props.valuePrice}</span>
                )}
                {props.percent && (
                  <span className="etat-percent">({props.percent})</span>
                )}
              </>
            )}
          </div>
        </div>
      </Card>
    </>
  );
}

export default CardDashboard;

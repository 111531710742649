import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user_id: "",
  company_id: "",
  application_id: "",
  token: "",
  role: "",
  pack: "",
};

export const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.user_id = action.payload;
    },

    setUserRole: (state, action) => {
      state.role = action.payload;
    },

    setCompanyPack: (state, action) => {
      state.pack = action.payload;
    },

    setToken: (state, action) => {
      state.token = action.payload;
    },

    setCompanyId: (state, action) => {
      state.company_id = action.payload;
    },

    setApplicationId: (state, action) => {
      state.application_id = action.payload;
    },
    resetRoot: (state: any) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setUserId,
  setCompanyId,
  setToken,
  setApplicationId,
  setUserRole,
  resetRoot,
  setCompanyPack
} = rootSlice.actions;
export default rootSlice.reducer;

import React, { PropsWithChildren, ReactElement } from "react";
import "./TopCard.scss";
import { ICardProperties } from "../../interfaces";
import { Card } from "reactstrap";

function TopCard(props: PropsWithChildren<ICardProperties>): ReactElement {
  return (
    <>
      <Card className={`card-box  ${props.className} `}>
        <div className=" box-inner-card">
          <div className="card-icon">
            <img src={props.icon} alt="icon" />
          </div>
          <div className="card-texte">
            <h4>{props.title}</h4>
            <div className="card-price">{props.text}</div>
          </div>
        </div>
      </Card>
    </>
  );
}

export default TopCard;

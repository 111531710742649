import React, { useEffect, useState } from "react";
import "./ApercuAdmin.scss";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import UserInfo from "../../components/user-info/UserInfo";
import { data, images } from "../../constants";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiCalendar2Line,
  RiCheckFill,
  RiDownload2Fill,
  RiUser3Line,
  RiUserAddFill,
  RiUserFollowFill,
} from "react-icons/ri";
import BannerTop from "../../components/Banner/BannerTop";
import AttacheIcon from "../../assets/AttacheIcon";
import Select from "react-select";

import Icon1 from "../../assets/images/svg/icon-nav-1.svg";
import Icon2 from "../../assets/images/svg/icon-nav-2.svg";
import Icon3 from "../../assets/images/svg/icon-nav-3.svg";
import Icon4 from "../../assets/images/svg/icon-nav-4.svg";
import IconLogOut from "../../assets/images/svg/icon-nav-5.svg";
import ImgBanner from "../../assets/images/banner/b1.png";
import previewPrsopect from "../../assets/images/previewPrsopect.png";
import profilePhoto from "../../assets/images/avatar_empty.png";
import emptyLogo from "../../assets/images/EmptyLogo.jpg";

import TopNav from "../../components/topnav/TopNav";
import { Link } from "react-router-dom";
import { SiPypy } from "react-icons/si";
import EmailIcon from "../../assets/EmailIcon";
import CrownIcon from "../../assets/CrownIcon";
import { IoMdCreate } from "react-icons/io";
import { BsTrashFill } from "react-icons/bs";
import UserOrangeIcon from "../../assets/UserOrangeIcon";
import userAddIcon from "../../assets/images/svg/user-t1.svg";
import AddIconLead from "../../assets/images/svg/userLead-icon.svg";
import colors from "../../constants/colors";
import styled from "styled-components";
import TicketIcon from "./TicketIcon";
import EmailPrevIcon from "./EmailIcon";
import CerleUserBg from "../../assets/CerleUserBg";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const ApercuAdmin = ({
  scale,
  transformOrigin = "top left",
  logo,
  color1 = colors.primaryColor,
  color2 = colors.secondaryColor,
  color3 = colors.orange,
}: {
  scale: number | number[];
  transformOrigin?: string;
  logo?: any;
  color1?: string;
  color2?: string;
  color3?: string;
}) => {
  const ActiveLi = styled.li`
    a {
      background: ${color1};
      boxshadow: inset 0px 4px 6px 0px ${color1}75;
    }
    :before {
      background-color: ${color1};
    }
    :after {
      box-shadow: inset 2px 2px 7px 3px ${color2}52;
    }
  `;

  const BannerApercu = styled.div`
    box-shadow: 0 5px 20px ${color2}24;
  `;

  const TableApercuHead = styled.thead`
  color: ${color2};
  background: ${adjustColor(color2!,95)};
  `;

  const ButtonSec = styled.button`
    background: ${color2} !important;
    box-shadow: inset 0px 4px 5px 0px ${color2}75 !important;
    border-color: ${color2} !important;
  `;

  const EditIcon = styled.button`
    box-shadow: -2px 4px 10px ${color2}75 !important;
    svg {
      fill: ${color2};
    }
  `;

  const ButtonPrim = styled.button`
    background: ${color1} !important;
    box-shadow: inset 0px 4px 5px 0px ${color1}75 !important;
    border-color: ${color1} !important;
  `;

  const PageLink = styled.button`
    background: ${color1} !important;
  `;

  const PageLinkNav = styled.button`
    button {
      svg {
        fill: ${color1};
      }
    }
  `;

  const ButtonOrange = styled.button`
    background: ${color3} !important;
    box-shadow: inset 0px 4px 5px 0px ${color3}75 !important;
    border-color: ${color3} !important;
  `;

  const CalenderIcon = styled.span`
    box-shadow: inset 0px 4px 6px 0px ${color2}75 !important;
    svg {
      fill: ${color2};
    }
  `;

  return (
    <div
      className="main-apercu"
      style={{
        transform: `scale${
          Array.isArray(scale) ? `(${scale[0]}, ${scale[1]})` : `(${scale})`
        }`,
        transformOrigin: transformOrigin,
      }}
    >
      <div className="preview-wrapper">
        <div className="sidebar-apercu">
          <div className="logo-apercu">
            <img
              src={
                logo
                  ? logo.key
                    ? logo.url
                    : URL.createObjectURL(logo)
                  : emptyLogo
              }
              alt="app-logo"
            />
          </div>
          <ul>
            <li>
              <Link to="/" className="nav-item ">
                <span className="icon-nav">
                  <img src={Icon1} alt="icon" />
                </span>
                <span className="txt-nav">Mes Clients</span>
              </Link>
            </li>
            <li>
              <Link to="/" className="nav-item">
                <span className="icon-nav  icon-1">
                  <img src={Icon2} alt="icon" />
                </span>
                <span className="txt-nav">Gestion admin</span>
              </Link>
            </li>
            <li>
              <Link to="/" className="nav-item">
                <span className="icon-nav">
                  <img src={Icon3} alt="icon" />
                </span>
                <span className="txt-nav">Gestion Comptable</span>
              </Link>
            </li>
            <ActiveLi className="active">
              <Link to="/" className="nav-item">
                <span className="icon-nav">
                  <img src={Icon4} alt="icon" />
                </span>
                <span className="txt-nav">Gestion prospects</span>
              </Link>
            </ActiveLi>
          </ul>

          <div className="logout-sidbar">
            <ul>
              <li>
                <Link to="/" className="nav-item">
                  <span className="icon-nav">
                    <img src={IconLogOut} alt="icon" />
                  </span>
                  <span className="txt-nav">Déconnexion</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="main-content-apercu">
          <div className="top-navbtn">
            <Button
              className="btn-creat-lead btn-creat-apercu mx-2"
              style={{
                boxShadow: `inset 0px 4px 5px 0px ${adjustColor(color1!,-75)}`,
                borderColor: color1,
                background: color1,
                backgroundColor: color1,
              }}
            >
              <img src={AddIconLead} alt="icon" />
              <span>Créer un lead</span>
            </Button>
            <Button
              className="btn-creat-user btn-creat-apercu"
              style={{
                boxShadow: `inset 0px 4px 5px 0px ${color3}75`,
                borderColor: color3,
                background: color3,
                backgroundColor: color3,
              }}
            >
              <img src={userAddIcon} alt="icon" />
              <span>Créer un client</span>
            </Button>
          </div>

          <Row className="mb-4">
            <Col md={8}>
              <BannerApercu
                className="banner-apercu"
                style={{
                  background: `linear-gradient(to left, ${color1}80, ${color1}60, ${color1}40, ${color1}20, ${color1}) ${color1}`,
                }}
              >
                <div className="Banner-text">
                  <h2>Mes prospects</h2>
                </div>
                <div className="Banner-img">
                  <img src={previewPrsopect} alt="Gestion Admin" />
                </div>
              </BannerApercu>
            </Col>
            <Col md={4}>
              <Card className="user-apercu">
              <TicketIcon color={color1!} />

                <div className="user-info">
                  <CerleUserBg className="bgCercle" />
                  <div className="user-info__img">
                    <img src={profilePhoto} alt="img" />
                  </div>
                </div>

                <EmailPrevIcon color={color1!} />
              </Card>
            </Col>
          </Row>
          <Card className="card-filter">
            <Form>
              <Row className="align-items-end">
                <Col lg={6} md={12}>
                  <div className="filterInner">
                    <Row>
                      <Col lg={6} md={6}>
                        <FormGroup className="form-icon icon-end">
                          <Label for="dated">Date de debut</Label>
                          <Input
                            id="dated"
                            name="date"
                            placeholder="Date de debut"
                            type="date"
                            className="form-secondary"
                            style={{
                              color: color1,
                              background: `${adjustColor(color1!,90)}`,
                              boxShadow: `inset 0px 4px 6px 0px ${adjustColor(color1!,90)}`,
                              borderColor: color1,
                            }}
                          />
                          <CalenderIcon className="icon icon-secondary icon-secondary-fill">
                            <RiCalendar2Line />
                          </CalenderIcon>
                        </FormGroup>
                      </Col>
                      <Col lg={6} md={6}>
                        <FormGroup className="form-icon icon-end">
                          <Label for="datef">Date de fin</Label>
                          <Input
                            id="datef"
                            name="date"
                            placeholder="Date de fin"
                            type="date"
                            className="form-secondary"
                            style={{
                              color: color1,
                              background: `${adjustColor(color1!,90)}`,
                              boxShadow: `inset 0px 4px 6px 0px ${adjustColor(color1!,90)}`,
                              borderColor: color1,
                            }}
                          />
                          <CalenderIcon className="icon icon-secondary icon-secondary-fill">
                            <RiCalendar2Line />
                          </CalenderIcon>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={6} md={12}>
                  <div className="actionsFilter mb-3">
                    <Button
                      style={{
                        boxShadow: `inset 0px 4px 5px 0px ${color2}75`,
                        borderColor: color2,
                        background: color2,
                        backgroundColor: color2,
                      }}
                    >
                      <span>Filtrer</span>
                    </Button>
                    <Button
                      style={{
                        borderColor: color2,
                        color: color2,
                      }}
                      outline
                    >
                      <span>Réinitialiser</span>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
          <div className=" table-primary card">
            <Table className="table-apercu">
              <TableApercuHead>
                <tr>
                  <th>Prénom</th>
                  <th>Nom</th>
                  <th>Email</th>
                  <th>Création</th>
                  <th>Statut</th>
                  <th>Action</th>
                </tr>
              </TableApercuHead>
              <tbody>
                <tr>
                  <td scope="row">Mark</td>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>
                    <ButtonPrim className="btn-status btn btn-small btn-primary">
                      <RiCheckFill />
                      Lead
                    </ButtonPrim>
                  </td>
                  <td>
                    <div className="table-action">
                      <EditIcon className="btn btn-blue">
                        <IoMdCreate />
                      </EditIcon>
                      <button className="btn btn-red">
                        <BsTrashFill />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td scope="row">Mark</td>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@mdo</td>
                  <td>
                    <ButtonSec className="btn-status btn btn-small btn-secondary">
                      <RiUserAddFill />
                      Intéressé
                    </ButtonSec>
                  </td>
                  <td>
                    <div className="table-action">
                      <EditIcon className="btn btn-blue">
                        <IoMdCreate />
                      </EditIcon>
                      <button className="btn btn-red">
                        <BsTrashFill />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td scope="row">Mark</td>
                  <td>Larry</td>
                  <td>the Bird</td>
                  <td>@mdo</td>
                  <td>
                    <ButtonOrange className="btn-status btn btn-small btn-warning">
                      <RiUserFollowFill />A Valider
                    </ButtonOrange>
                  </td>
                  <td>
                    <div className="table-action">
                      <EditIcon className="btn btn-blue">
                        <IoMdCreate />
                      </EditIcon>
                      <button className="btn btn-red">
                        <BsTrashFill />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApercuAdmin;

export const adjustColor = (color: string, amount: number) => {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
};

import axios from "axios";
import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import config from "../../../config";
import { ICategory, ISubCategory, ITransaction } from "../../../interfaces";
import { CarbKeys, PayDiffKeys } from "../../../util/context";
import { ErrorLogger } from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import { useTVA } from "../../../util/hooks/useTVA";

const { API_URL } = config[process.env.NODE_ENV];

const Decaissements = ({
  categs,
  transaction,
  callback,
  immobCheckCallback,
  carbCheckCallback,
  diffPayCheckCallback,
  setRefreshCategs,
  tvaCallback = null,
}: {
  categs: ICategory[];
  transaction: ITransaction | null | undefined;
  callback: () => void;
  setRefreshCategs: (value: React.SetStateAction<boolean>) => void;
  immobCheckCallback: any;
  diffPayCheckCallback: any;
  carbCheckCallback: any;
  tvaCallback?: any;
}) => {
  const [activeCategIndex, setActiveCategIndex] = useState<number>(0);
  const [currentCategs, setCurrentCategs] = useState<ICategory[]>([]);
  const [descShown, setDescShown] = useState<{
    [prop: number]: boolean;
  }>({});

  useEffect(() => {
    setCurrentCategs(
      !transaction?.diffPay_id
        ? categs
        : categs.filter((elt) => elt.name !== "Paiements différés/groupés")
    );
  }, [categs]);

  const { setTVA } = useTVA();

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };
  let api = useAxios();

  const toastMessage = (message: string, type: string) => {
    let options: any = {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };

    toast.dismiss();
    switch (type) {
      case "success":
        toast.success(message, options);
        return;

      case "warning":
        toast.warning(message, options);
        return;

      case "error":
        toast.error(message, options);
        return;

      default:
        return;
    }
  };

  const updateTransaction = async (categ: ISubCategory) => {
    try {
      toastMessage(
        "Cela peut prendre quelques secondes,s'il vous plaît, ne faites pas d'autre action ...",
        "warning"
      );
      // updating parent transaction category
      if (
        transaction?.relatedToFactureType &&
        transaction?.relatedToFactureType === "parent"
      ) {
        let payload = {
          id: transaction?.id,
          subCategoryId: categ.id,
          category: categ.key,
          status: transaction?.status === 101 ? 100 : transaction?.status,
        };

        await api.post(`/api/Transaction/Update`, payload, {
          headers: {
            "x-access-token": creds.token,
          },
        });

        await callback();
      } else {
        if (categ.key && categ.key === CarbKeys.OUT028) {
          await carbCheckCallback(transaction);
          await callback();
          return;
        }

        let updatedTransaction = null as ITransaction | null | undefined;

        let payload: any = {
          id: transaction?.id,
          subCategoryId: categ.id,
          category: categ.key,
          status: transaction?.status === 101 ? 100 : transaction?.status,
        };

        if (!transaction?.factures || transaction?.factures.length === 0) {
          let vatPercentage =
            categ.TVA &&
            !isNaN(parseFloat(categ.TVA)) &&
            parseFloat(categ.TVA) !== 0
              ? parseFloat(categ.TVA)
              : null;
          payload = {
            ...payload,
            vat: vatPercentage
              ? setTVA(transaction?.amount!, vatPercentage / 100)
              : null,
            vatPercentage,
          };
        }

        const { data } = await api.post(`/api/Transaction/Update`, payload, {
          headers: {
            "x-access-token": creds.token,
          },
        });

        if (
          data &&
          data.updated.length > 0 &&
          transaction &&
          data.updated[0].id === transaction.id
        ) {
          updatedTransaction = data.updated[0].view;
        }

        if (transaction?.diffPay_id && transaction?.diffPay) {
          if (updatedTransaction) {
            await api.post(
              `/api/Transaction/Update`,
              {
                id: transaction?.diffPay_id,
                vat:
                  transaction?.diffPay?.vat && transaction?.diffPay?.vat !== ""
                    ? `-${
                        Math.abs(parseFloat(transaction?.diffPay?.vat)) +
                        Math.abs(
                          updatedTransaction.vat &&
                            updatedTransaction.vat !== ""
                            ? parseFloat(updatedTransaction.vat)
                            : 0
                        )
                      }`
                    : updatedTransaction.vat,
              },
              {
                headers: {
                  "x-access-token": creds.token,
                },
              }
            );
          }
        }

        if (
          categ.amount &&
          categ.rule &&
          Math.abs(transaction?.amount!) >=
            Math.abs(parseFloat(categ.amount)) &&
          String(categ.rule) === "true" &&
          categ.redirectCategory_id
        ) {
          await immobCheckCallback(categ, transaction);
          return;
        }

        await callback();

        if (
          categ.key === PayDiffKeys.OUT100 ||
          categ.key === PayDiffKeys.OUT101
        ) {
          await diffPayCheckCallback(
            updatedTransaction && updatedTransaction.id
              ? updatedTransaction
              : transaction,
            categ
          );
          return;
        }
      }

      toastMessage("Votre mise à jour a été effectuée avec succès", "success");
    } catch (error: any) {
      ErrorLogger("updating a transaction's catgeory", error);
      toastMessage(
        "Quelque chose d'inattendu s'est produit, veuillez réessayer plus tard.",
        "error"
      );
    }
  };

  return (
    <>
      {currentCategs && currentCategs.length > 1 && (
        <>
          <Row>
            <Col md={5} sm={12}>
              <div className="remunerations-block">
                <ul>
                  {currentCategs
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((elt, key) => (
                      <li
                        className={key === activeCategIndex ? "active" : ""}
                        key={key}
                      >
                        <span
                          className="name-cat-span"
                          onClick={() => setActiveCategIndex(key)}
                        >
                          {elt.name}
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
            </Col>
            <Col md={7} sm={12}>
              <div className="list-encaissements-choix">
                {currentCategs[activeCategIndex].sub_categories
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((elt, key) => (
                    <div
                      className="choix-item"
                      key={key}
                      onClick={async () => {
                        setRefreshCategs(true);
                        await updateTransaction(elt);
                      }}
                      onMouseEnter={() =>
                        setDescShown((prevState) => ({
                          ...prevState,
                          [key]:
                            elt.description && elt.description !== ""
                              ? true
                              : false,
                        }))
                      }
                      onMouseLeave={() =>
                        setDescShown((prevState) => ({
                          ...prevState,
                          [key]: false,
                        }))
                      }
                    >
                      <p>{elt.name}</p>
                      {descShown![key] && <p>{elt.description}</p>}
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
        </>
      )}
      {currentCategs && currentCategs.length === 1 && (
        <div className="list-encaissements-choix">
          {currentCategs[0].sub_categories?.map((elt, key) => (
            <div
              className="choix-item"
              key={key}
              onClick={async () => {
                setRefreshCategs(true);
                await updateTransaction(elt);
              }}
              onMouseEnter={() =>
                setDescShown((prevState) => ({
                  ...prevState,
                  [key]:
                    elt.description && elt.description !== "" ? true : false,
                }))
              }
              onMouseLeave={() =>
                setDescShown((prevState) => ({
                  ...prevState,
                  [key]: false,
                }))
              }
            >
              <p>{elt.name}</p>
              {descShown![key] && <p>{elt.description}</p>}
            </div>
          ))}
        </div>
      )}
      {currentCategs && currentCategs.length < 1 && <></>}
    </>
  );
};

export default Decaissements;

import React from "react";

interface SendIconProps {
  className?: string;
}

const SendIconWhite = ({ className }: SendIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36.304"
    height="36.326"
    viewBox="0 0 36.304 36.326"
    className={className}
  >
    <path
      id="Tracé_96"
      data-name="Tracé 96"
      d="M-447.125,164.071a1.439,1.439,0,0,1,1.042-1.491q4.657-1.648,9.315-3.292,11.921-4.208,23.841-8.42a1.481,1.481,0,0,1,1.692.262,1.47,1.47,0,0,1,.269,1.69q-5.831,16.5-11.645,33c-.364,1.032-1.145,1.489-1.951,1.079a1.747,1.747,0,0,1-.751-.74c-1.992-4.211-3.953-8.436-5.935-12.652-.131-.278.008-.393.163-.549q2.992-2.989,5.982-5.98a1.285,1.285,0,0,0,.434-1.371,1.5,1.5,0,0,0-1.122-1.051,1.287,1.287,0,0,0-1.288.45c-1.971,1.975-3.95,3.943-5.913,5.927a.567.567,0,0,1-.773.141q-6.184-2.911-12.385-5.784A1.468,1.468,0,0,1-447.125,164.071Z"
      transform="translate(447.125 -150.722)"
      fill="#fff"
    />
  </svg>
);

export default SendIconWhite;

import moment from "moment";

const useFormatter = () => {
  const setDecimalDigits = (value: number) => {
    return (Math.round(value * 100) / 100).toFixed(2);
  };

  const dateSort = (
    obj1: {
      date: any;
      createdAt: any;
      dateFrom: any;
      dateTo: any;
      to: any;
      from: any;
    },
    obj2: {
      date: any;
      createdAt: any;
      dateFrom: any;
      dateTo: any;
      to: any;
      from: any;
    },
    order: number = 1
  ) => {
    const a = moment(
      obj1.date ||
        obj1.createdAt ||
        obj1.dateFrom ||
        obj1.dateTo ||
        obj1.to ||
        obj1.from
    );
    const b = moment(
      obj2.date ||
        obj2.createdAt ||
        obj2.dateFrom ||
        obj2.dateTo ||
        obj2.to ||
        obj2.from
    );
    if (order === 1) {
      if (a > b) {
        return 1;
      }

      if (b > a) {
        return -1;
      }
    } else {
      if (b > a) {
        return 1;
      }

      if (a > b) {
        return -1;
      }
    }

    return 0;
  };

  const entrepCalculator = (activity: string, value: number) => {
    let peak = 0;
    let t_render = "T10";
    switch (activity) {
      case "prestation":
      case "marchendise":
      case "autresPrestations":
        peak = 188700;
        break;
      case "location":
      case "profession":
        peak = 77700;
        break;

      default:
        peak = 188700;
        break;
    }

    let t_value = (value / peak) * 100;

    if (0 <= t_value && t_value < 10) {
      t_render = "t-10";
    }
    if (10 <= t_value && t_value < 20) {
      t_render = "t-20";
    }
    if (20 <= t_value && t_value < 30) {
      t_render = "t-30";
    }
    if (30 <= t_value && t_value < 40) {
      t_render = "t-40";
    }
    if (40 <= t_value && t_value < 50) {
      t_render = "t-50";
    }
    if (50 <= t_value && t_value < 60) {
      t_render = "t-60";
    }
    if (60 <= t_value && t_value < 70) {
      t_render = "t-70";
    }
    if (70 <= t_value && t_value < 80) {
      t_render = "t-80";
    }
    if (80 <= t_value && t_value < 90) {
      t_render = "t-90";
    }
    if (90 <= t_value && t_value < 100) {
      t_render = "t-100";
    }

    return { t_render, peak };
  };

  return {
    setDecimalDigits,
    dateSort,
    entrepCalculator
  };
};

export { useFormatter };

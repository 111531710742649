import React, { useEffect, useState } from "react";
import "./GestionComptable.scss";
import { Col, Row } from "reactstrap";
import UserInfo from "../../components/user-info/UserInfo";
import { data } from "../../constants";
import BannerTop from "../../components/Banner/BannerTop";

import Tabs from "../../components/Tabs";
import SousCategories from "./SousCategories/SousCategories";
import Categories from "./Categories/Categories";
import Reporting from "./Reporting/Reporting";
import { setCategories } from "../../store/reducers/categories/categoriesSlice";
import axios from "axios";
import config from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { ErrorLogger } from "../../util/errorLogger";
import { ICategory, IPermission, User } from "../../interfaces";
import { useAuthorization } from "../../util/hooks/useAuthorization";;

type TabsType = {
  label: string;
  index: number;
  Component: React.FC<{}>;
}[];

// Tabs Array
const tabs: TabsType = [
  {
    label: "Reporting",
    index: 1,
    Component: Reporting,
  },
  // {
  //   label: "Catégories",
  //   index: 2,
  //   Component: Categories,
  // },
  // {
  //   label: "Sous Catégories",
  //   index: 3,
  //   Component: SousCategories,
  // },
];

export interface ComptableProps {}
const GestionComptable: React.FC<ComptableProps> = ({}) => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);

  const { authenticatedRoute } = useAuthorization();
  const { user, permissions } = useSelector(
    (state: { user: object; application: object; permissions: object }) =>
      state.user
  ) as { user: User; permissions: IPermission[] };

  useEffect(() => {
  }, []);

  return (
    <div className="page">
      <div className="top-content">
        <Row>
          <Col lg={8} md={12}>
            <BannerTop banner={data.gestioncomptable} />
          </Col>
          <Col lg={4} md={12}>
            <UserInfo user={user} />
          </Col>
        </Row>
      </div>

      <div className="full-tabs">
        <Tabs selectedTab={selectedTab} onClick={setSelectedTab} tabs={tabs} />
      </div>
    </div>
  );
};

export default GestionComptable;

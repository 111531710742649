import { configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { useDispatch } from "react-redux";
import rootReducer from "./reducers/root/rootSlice";
import userReducer from "./reducers/user/userSlice";
import utilReducer from "./reducers/utils/utilSlice";
import applicationsReducer from "./reducers/applications/applicationsSlice";
import permissionsListReducer from "./reducers/permissions-list/permissionsListSlice";
import clientsListReducer from "./reducers/clients-list/clientsSlice";
import companiesListReducer from "./reducers/companies-list/companiesSlice";
import categoriesReducer from "./reducers/categories/categoriesSlice";
import messagesNotifsReducer from "./reducers/messages-notifs/messagesNotifsSlice";

export const rootPersistConfig = {
  key: "noly-root",
  version: 1,
  storage,
};

const persistedRootReducer = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore({
  reducer: {
    root: persistedRootReducer,
    user: userReducer,
    util: utilReducer,
    applications: applicationsReducer,
    permissionsList: permissionsListReducer,
    clientsList: clientsListReducer,
    companiesList: companiesListReducer,
    categories: categoriesReducer,
    messagesNotifs: messagesNotifsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
let persistor = persistStore(store);
export { persistor };

export default store;

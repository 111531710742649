import React, { useEffect, useState } from "react";

import "./style.scss";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useParams } from "react-router-dom";
import config from "../../config";
import { useSelector } from "react-redux";
import axios from "axios";
import { ErrorLogger } from "../../util/errorLogger";
import { Company, User } from "../../interfaces";
import { SubmitHandler, useForm } from "react-hook-form";

import UserChat from "../../assets/images/avatar.png";
import { Watch } from "typescript";
import { RiDownload2Fill } from "react-icons/ri";
import AttacheIcon from "../../assets/AttacheIcon";
import moment from "moment";
import "moment/locale/fr";
import Tabs from "../../components/Tabs";
import GestionDocuments from "./GestionDocuments/GestionDocuments";
import Information from "./InformationClient";

type TabsType = {
  label: string;
  index: number;
  Component: any;
}[];

// Tabs Array
const tabs: TabsType = [
  {
    label: "Information client",
    index: 1,
    Component: Information,
  },
  {
    label: "Gestion des documents",
    index: 2,
    Component: GestionDocuments,
  },
];

const ClientInfo = ({
  context,
  company,
  client,
  callback,
}: {
  context?: string;
  company?: Company;
  client?: User;
  callback?: () => void;
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);
  return (
    <>
      <div className="page">
        <div>
          <Card className="card-Table table-primary">
            <Tabs
              selectedTab={selectedTab}
              onClick={setSelectedTab}
              tabs={tabs}
              context={context}
              company={company}
              client={client}
              callback={callback}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default ClientInfo;
